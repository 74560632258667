import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import PersonalInfo from '../components/AccountSettings/PersonalInfo';
import CompanyInfo from '../components/AccountSettings/CompanyInfo';
import Staff from '../components/AccountSettings/Staff';
import AppSettings from '../components/AccountSettings/AppSettings';
import ChangePassword from '../components/AccountSettings/ChangePassword';

const AccountSettings = () => {
    return (
        <div className='m-2'>
            <h2>Cài Đặt Tài Khoản</h2>
            <Tabs defaultActiveKey="personal" id="account-settings-tabs">
                <Tab eventKey="personal" title="Cá nhân">
                    <PersonalInfo />
                </Tab>
                <Tab eventKey="company" title="Công ty">
                    <CompanyInfo />
                </Tab>
                <Tab eventKey="staff" title="Nhân viên">
                    <Staff />
                </Tab>
                <Tab eventKey="app" title="Ứng dụng">
                    <AppSettings />
                </Tab>
                <Tab eventKey="change-password" title="Đổi mật khẩu">
                    <ChangePassword />
                </Tab>
            </Tabs>
        </div>
    );
};

export default AccountSettings;
