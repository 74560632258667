// frontend/src/pages/CustomerList.js
import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faUserPlus, faEye, faEyeSlash, faTrash, faUserGroup, faPhone, faEnvelope, faMapMarkerAlt, faStickyNote } from '@fortawesome/free-solid-svg-icons';
import { Table, Button, FormControl, Badge } from 'react-bootstrap';
import AddCustomer from '../components/AddCustomer';
import EditCustomer from '../components/EditCustomer';
import formatDate from '../components/FormatDate';
import Loading from '../components/Loading';
import ModalConfirm from '../components/ModalConfirm';
import { toast } from 'react-toastify';
import PageWrapper from '../components/PageWrapper';
import { Link } from 'react-router-dom';

const CustomerList = () => {
    const [customers, setCustomers] = useState([]);
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const [consultationCustomerIds, setConsultationCustomerIds] = useState([]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [search, setSearch] = useState("");
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentCustomerId, setCurrentCustomerId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [visibleColumns, setVisibleColumns] = useState({
        index: true,
        name: true,
        birthday: true,
        gender: true,
        createdAt: true,
        phone: true,
        email: true,
        address: true,
        note: true,
        actions: true,
    });

    const toggleColumnVisibility = (column) => {
        setVisibleColumns((prevState) => ({
            ...prevState,
            [column]: !prevState[column],
        }));
    };
    const apiUrl = process.env.REACT_APP_API_URL;

    const token = localStorage.getItem('token');
    let userId;
    if (token && typeof token === 'string') {
        try {
            const decoded = jwtDecode(token);
            userId = decoded.userId;
        } catch (error) {
            console.error('Token không hợp lệ:', error);
        }
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const updateIsMobile = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', updateIsMobile);
        return () => window.removeEventListener('resize', updateIsMobile);
    }, []);

    const fetchConsultations = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/consultations?createdBy=${userId}`);
            // Lấy ra danh sách ID khách hàng từ tư vấn
            const ids = response.data.consultations.map(consultation => consultation.customerId._id);
            setConsultationCustomerIds(ids);
        } catch (error) {
            console.error('Lỗi tải danh sách tư vấn: ', error);
            setLoading(false);
        }
    }, [apiUrl, userId]);


    useEffect(() => {
        fetchConsultations();
    }, [fetchConsultations]);

    const fetchCustomers = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/customers?createdBy=${userId}`);
            setCustomers(
                response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            );

            setLoading(false);
        } catch (error) {
            console.error('Error fetching customers:', error);
            setLoading(false);
        }
    }, [apiUrl, userId]);

    useEffect(() => {
        fetchCustomers();
    }, [fetchCustomers]);

    const reFreshCustomers = () => {
        fetchCustomers();
    };

    const handleShowAdd = () => setShowAddModal(true);
    const handleCloseAdd = () => setShowAddModal(false);

    const handleShowEdit = (customerId) => {
        setCurrentCustomerId(customerId);
        setShowEditModal(true);
    };

    const handleCloseEdit = () => {
        setCurrentCustomerId(null);
        setShowEditModal(false);
    };

    const filteredCustomers = customers.filter(customer =>
        customer.name.toLowerCase().includes(search.toLowerCase()) ||
        customer.phone.includes(search)
    );
    const displayedCustomers = filteredCustomers
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, 10);

    const confirmDelete = (customerId) => {
        setSelectedCustomerId(customerId); // Lưu ID khách hàng cần xóa
        setShowConfirmModal(true); // Hiển thị modal xác nhận
    };

    const handleDeleteCustomer = async () => {
        try {
            await axios.delete(`${apiUrl}/customers/${selectedCustomerId}`); // Gọi API xóa khách hàng
            toast.success('Xóa khách hàng thành công!');
            setShowConfirmModal(false); // Đóng modal xác nhận
            reFreshCustomers(); // Cập nhật lại danh sách
        } catch (error) {
            console.error('Lỗi khi xóa khách hàng:', error);
            toast.error('Không thể xóa khách hàng.');
        }
    };

    return (
        <div className="m-2">
            <PageWrapper
                pageTitle="Khách Hàng"
                pageIcon={<FontAwesomeIcon icon={faUserGroup} />}>
                <div className="d-flex mb-3">
                    <Button
                        variant="primary"
                        onClick={handleShowAdd}
                        className="add-customer-btn"
                        title="Thêm Khách Hàng"
                    >
                        <FontAwesomeIcon icon={faUserPlus} size="lg" />
                    </Button>
                    <FormControl
                        type="text"
                        placeholder="Tìm kiếm khách hàng..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className="m-1"
                        style={{ flexGrow: 1 }}
                    />
                </div>
                {loading ? (
                    <Loading />
                ) : isMobile ? (
                    <div className="row">
                        {filteredCustomers.map((customer, index) => (
                            <div className="col-md-6 col-sm-12 mb-4" key={customer._id}>
                                <div className="card h-100">
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            <Badge bg="secondary" className="m-1">{index + 1}</Badge>
                                            {customer.name} - {new Date(customer.birthday).getFullYear()}
                                        </h5>
                                        <div className="d-flex">
                                            {/* Cột bên trái: Hình đại diện */}
                                            <div className="me-3 d-flex justify-content-center align-items-center">
                                                <img
                                                    className="customer-avatar"
                                                    src={customer.profilePicture || customer.gender === "Nam" ? 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEivYejoR2LHSmj1k4rPX5GAzkudAMk1bry1vGcUIMKT6KGqVdZHCXei00LlHmy9sVd-KgyBGoBnjUMEadamNiYPCw9ujoBgsZ7d42o2ICLNeiFb_-EZVJ4JJXj9VaIpNDew1kj8OTQzjp682jz71pNprN_YaK5nFQxZ5CKDVsdpkObFahGt-KLNsIIU0vP1/s16000/noProfile.png' : 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEj4zZog9334BSk5TeAduz4vggNF4RH0O7Frj0Rz46ze56CcfU3GMVrAB1Ly5Dz79AN2tbHhlFnfZ8ZvWqWJhj7TAW4vmEySIb-BKG8_Ez0PiQe6taDRTP8P4wrVOQZmjLQix8t1DIec6vA0_11jTM-xB4DER2C2lzHFO54Zqt9cpUfDOMQkN1TquUTCNFN9/s200/default-avatar-female.jpg'}
                                                    alt={customer.name}
                                                    style={{
                                                        width: "100px",
                                                        height: "100px",
                                                        borderRadius: "50%",
                                                        objectFit: "cover",
                                                    }}
                                                />
                                            </div>
                                            {/* Cột bên phải: Thông tin */}
                                            <div>
                                                <p className="card-text mb-1" style={{ fontSize: '0.9em' }}>
                                                    <FontAwesomeIcon icon={faPhone} /> <Link as={Link} to={`tel:+84${customer.phone}`} style={{ textDecoration: 'none' }}>{customer.phone}</Link> <br />
                                                    <FontAwesomeIcon icon={faEnvelope} /> {customer.email || "Chưa có email"} <br />
                                                    <FontAwesomeIcon icon={faMapMarkerAlt} /> {customer.address || "Chưa có địa chỉ"}<br />
                                                    <FontAwesomeIcon icon={faStickyNote} /> {customer.note || "Không có ghi chú"}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer d-flex justify-content-between">
                                        <Button
                                            variant="warning"
                                            size="sm"
                                            onClick={() => handleShowEdit(customer._id)}
                                        >
                                            <FontAwesomeIcon icon={faPencil} /> Sửa
                                        </Button>
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() => confirmDelete(customer._id)}
                                            disabled={consultationCustomerIds.includes(customer._id)}
                                        >
                                            <FontAwesomeIcon icon={faTrash} /> Xóa
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <Table striped bordered hover>
                        <thead className="text-center">
                            <tr>
                                <th>
                                    STT
                                    <FontAwesomeIcon
                                        icon={visibleColumns.index ? faEye : faEyeSlash}
                                        onClick={() => toggleColumnVisibility('index')}
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </th>
                                <th>
                                    Họ Và Tên
                                    <FontAwesomeIcon
                                        icon={visibleColumns.name ? faEye : faEyeSlash}
                                        onClick={() => toggleColumnVisibility('name')}
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </th>
                                <th>
                                    Ngày Sinh
                                    <FontAwesomeIcon
                                        icon={visibleColumns.birthday ? faEye : faEyeSlash}
                                        onClick={() => toggleColumnVisibility('birthday')}
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </th>
                                <th>
                                    Giới Tính
                                    <FontAwesomeIcon
                                        icon={visibleColumns.gender ? faEye : faEyeSlash}
                                        onClick={() => toggleColumnVisibility('gender')}
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </th>
                                <th>
                                    Ngày Nhập
                                    <FontAwesomeIcon
                                        icon={visibleColumns.createdAt ? faEye : faEyeSlash}
                                        onClick={() => toggleColumnVisibility('createdAt')}
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </th>
                                <th>
                                    Số ĐT
                                    <FontAwesomeIcon
                                        icon={visibleColumns.phone ? faEye : faEyeSlash}
                                        onClick={() => toggleColumnVisibility('phone')}
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </th>
                                <th>
                                    Email
                                    <FontAwesomeIcon
                                        icon={visibleColumns.email ? faEye : faEyeSlash}
                                        onClick={() => toggleColumnVisibility('email')}
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </th>
                                <th>
                                    Địa Chỉ
                                    <FontAwesomeIcon
                                        icon={visibleColumns.address ? faEye : faEyeSlash}
                                        onClick={() => toggleColumnVisibility('address')}
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </th>
                                <th>
                                    Ghi Chú
                                    <FontAwesomeIcon
                                        icon={visibleColumns.note ? faEye : faEyeSlash}
                                        onClick={() => toggleColumnVisibility('note')}
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </th>
                                <th>
                                    Hành Động
                                    <FontAwesomeIcon
                                        icon={visibleColumns.actions ? faEye : faEyeSlash}
                                        onClick={() => toggleColumnVisibility('actions')}
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayedCustomers.map((customer, index) => (
                                <tr key={customer._id}>
                                    {visibleColumns.index ? <td>{index + 1}</td> : <td>&nbsp;</td>}
                                    {visibleColumns.name ? <td><b>{customer.name}</b></td> : <td>&nbsp;</td>}
                                    {visibleColumns.birthday ? <td>{formatDate(customer.birthday)}</td> : <td>&nbsp;</td>}
                                    {visibleColumns.gender ? <td>{customer.gender}</td> : <td>&nbsp;</td>}
                                    {visibleColumns.createdAt ? <td>{formatDate(customer.createdAt)}</td> : <td>&nbsp;</td>}
                                    {visibleColumns.phone ? <td>{customer.phone}</td> : <td>&nbsp;</td>}
                                    {visibleColumns.email ? <td>{customer.email}</td> : <td>&nbsp;</td>}
                                    {visibleColumns.address ? <td>{customer.address}</td> : <td>&nbsp;</td>}
                                    {visibleColumns.note ? <td>{customer.note}</td> : <td>&nbsp;</td>}
                                    {visibleColumns.actions ?
                                        <td className="text-center">
                                            <Button variant="warning" size="sm" onClick={() => handleShowEdit(customer._id)} title="Sửa thông tin Khách Hàng" className="m-1">
                                                <FontAwesomeIcon icon={faPencil} /> Sửa
                                            </Button>
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                onClick={() => confirmDelete(customer._id)}
                                                title="Xóa Khách Hàng"
                                                className="m-1"
                                                disabled={consultationCustomerIds.includes(customer._id)} // Vô hiệu hóa nút nếu khách hàng có trong danh sách Tư Vấn
                                            >
                                                <FontAwesomeIcon icon={faTrash} /> Xóa
                                            </Button>
                                        </td>
                                        : <td>&nbsp;</td>}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
                <AddCustomer onReFresh={reFreshCustomers} show={showAddModal} handleClose={handleCloseAdd} />
                <EditCustomer onReFresh={reFreshCustomers} show={showEditModal} handleClose={handleCloseEdit} customerId={currentCustomerId} />
                <ModalConfirm
                    show={showConfirmModal}
                    handleClose={() => setShowConfirmModal(false)} // Đóng modal nếu người dùng hủy
                    handleConfirm={handleDeleteCustomer} // Xóa khách hàng khi xác nhận
                />
            </PageWrapper>
        </div>
    );
};

export default CustomerList;
