import React, { useEffect, useState, useRef } from 'react';
import { Modal, Form, Button, InputGroup } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import { to_vietnamese } from './numberToWords';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';

const AddReceiptModal = ({ show, handleClose, onReFresh }) => {
    const [consultations, setConsultations] = useState([]);
    const [selectedConsultation, setSelectedConsultation] = useState(null);
    const [customerId, setCustomerId] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [address, setAddress] = useState('');
    const [services, setServices] = useState('');
    const [amount, setAmount] = useState(0);
    const [amountInWords, setAmountInWords] = useState('');
    const [notes, setNotes] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [totalPrice, setTotalPrice] = useState(0);
    const [remainingAmount, setRemainingAmount] = useState(0);
    const [promoteAmount, setPromoteAmount] = useState(0);
    const [customContent, setCustomContent] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;
    const inputRefs = useRef([]);

    useEffect(() => {
        if (show) {
            inputRefs.current[0]?.focus();
        }
    }, [show]);

    const token = localStorage.getItem('token');
    let userId;
    if (token && typeof token === 'string') {
        try {
            const decoded = jwtDecode(token);
            userId = decoded.userId;
        } catch (error) {
            console.error('Token không hợp lệ:', error);
        }
    }

    const fetchConsultations = async () => {
        try {
            const response = await axios.get(`${apiUrl}/consultations?createdBy=${userId}`);
            if (response.data.success) {
                const sortedConsultations = response.data.consultations.sort((a, b) => new Date(b.date) - new Date(a.date));
                const consultationOptions = sortedConsultations.map(c => ({
                    value: c._id,
                    label: `${c.customerId.name} - ${formatDate(c.date)}`,
                    data: c
                }));
                setConsultations(consultationOptions);
            }
        } catch (error) {
            console.error('Lỗi khi lấy danh sách tư vấn:', error);
        }
    };

    useEffect(() => {
        fetchConsultations();
    }, [apiUrl, userId]);

    const handleConsultationChange = (selectedOption) => {
        if (selectedOption) {
            const consultation = selectedOption.data;
            setSelectedConsultation(consultation);
            setCustomerId(consultation.customerId._id);
            setCustomerName(consultation.customerId.name);
            setAddress(consultation.customerId.address);
            const serviceNamesList = consultation.services.map(service => service.name).join(', ');
            setServices(serviceNamesList);
            setTotalPrice(consultation.totalPrice);
            setAmountInWords(to_vietnamese(consultation.totalPrice));
            inputRefs.current[3]?.focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            let nextIndex = index + 1;
            while (nextIndex < inputRefs.current.length && (inputRefs.current[nextIndex].readOnly || inputRefs.current[nextIndex].disabled)) {
                nextIndex++;
            }
            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex]?.focus();
            } else {
                document.getElementById('submitButton')?.focus();
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${apiUrl}/receipts`, {
                consultationId: selectedConsultation?._id, // Thêm consultationId vào dữ liệu gửi
                customerId,
                customerName: customerName.toUpperCase(),
                address,
                services: customContent || services,
                amount: amount || totalPrice,
                amountInWords: amountInWords.charAt(0).toUpperCase() + amountInWords.slice(1),
                notes,
                paymentMethod,
                totalPrice,
                remainingAmount,
                promoteAmount,
                createdBy: userId
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            toast.success('Tạo phiếu thu thành công!');
            handleClose();
            resetForm();
            onReFresh();
        } catch (error) {
            toast.error('Lỗi khi tạo phiếu thu!');
            console.error('Lỗi khi tạo phiếu thu:', error);
        }
    };

    const resetForm = () => {
        setCustomerId('');
        setCustomerName('');
        setAddress('');
        setServices('');
        setAmount(0);
        setAmountInWords('');
        setNotes('');
        setPaymentMethod('');
        setTotalPrice(0);
        setRemainingAmount(0);
        setPromoteAmount(0);
        setCustomContent('');
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;
    };

    return (
        <Modal show={show} onHide={handleClose} size='xl'>
            <Modal.Header closeButton>
                <Modal.Title><FontAwesomeIcon icon={faFileInvoiceDollar} /> Thêm Phiếu Thu</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className='mb-3'>
                        <InputGroup>
                            <InputGroup.Text>Chọn Khách Hàng</InputGroup.Text>
                            <Select
                                options={consultations}
                                onChange={handleConsultationChange}
                                placeholder="Tìm kiếm khách hàng..."
                                ref={el => inputRefs.current[0] = el}
                                styles={{ container: (base) => ({ ...base, flex: 1 }) }}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className='mb-3'>
                        <InputGroup>
                            <InputGroup.Text>Khách Hàng</InputGroup.Text>
                            <Form.Control type="text" value={customerName} disabled ref={el => inputRefs.current[1] = el} />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className='mb-3'>
                        <InputGroup>
                            <InputGroup.Text>Địa Chỉ</InputGroup.Text>
                            <Form.Control type="text" value={address} disabled ref={el => inputRefs.current[2] = el} />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className='mb-3'>
                        <InputGroup>
                            <InputGroup.Text>Nội dung nộp</InputGroup.Text>
                            <Form.Control
                                type="text"
                                value={customContent || services.charAt(0).toUpperCase() + services.slice(1)}
                                onChange={(e) => setCustomContent(e.target.value)}
                                ref={el => inputRefs.current[3] = el}
                                onKeyDown={(e) => handleKeyDown(e, 3)}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className='mb-3'>
                        <InputGroup>
                            <InputGroup.Text>Tổng thanh toán</InputGroup.Text>
                            <Form.Control type="number" value={totalPrice} disabled ref={el => inputRefs.current[4] = el} />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className='mb-3'>
                        <InputGroup>
                            <InputGroup.Text>Số tiền khách trả</InputGroup.Text>
                            <Form.Control
                                type="number"
                                value={amount !== 0 ? amount : totalPrice}
                                onChange={(e) => {
                                    const value = Number(e.target.value);
                                    setAmount(value);
                                    setRemainingAmount(totalPrice - value - promoteAmount);
                                    setAmountInWords(to_vietnamese(value));
                                }}
                                ref={el => inputRefs.current[5] = el}
                                onKeyDown={(e) => handleKeyDown(e, 5)}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className='mb-3'>
                        <InputGroup>
                            <InputGroup.Text>Số tiền giảm</InputGroup.Text>
                            <Form.Control
                                type="number"
                                value={promoteAmount}
                                onChange={(e) => setPromoteAmount(Number(e.target.value))}
                                ref={el => inputRefs.current[6] = el}
                                onKeyDown={(e) => handleKeyDown(e, 6)}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className='mb-3'>
                        <InputGroup>
                            <InputGroup.Text>Số tiền còn nợ</InputGroup.Text>
                            <Form.Control type="number" value={remainingAmount} disabled ref={el => inputRefs.current[7] = el} />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className='mb-3'>
                        <InputGroup>
                            <InputGroup.Text>Bằng chữ</InputGroup.Text>
                            <Form.Control type="text" value={amountInWords.charAt(0).toUpperCase() + amountInWords.slice(1)} disabled ref={el => inputRefs.current[8] = el} />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className='mb-3'>
                        <InputGroup>
                            <InputGroup.Text>Ghi Chú</InputGroup.Text>
                            <Form.Control
                                type="text"
                                value={notes}
                                onChange={(e) => setNotes(e.target.value)}
                                ref={el => inputRefs.current[9] = el}
                                onKeyDown={(e) => handleKeyDown(e, 9)}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className='mb-3'>
                        <InputGroup>
                            <InputGroup.Text>Hình thức thanh toán</InputGroup.Text>
                            <Form.Select value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)} ref={el => inputRefs.current[10] = el} onKeyDown={(e) => handleKeyDown(e, 10)}>
                                <option value="Tiền mặt">Tiền mặt</option>
                                <option value="Chuyển khoản">Chuyển khoản</option>
                                <option value="Quẹt thẻ">Quẹt thẻ</option>
                            </Form.Select>
                        </InputGroup>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <Button id="submitButton" variant="primary" onClick={handleSubmit}>Tạo Phiếu Thu</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddReceiptModal;
