import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { jwtDecode } from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faUser, faStickyNote, faListNumeric, faHandHoldingMedical, faUserTie, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

const AddConsultation = ({ show, handleClose, onReFresh }) => {
    const [customerId, setCustomerId] = useState(null);
    const [selectedServices, setSelectedServices] = useState([]);
    const [unselectedServices, setUnselectedServices] = useState([]);
    const [staffId, setStaffId] = useState(null);
    const [executionDate, setExecutionDate] = useState('');
    const [note, setNote] = useState('');
    const [customers, setCustomers] = useState([]);
    const [availableServices, setAvailableServices] = useState([]);
    const [staffOptions, setStaffOptions] = useState([]);
    const [courseCount, setCourseCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const apiUrl = process.env.REACT_APP_API_URL;

    const token = localStorage.getItem('token');
    const userId = jwtDecode(token)?.userId;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [customersResponse, servicesResponse, staffResponse] = await Promise.all([
                    axios.get(`${apiUrl}/customers?createdBy=${userId}`),
                    axios.get(`${apiUrl}/services?createdBy=${userId}`),
                    axios.get(`${apiUrl}/user/${userId}/staff`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                ]);

                setCustomers(customersResponse.data.map(c => ({
                    value: c._id,
                    label: `${c.name} - ${c.gender} - ${new Date(c.birthday).getFullYear()} - ${c.phone}`
                })));

                const servicesData = servicesResponse.data.map(s => ({
                    id: s._id,
                    name: s.name,
                    price: s.price
                }));
                setAvailableServices(servicesData);
                setUnselectedServices(servicesData);

                setStaffOptions(staffResponse.data.staff.map(s => ({
                    value: s._id,
                    label: s.name
                })));
            } catch (error) {
                console.error("Lỗi khi tải dữ liệu:", error);
            }
        };

        fetchData();
    }, [apiUrl, userId]);


    const handleServiceToggle = (serviceId) => {
        const updatedSelectedServices = selectedServices.filter(service => service.serviceId.id !== serviceId);
        const serviceToUnselect = availableServices.find(service => service.id === serviceId);
        setSelectedServices(updatedSelectedServices);
        setUnselectedServices([...unselectedServices, serviceToUnselect]);
    };

    const handleAddService = (serviceId) => {
        const newService = availableServices.find(service => service.id === serviceId);
        setSelectedServices([...selectedServices, { serviceId: { id: serviceId }, name: newService.name, price: newService.price }]);
        setUnselectedServices(unselectedServices.filter(service => service.id !== serviceId));
    };

    const handleAddConsultation = async () => {
        try {
            // Chuyển `serviceId` thành chuỗi thay vì đối tượng lồng
            const servicesPayload = selectedServices.map(s => ({
                serviceId: s.serviceId.id || s.serviceId, // lấy `id` trực tiếp nếu là đối tượng
                name: s.name,
                price: s.price
            }));

            await axios.post(`${apiUrl}/consultations`, {
                customerId: customerId?.value,
                services: servicesPayload,
                createdBy: userId,
                courseCount,
                note,
                staffId: staffId?.value || userId,
                executionDate: executionDate || new Date()
            });

            handleClose();
            onReFresh();
            toast.success('Tạo tư vấn mới thành công!');
        } catch (error) {
            console.error("Error while adding consultation:", error.response?.data || error);
        }
    };

    const filteredServices = unselectedServices
        .filter(service =>
            service.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
            !selectedServices.some(selected => selected.serviceId.id === service.id)
        );
    // Hàm tính tổng chi phí
    const calculateTotalCost = () => {
        return selectedServices.reduce((total, service) => total + (service.price || 0), 0);
    };

    return (
        <Modal show={show} onHide={handleClose} size='xl'>
            <Modal.Header closeButton>
                <Modal.Title><FontAwesomeIcon icon={faComments} /> Tạo Tư Vấn Mới</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faUser} /></InputGroup.Text>
                            <Select
                                options={customers}
                                value={customerId}
                                onChange={(option) => setCustomerId(option)}
                                placeholder="Chọn khách hàng..."
                                isClearable
                                styles={{ container: (base) => ({ ...base, flex: 1 }) }}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label><FontAwesomeIcon className="m-1" icon={faHandHoldingMedical} /> <b>Dịch Vụ Đã Chọn - Tổng chi phí: <span className='text-danger'>{calculateTotalCost().toLocaleString('vi-VN')}</span> VND</b></Form.Label>
                        {selectedServices.map(service => (
                            <div key={uuidv4()} className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked
                                    onChange={() => handleServiceToggle(service.serviceId.id)}
                                />
                                <label className="form-check-label">
                                    {service.name} - {service.price.toLocaleString()} VND
                                </label>
                            </div>
                        ))}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Control
                            type="text"
                            placeholder="Tìm kiếm dịch vụ..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <div style={{ maxHeight: '100px', overflowY: 'auto', marginTop: '5px' }}>
                            {filteredServices.map(service => (
                                <div key={uuidv4()} className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        onChange={() => handleAddService(service.id)}
                                    />
                                    <label className="form-check-label">
                                        {service.name} - {service.price.toLocaleString()} VND
                                    </label>
                                </div>
                            ))}
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faListNumeric} /> Số Lần Liệu Trình</InputGroup.Text>
                            <Form.Control
                                type="number"
                                placeholder="Số lần liệu trình"
                                value={courseCount}
                                onChange={(e) => setCourseCount(Number(e.target.value))}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faUserTie} /> Nhân Viên</InputGroup.Text>
                            <Select
                                options={staffOptions}
                                value={staffId}
                                onChange={(option) => setStaffId(option)}
                                placeholder="Chọn nhân viên thực hiện..."
                                styles={{ container: (base) => ({ ...base, flex: 1 }) }}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faCalendarAlt} /> Ngày Thực Hiện</InputGroup.Text>
                            <Form.Control
                                type="date"
                                value={executionDate}
                                onChange={(e) => setExecutionDate(e.target.value)}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faStickyNote} /> Ghi Chú</InputGroup.Text>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Nhập nội dung ghi chú"
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                            />
                        </InputGroup>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Hủy</Button>
                <Button variant="primary" onClick={handleAddConsultation} disabled={!customerId}>
                    Thêm Tư Vấn
                </Button>
            </Modal.Footer>
        </Modal >
    );
};

export default AddConsultation;
