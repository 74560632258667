import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Form, Row, Col, Button, Container, InputGroup, Table } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiagnoses, faPrescription, faUser } from '@fortawesome/free-solid-svg-icons';
import PrescriptionList from './PrescriptionList';
import ModalConfirm from './ModalConfirm';
import MedicineCustomOption from './MedicineCustomOption';

const Prescription = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [customers, setCustomers] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [diagnosis, setDiagnosis] = useState('');
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [selectedTemplateToDelete, setSelectedTemplateToDelete] = useState(null);
    const [isInStock, setIsInStock] = useState(true);
    const [totalAmount, setTotalAmount] = useState(0);
    const [doctorAdvice, setDoctorAdvice] = useState('');
    const [prescriptions, setPrescriptions] = useState([
        { medicineId: '', days: '', timesPerDay: '', dosePerTime: '', usageTime: [], route: '', unit: '', note: '' }
    ]);
    const [medicines, setMedicines] = useState([]);
    const [reload, setReload] = useState(false);

    // Hàm để làm mới danh sách toa thuốc
    const refreshPrescriptionList = () => {
        setReload(prev => !prev);
    };

    const token = localStorage.getItem('token');
    const decoded = jwtDecode(token);
    const userId = decoded.userId;

    const inputRefs = useRef([]);

    const fetchTemplates = useCallback(async () => {
        try {
            const response = await axios.get(`${apiUrl}/prescription-templates?createdBy=${userId}`);
            setTemplates(response.data.data);
        } catch (error) {
            console.error('Lỗi khi tải toa mẫu:', error);
        }
    }, [apiUrl, userId]);

    useEffect(() => {
        fetchTemplates();
    }, [fetchTemplates]);

    const fetchData = useCallback(async () => {
        try {
            const [customerRes, templateRes, medicineRes] = await Promise.all([
                axios.get(`${apiUrl}/customers?createdBy=${userId}`),
                axios.get(`${apiUrl}/prescription-templates?createdBy=${userId}`),
                axios.get(`${apiUrl}/medicines?createdBy=${userId}`)
            ]);

            setCustomers(customerRes.data.map(c => ({
                value: c._id,
                label: `${c.name} - ${c.gender} - ${new Date(c.birthday).getFullYear()} - ${c.phone}`
            })));

            setTemplates(templateRes.data.data);
            setMedicines(medicineRes.data.map(m => ({
                value: m._id,
                label: m.name,
                name: m.name,
                quantity: m.quantity,
                price: m.price,
                unit: m.unit,
                usemed: m.usemed,
            })));

        } catch (error) {
            console.error('Lỗi khi lấy dữ liệu:', error);
        }
    }, [apiUrl, userId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        inputRefs.current[0]?.focus();
    }, []);

    useEffect(() => {
        if (isInStock) calculateTotalAmount();
        else setTotalAmount(0); // Nếu không bán thuốc từ kho, không tính tổng tiền
    }, [prescriptions, isInStock]);

    const handleSelectCustomerChange = (selectedOption) => {
        setSelectedCustomer(selectedOption);
        inputRefs.current[1]?.focus();
    };

    const handleAddPrescription = () => {
        setPrescriptions((prevPrescriptions) => {
            const newPrescription = {
                medicineId: '',
                days: '',
                timesPerDay: '',
                dosePerTime: '',
                usageTime: [],
                route: '',
                unit: '',
                note: ''
            };
            const newPrescriptions = [...prevPrescriptions, newPrescription];

            // Đặt focus vào ô chọn thuốc của dòng mới nhất sau khi thêm
            setTimeout(() => {
                const newIndex = (newPrescriptions.length - 1) * 7 + 2; // Cập nhật chỉ số chính xác của ô chọn thuốc mới
                inputRefs.current[newIndex]?.focus();
            }, 0); // Đặt thời gian chờ để đảm bảo dòng mới đã được thêm vào trước khi focus

            return newPrescriptions;
        });
    };


    const handleSelectChange = (selectedOption, index) => {
        setPrescriptions(prescriptions.map((r, i) =>
            i === index ? { ...r, medicineId: selectedOption.value, route: selectedOption.usemed || '', unit: selectedOption.unit || '' } : r
        ));
        inputRefs.current[index * 7 + 3]?.focus();
    };

    const handleCheckboxChange = (index, time) => {
        const updatedPrescriptions = [...prescriptions];
        if (updatedPrescriptions[index].usageTime.includes(time)) {
            updatedPrescriptions[index].usageTime = updatedPrescriptions[index].usageTime.filter(t => t !== time);
        } else {
            updatedPrescriptions[index].usageTime.push(time);
        }
        setPrescriptions(updatedPrescriptions);
    };

    const handleInputChange = (index, field, value) => {
        const updatedPrescriptions = [...prescriptions];
        updatedPrescriptions[index][field] = value;
        setPrescriptions(updatedPrescriptions);
    };

    const handleKeyDown = (e, index, field) => {
        if (e.key === 'Enter') {
            e.preventDefault();

            // Kiểm tra nếu field là 'usageTime' thì ngăn mọi hành động và không focus tới trường khác
            if (field === 'usageTime') {
                return; // Dừng lại mà không thực hiện thêm bất kỳ hành động nào
            }

            let nextIndex;

            if (field === 'customer') {
                nextIndex = 1;
            } else if (field === 'diagnosis') {
                nextIndex = 2;
            } else if (field === 'medicineId') {
                nextIndex = index * 7 + 3;
            } else if (field === 'days') {
                nextIndex = index * 7 + 4;
            } else if (field === 'timesPerDay') {
                nextIndex = index * 7 + 5;
            } else if (field === 'dosePerTime') {
                nextIndex = `checkbox-${index}`;
            } else if (field === 'note') {
                nextIndex = 'addmore';
            }

            if (nextIndex === 'addmore') {
                document.getElementById('addMoreMedicine')?.focus();
            } else if (typeof nextIndex === 'string' && nextIndex.startsWith('checkbox')) {
                inputRefs.current[nextIndex]?.focus();
            } else {
                inputRefs.current[nextIndex]?.focus();
            }
        }
    };

    const resetForm = () => {
        setSelectedCustomer(null);
        setDiagnosis('');
        setPrescriptions([{ medicineId: '', days: '', timesPerDay: '', dosePerTime: '', usageTime: [], route: '', note: '' }]);
        inputRefs.current[0]?.focus();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');
            const decoded = jwtDecode(token);
            const userId = decoded.userId;

            const formattedPrescriptions = prescriptions.map(prescription => ({
                ...prescription,
                usageTime: Array.isArray(prescription.usageTime)
                    ? prescription.usageTime.join(', ')
                    : prescription.usageTime,
                days: prescription.days
            }));

            // Lưu toa thuốc
            await axios.post(`${apiUrl}/prescriptions`, {
                customerId: selectedCustomer.value,
                diagnosis,
                prescriptions: formattedPrescriptions,
                createdBy: userId,
                doctorAdvice,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            toast.success('Kê toa thuốc thành công!');

            // Nếu chọn "Bán thuốc từ kho", thực hiện trừ kho
            if (isInStock) {
                try {
                    const stockUpdates = formattedPrescriptions.map(async (prescription) => {
                        // Lấy số lượng hiện tại của từng thuốc trong kho
                        const medicineResponse = await axios.get(`${apiUrl}/medicines/${prescription.medicineId}`);
                        const currentQuantity = medicineResponse.data.quantity;

                        // Tính toán số lượng mới sau khi trừ
                        const updatedQuantity = currentQuantity - (prescription.dosePerTime * prescription.days * prescription.timesPerDay);

                        // Kiểm tra số lượng đủ hay không
                        if (updatedQuantity < 0) {
                            throw new Error(`Số lượng thuốc "${medicineResponse.data.name}" không đủ trong kho.`);
                        }

                        return axios.put(`${apiUrl}/medicines/${prescription.medicineId}`, {
                            quantity: updatedQuantity,
                        }, {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });
                    });

                    // Thực hiện cập nhật tất cả các loại thuốc song song
                    await Promise.all(stockUpdates);
                    toast.success("Cập nhật kho thuốc thành công!");
                } catch (error) {
                    console.error("Lỗi khi cập nhật kho thuốc:", error);
                    toast.error("Không thể cập nhật kho thuốc!");
                }
            }

            resetForm(); // Reset form sau khi lưu thành công
            refreshPrescriptionList();
        } catch (error) {
            console.error('Lỗi khi kê toa:', error);
            toast.error('Lỗi khi kê toa thuốc!');
        }
    };


    const handleApplyTemplate = (template) => {
        setDiagnosis(template.diagnosis || '');
        setPrescriptions(template.prescriptions);
        toast.success(`Áp dụng mẫu "${template.name}" thành công!`);
    };

    const handleDeleteTemplate = async () => {
        if (!selectedTemplateToDelete) return;

        try {
            await axios.delete(`${apiUrl}/prescription-templates/${selectedTemplateToDelete}`);
            setTemplates(templates.filter(template => template._id !== selectedTemplateToDelete));
            toast.success('Xóa toa mẫu thành công!');
            setShowConfirmDelete(false);
        } catch (error) {
            console.error('Lỗi khi xóa toa mẫu:', error);
            toast.error('Lỗi khi xóa toa mẫu!');
        }
    };

    // Tính tổng số tiền của toa thuốc
    const calculateTotalAmount = async () => {
        let total = 0;
        for (const prescription of prescriptions) {
            try {
                const response = await axios.get(`${apiUrl}/medicines/${prescription.medicineId}`);
                const price = response.data.price;
                const amount = prescription.dosePerTime * prescription.timesPerDay * prescription.days * price;
                total += amount;
            } catch (error) {
                console.error("Lỗi khi lấy giá thuốc:", error);
            }
        }
        setTotalAmount(total);
    };

    return (
        <Container fluid className='mt-2'>
            <h2><FontAwesomeIcon icon={faPrescription} /> Kê Toa Thuốc</h2>
            <Row>
                <Col md={10}>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="sellFromStock" className="mb-3" style={{ cursor: 'pointer', pointerEvents: 'auto' }}>
                            <Form.Switch
                                label={isInStock ? "Bán thuốc (sẽ trừ số lượng thuốc trong kho)" : "Chỉ in toa cho khách"}
                                checked={isInStock}
                                onChange={(e) => setIsInStock(e.target.checked)}
                                style={{ cursor: 'pointer' }}
                            />
                        </Form.Group>
                        <Form.Group controlId="selectCustomer" className='mb-3'>
                            <Form.Label><FontAwesomeIcon icon={faUser} /> Chọn Khách Hàng</Form.Label>
                            <Select
                                options={customers}
                                value={selectedCustomer}
                                onChange={handleSelectCustomerChange}
                                placeholder="Tìm và chọn khách hàng..."
                                ref={el => inputRefs.current[0] = el}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon={faDiagnoses} />&nbsp;Chẩn đoán</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    placeholder="Chẩn đoán"
                                    value={diagnosis} // Đảm bảo cập nhật giá trị từ state
                                    onChange={(e) => setDiagnosis(e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(e, 0, 'diagnosis')}
                                    ref={el => inputRefs.current[1] = el}
                                />
                            </InputGroup>
                        </Form.Group>
                        {prescriptions.map((prescription, index) => (
                            <div key={index} className="mb-4">
                                <Row className="mb-4">
                                    <Col md={1} className='m-1 text-center'>
                                        <b>{index + 1}.</b>
                                    </Col>
                                    <Col md={4}>
                                        <Select
                                            options={medicines}
                                            value={prescriptions[index].medicineId ? {
                                                value: prescriptions[index].medicineId,
                                                label: medicines.find(m => m.value === prescriptions[index].medicineId)?.label || ''
                                            } : null}
                                            onChange={(option) => handleSelectChange(option, index)}
                                            placeholder="Chọn thuốc"
                                            ref={el => inputRefs.current[index * 7 + 2] = el}
                                            components={{ Option: MedicineCustomOption }} // Sử dụng MedicineCustomOption
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <InputGroup>
                                            <InputGroup.Text>Số ngày</InputGroup.Text>
                                            <Form.Control
                                                type="number"
                                                placeholder="0"
                                                value={prescription.days}
                                                onChange={(e) => handleInputChange(index, 'days', e.target.value)}
                                                onKeyDown={(e) => handleKeyDown(e, index, 'days')}
                                                ref={el => inputRefs.current[index * 7 + 3] = el}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col md={2}>
                                        <InputGroup>
                                            <InputGroup.Text>Lần/ngày</InputGroup.Text>
                                            <Form.Control
                                                type="number"
                                                placeholder="0"
                                                value={prescription.timesPerDay}
                                                onChange={(e) => handleInputChange(index, 'timesPerDay', e.target.value)}
                                                onKeyDown={(e) => handleKeyDown(e, index, 'timesPerDay')}
                                                ref={el => inputRefs.current[index * 7 + 4] = el}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col md={2}>
                                        <InputGroup>
                                            <InputGroup.Text>Mỗi lần</InputGroup.Text>
                                            <Form.Control
                                                type="number"
                                                placeholder="0"
                                                value={prescription.dosePerTime}
                                                onChange={(e) => handleInputChange(index, 'dosePerTime', e.target.value)}
                                                onKeyDown={(e) => handleKeyDown(e, index, 'dosePerTime')}
                                                ref={el => inputRefs.current[index * 7 + 5] = el}
                                            />
                                            <Form.Control
                                                as="select"
                                                value={prescription.route === 'Bôi' ? 'Lần' : prescription.unit}
                                                onChange={(e) => handleInputChange(index, 'unit', e.target.value)}
                                                disabled
                                            >
                                                <option value="">...</option>
                                                {prescription.route === 'Bôi' ? (
                                                    <option value="Lần">Lần</option> // Hiển thị đơn vị là "Lần" khi đường dùng là "Bôi"
                                                ) : (
                                                    ['Viên', 'Ống', 'Gói'].map(unit => (
                                                        <option key={unit} value={unit}>{unit}</option>
                                                    ))
                                                )}
                                            </Form.Control>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <Form.Group controlId={`usageTime-${index}`}>
                                            <div>
                                                {['Sáng', 'Trưa', 'Chiều', 'Tối'].map((time, i) => (
                                                    <Form.Check
                                                        inline
                                                        key={time}
                                                        label={time}
                                                        type="checkbox"
                                                        checked={prescription.usageTime.includes(time)}
                                                        onChange={() => handleCheckboxChange(index, time)}
                                                        ref={el => {
                                                            if (i === 0) {
                                                                inputRefs.current[`checkbox-${index}`] = el;
                                                            }
                                                        }}
                                                    />
                                                ))}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <InputGroup>
                                            <InputGroup.Text>Đường dùng</InputGroup.Text>
                                            <Form.Control
                                                as="select"
                                                value={prescription.route}
                                                onChange={(e) => handleInputChange(index, 'route', e.target.value)}
                                                disabled
                                            >
                                                <option value="">...</option>
                                                {['Uống', 'Ngậm', 'Chích', 'Bôi'].map(route => (
                                                    <option key={route} value={route}>{route}</option>
                                                ))}
                                            </Form.Control>
                                        </InputGroup>
                                    </Col>
                                    <Col md={4}>
                                        <InputGroup>
                                            <InputGroup.Text>Ghi chú</InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Ghi chú"
                                                value={prescription.note}
                                                onChange={(e) => handleInputChange(index, 'note', e.target.value)}
                                                onKeyDown={(e) => handleKeyDown(e, index, 'note')}
                                                ref={el => inputRefs.current[index * 7 + 7] = el}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <hr /></div>
                        ))}
                        <Button id="addMoreMedicine" variant="success" onClick={handleAddPrescription}>Thêm thuốc +</Button>
                        <Form.Group controlId="doctorAdvice" className="mt-3">
                            <Form.Label><b>Lời dặn của bác sĩ:</b></Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Nhập lời dặn của bác sĩ..."
                                value={doctorAdvice}
                                onChange={(e) => setDoctorAdvice(e.target.value)}
                            />
                        </Form.Group>
                        <center>
                            <Button variant="primary" onClick={handleSubmit} className="mt-3 m-1">Tạo toa thuốc</Button>
                        </center>
                        <strong>Thành tiền: {isInStock && totalAmount > 0 ? totalAmount.toLocaleString('vi-VN') : 0} VND</strong>
                    </Form>
                </Col>
                <Col md={2}>
                    <h4 className='text-center'>Toa Mẫu</h4>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Tên Mẫu</th>
                                <th>Hành động</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(templates) && templates.map((template) => (
                                <tr key={template._id}>
                                    <td>{template.name}</td>
                                    <td>
                                        <Button size="sm" className="m-1" variant="primary" onClick={() => handleApplyTemplate(template)}>
                                            Áp dụng
                                        </Button>
                                        <Button
                                            className="m-1"
                                            size="sm"
                                            variant="danger"
                                            onClick={() => {
                                                setSelectedTemplateToDelete(template._id);
                                                setShowConfirmDelete(true);
                                            }}
                                        >
                                            Xóa
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row><hr />
            <PrescriptionList reload={reload} refreshPrescriptionList={refreshPrescriptionList} refreshTemplateList={fetchTemplates} />
            <ModalConfirm
                show={showConfirmDelete}
                handleClose={() => setShowConfirmDelete(false)}
                handleConfirm={handleDeleteTemplate}
            />
        </Container>
    );
};

export default Prescription;
