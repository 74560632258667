import React, { useEffect, useState, useCallback } from 'react';
import { Table, Button } from 'react-bootstrap';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { faCheck, faCheckCircle, faTasks, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CourseConfirmModal from '../components/CourseConfirmModal';
import Loading from '../components/Loading';
import ModalConfirm from '../components/ModalConfirm';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';

const Courses = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const token = localStorage.getItem('token');
    let userId;
    if (token && typeof token === 'string') {
        try {
            const decoded = jwtDecode(token);
            userId = decoded.userId;
        } catch (error) {
            console.error('Token không hợp lệ:', error);
        }
    }

    const [courses, setCourses] = useState([]);
    const [confirmations, setConfirmations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedConsultation, setSelectedConsultation] = useState(null);
    const [confirmationToDelete, setConfirmationToDelete] = useState(null);

    const fetchConsultations = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/consultations?createdBy=${userId}`);
            const consultations = response.data.consultations;

            if (Array.isArray(consultations)) {
                const filteredConsultations = consultations.filter(consultation => consultation.courseCount > 0);

                const sortedConsultations = filteredConsultations.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

                setCourses(sortedConsultations);
            } else {
                console.error('Consultations data is not an array:', consultations);
            }
        } catch (error) {
            console.error('Error fetching consultations:', error);
        } finally {
            setLoading(false);
        }
    }, [apiUrl, userId]);

    const fetchConfirmations = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/courses-confirm?createdBy=${userId}`);
            const confirmationsData = response.data;

            // Kiểm tra xem confirmationsData có phải là mảng hay không
            if (Array.isArray(confirmationsData)) {
                const confirmationsWithNames = await Promise.all(confirmationsData.map(async (confirmation) => {
                    const customerResponse = await axios.get(`${apiUrl}/customers/${confirmation.customerId._id}`);
                    return {
                        ...confirmation,
                        customerName: customerResponse.data.name
                    };
                }));

                // Sắp xếp danh sách theo thứ tự từ mới đến cũ dựa trên trường createdAt
                const sortedConfirmations = confirmationsWithNames.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

                setConfirmations(sortedConfirmations);
            } else {
                // Nếu không phải là mảng, có thể xử lý theo cách bạn muốn
                console.warn('Dữ liệu xác nhận không phải là mảng:', confirmationsData);
                setConfirmations([]); // Hoặc có thể không cập nhật gì nếu bạn không muốn
            }
        } catch (error) {
            console.error('Error fetching course confirmations:', error);
        } finally {
            setLoading(false);
        }
    }, [apiUrl, userId]);


    useEffect(() => {
        fetchConsultations();
        fetchConfirmations();
    }, [fetchConsultations, fetchConfirmations]);

    const reFreshAll = () => {
        fetchConsultations();
        fetchConfirmations();
    }

    const handleShowConfirmModal = (consultation) => {
        setSelectedConsultation(consultation);
        setShowConfirmModal(true);
    };

    const handleCloseConfirmModal = () => {
        setShowConfirmModal(false);
        setSelectedConsultation(null);
    };

    const handleDelete = (id) => {
        setConfirmationToDelete(id);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setConfirmationToDelete(null);
    };

    const handleConfirmDelete = async () => {
        try {
            await axios.delete(`${apiUrl}/courses-confirm/${confirmationToDelete}`);
            toast.success('Xóa bản ghi xác nhận thành công!');
            fetchConfirmations(); // Refresh danh sách xác nhận
        } catch (error) {
            console.error('Lỗi khi xóa bản ghi:', error);
            toast.error('Không thể xóa bản ghi.');
        } finally {
            handleCloseDeleteModal();
        }
    };

    return (
        <div className="m-2">
            <h3><FontAwesomeIcon icon={faTasks} /> Liệu Trình</h3>
            {loading ? (<Loading />) : (courses.length > 0 ? (
                <>
                    <Table striped bordered hover responsive>
                        <thead className="text-center">
                            <tr>
                                <th>STT</th>
                                <th>Mã Tư Vấn</th>
                                <th>Tên Khách Hàng</th>
                                <th>Tên Dịch Vụ</th>
                                <th>Số Lần Còn Lại</th>
                                <th>Ngày Tư Vấn</th>
                                <th>Hành Động</th>
                            </tr>
                        </thead>
                        <tbody>
                            {courses.map((consultation, index) => (
                                <tr key={consultation._id}>
                                    <td>{index + 1}</td>
                                    <td>{consultation.consultationCode.slice(0, 10)}</td>
                                    <td><b>{consultation.customerId.name}</b></td>
                                    <td>
                                        {consultation.services.map(service => (
                                            <div key={uuidv4()}>{service.name}</div>
                                        ))}
                                    </td>
                                    <td className="text-center"><b>{consultation.courseCount}</b></td>
                                    <td>{new Date(consultation.date).toLocaleDateString('vi-VN')}</td>
                                    <td className="text-center">
                                        <Button variant="primary" size="sm" className="me-2" onClick={() => handleShowConfirmModal(consultation)}>
                                            <FontAwesomeIcon icon={faCheckCircle} /> Xác Nhận Thực Hiện Liệu Trình
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </>
            ) : <div className='text-center'>Không có ca thực hiện Liệu Trình nào</div>
            )}

            {/* Bảng liệt kê các bản ghi xác nhận */}
            <h3><FontAwesomeIcon icon={faTasks} /> Bản Ghi Xác Nhận Thực Hiện Liệu Trình</h3>
            <Table striped bordered hover responsive>
                <thead className="text-center">
                    <tr>
                        <th>STT</th>
                        <th>Tên Khách Hàng</th>
                        <th>Ngày Thực Hiện</th>
                        <th>Liệu Trình Còn Lại</th>
                        <th>Ghi Chú</th>
                        <th>Hành Động</th>
                    </tr>
                </thead>
                <tbody>
                    {confirmations.length > 0 ? (
                        confirmations.map((confirmation, index) => (
                            <tr key={confirmation._id}>
                                <td>{index + 1}</td>
                                <td>{confirmation.customerName}</td>
                                <td>{new Date(confirmation.performedDate).toLocaleDateString('vi-VN')}</td>
                                <td>
                                    {confirmation.updatedCourseCount === 0 ? (
                                        <span>
                                            Đã hoàn thành <FontAwesomeIcon icon={faCheck} className='text-success' />
                                        </span>
                                    ) : (
                                        confirmation.updatedCourseCount
                                    )}
                                </td>
                                <td>{confirmation.notes || 'Không có'}</td>
                                <td className="text-center">
                                    <Button variant="danger" size="sm" onClick={() => handleDelete(confirmation._id)}>
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </Button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6" className="text-center">Chưa có bản ghi nào</td>
                        </tr>
                    )}
                </tbody>
            </Table>

            <CourseConfirmModal
                show={showConfirmModal}
                handleClose={handleCloseConfirmModal}
                consultation={selectedConsultation}
                onReFresh={reFreshAll} />

            <ModalConfirm
                show={showDeleteModal}
                handleClose={handleCloseDeleteModal}
                handleConfirm={handleConfirmDelete} />
        </div>
    );
};

export default Courses;
