// frontend\src\components\Medicines.js

import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPills, faPencil, faPrescription, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { Table, Button, FormControl } from 'react-bootstrap';
import AddMedicine from '../components/AddMedicine';
import EditMedicine from '../components/EditMedicine';
import CreateMedicineReceipt from '../components/CreateMedicineReceipt';
import Loading from '../components/Loading';
import { Link, useNavigate } from 'react-router-dom';
import formatDate from '../components/FormatDate';

const Medicines = () => {
    const [medicines, setMedicines] = useState([]);
    const [receipts, setReceipts] = useState({});
    const [search, setSearch] = useState("");
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showReceiptModal, setShowReceiptModal] = useState(false);
    const [currentMedicineId, setCurrentMedicineId] = useState(null);
    const [loading, setLoading] = useState(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate(); // Thêm useNavigate để điều hướng

    const token = localStorage.getItem('token');

    let userId;
    if (token && typeof token === 'string') {
        try {
            const decoded = jwtDecode(token);
            userId = decoded.userId;
        } catch (error) {
            console.error('Token không hợp lệ:', error);
        }
    } else {
        console.warn('Token không hợp lệ hoặc không tồn tại.');
    }

    const fetchMedicines = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/medicines?createdBy=${userId}`);
            setMedicines(response.data);

            const receiptResponse = await axios.get(`${apiUrl}/medicineReceipts?createdBy=${userId}`);
            const expirationMap = {};
            receiptResponse.data.forEach(receipt => {
                receipt.medicines.forEach(medicine => {
                    const medicineId = medicine.medicineId._id;
                    if (!expirationMap[medicineId]) {
                        expirationMap[medicineId] = [];
                    }
                    expirationMap[medicineId].push(medicine.expirationDate);
                });
            });

            for (const key in expirationMap) {
                expirationMap[key] = expirationMap[key].sort((a, b) => new Date(a) - new Date(b));
            }

            setReceipts(expirationMap);
            setLoading(false);
        } catch (error) {
            console.error('Lỗi khi tải Thuốc:', error);
            setLoading(false);
        }
    }, [apiUrl, userId]);

    useEffect(() => {
        fetchMedicines();
    }, [fetchMedicines]);

    const reFreshMedicines = () => {
        fetchMedicines();
    };

    const handleShowAdd = () => setShowAddModal(true);
    const handleCloseAdd = () => setShowAddModal(false);

    const handleShowReceipt = () => setShowReceiptModal(true);
    const handleCloseReceipt = () => setShowReceiptModal(false);

    const handleShowEdit = (medicineId) => {
        setCurrentMedicineId(medicineId);
        setShowEditModal(true);
    };

    const handleCloseEdit = () => {
        setCurrentMedicineId(null);
        setShowEditModal(false);
    };

    const handleNavigateToPrescription = () => {
        navigate('/prescription'); // Điều hướng đến trang kê toa thuốc
    };

    const filteredMedicines = medicines.filter(medicine =>
        medicine.name.toLowerCase().includes(search.toLowerCase()) ||
        medicine.medicineCode.includes(search)
    );

    const displayedMedicines = filteredMedicines
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, 10);

    return (
        <div className='m-2'>
            <h2><FontAwesomeIcon icon={faPills} /> Danh Sách Thuốc</h2>
            <div className='d-flex mb-3'>
                <Button variant='primary' onClick={handleShowAdd} className='m-1'>
                    Thêm Thuốc<FontAwesomeIcon icon={faPlus} className="m-1" />
                </Button>
                <Button variant='primary' onClick={handleShowReceipt} className='m-1'>
                    Nhập Thuốc<FontAwesomeIcon icon={faPills} className="m-1" />
                </Button>
                <Button variant="primary" title='Danh sách phiếu nhập Thuốc' className='m-1' as={Link} to='/medicinereceipts'>
                    <FontAwesomeIcon icon={faFileInvoice} /> Phiếu Nhập
                </Button>
                <Button variant="success" title='Kê toa' className='m-1' onClick={handleNavigateToPrescription}>
                    <FontAwesomeIcon icon={faPrescription} className='m-1' /> Kê Toa
                </Button>
                <FormControl
                    type='text'
                    placeholder='Tìm kiếm thuốc...'
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className='ms-3'
                    style={{ flexGrow: 1 }}
                />
            </div>
            {loading ? <Loading /> : (
                <Table striped bordered hover>
                    <thead className="text-center">
                        <tr>
                            <th>STT</th>
                            <th>Mã Thuốc</th>
                            <th>Tên Thuốc</th>
                            <th>Giá Thuốc (VND)</th>
                            <th>Đơn vị</th>
                            <th>Số lượng</th>
                            <th>Hạn Dùng</th>
                            <th>Đường dùng</th>
                            <th>Ghi chú</th>
                            <th>Hành động</th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayedMedicines.map((medicine, index) => (
                            <tr key={medicine._id}>
                                <td>{index + 1}</td>
                                <td>{medicine.medicineCode.slice(0, 6)}</td>
                                <td>{medicine.name}</td>
                                <td>{medicine.price.toLocaleString('vi-VN')}</td>
                                <td>{medicine.unit}</td>
                                <td>{medicine.quantity}</td>
                                <td>
                                    {receipts[medicine._id] && receipts[medicine._id].length > 0 ? (
                                        <div>
                                            {receipts[medicine._id].map((date, idx) => (
                                                <div key={idx}>{formatDate(new Date(date))}</div>
                                            ))}
                                        </div>
                                    ) : (
                                        "Không có hạn dùng"
                                    )}
                                </td>
                                <td>{medicine.usemed}</td>
                                <td>{medicine.note}</td>
                                <td className="text-center">
                                    <Button variant='warning' onClick={() => handleShowEdit(medicine._id)} title='Sửa thông tin Thuốc' className='m-1'><FontAwesomeIcon icon={faPencil} /></Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>)}
            <AddMedicine onReFresh={reFreshMedicines} show={showAddModal} handleClose={handleCloseAdd} />
            <EditMedicine onReFresh={reFreshMedicines} show={showEditModal} handleClose={handleCloseEdit} medicineId={currentMedicineId} />
            <CreateMedicineReceipt onReFresh={reFreshMedicines} show={showReceiptModal} handleClose={handleCloseReceipt} />
        </div>
    );
};

export default Medicines;
