import React, { useEffect, useState, useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

const PrintSupplyUsageModal = ({ show, handleClose, supplyUsage }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('token');

  let userId;
  if (token && typeof token === 'string') {
    try {
      const decoded = jwtDecode(token);
      userId = decoded.userId;
    } catch (error) {
      console.error('Token không hợp lệ:', error);
    }
  }

  const [companyInfo, setCompanyInfo] = useState({ name: '', nameCPN: '', logo: '', address: '', phone: '' });
  const [customerInfo, setCustomerInfo] = useState({ name: '', age: '', gender: '', address: '', phone: '' });
  const [detailedUsage, setDetailedUsage] = useState([]);

  const fetchUser = useCallback(async () => {
    if (userId) {
      try {
        const response = await axios.get(`${apiUrl}/user/${userId}`);
        setCompanyInfo({
          name: response.data.user.name,
          nameCPN: response.data.user.companyName,
          logo: response.data.user.logo,
          address: response.data.user.companyAddress,
          phone: response.data.user.companyPhone,
        });
      } catch (error) {
        console.error('Lỗi khi lấy thông tin người dùng:', error);
      }
    }
  }, [apiUrl, userId]);

  useEffect(() => {
    if (supplyUsage) {
      const fetchCustomerInfo = async () => {
        try {
          const response = await axios.get(`${apiUrl}/customers/${supplyUsage.customerId._id}`);
          const { name, birthday, gender, address, phone } = response.data;
          const age = new Date().getFullYear() - new Date(birthday).getFullYear();
          setCustomerInfo({ name, age, gender, address, phone });
        } catch (error) {
          console.error('Lỗi khi lấy thông tin khách hàng:', error);
        }
      };

      const fetchSupplyDetails = async () => {
        try {
          const supplies = await Promise.all(
            supplyUsage.usageDetails.map(async (item) => {
              const response = await axios.get(`${apiUrl}/supplies/${item.supplyId._id}`);
              return {
                ...item,
                name: response.data.name,
                unit: response.data.unit,
              };
            })
          );
          setDetailedUsage(supplies);
        } catch (error) {
          console.error('Lỗi khi lấy chi tiết vật tư:', error);
        }
      };

      fetchCustomerInfo();
      fetchSupplyDetails();
    }
    fetchUser();
  }, [supplyUsage, fetchUser]);

  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
<head>
  <title>In Vật Tư Sử Dụng</title>
  <style>
    @media print {
        @page { 
            size: A5; 
            margin: 5mm; 
        }
        body {
            font-family: Arial, sans-serif;
            font-size: 12px; /* Giảm kích thước chữ để tiết kiệm không gian */
            margin: 0;
            padding: 0;
            width: 100%;
        }
        .supply-usage {
            padding: 5px;
            width: 100%;
            position: relative;
            min-height: 100%;
            box-sizing: border-box;
        }
        .header {
            text-align: center;
            margin-bottom: 5px;
        }
        .logo {
            max-width: 40px; /* Giảm kích thước logo */
        }
        .content {
            margin: 5px 0;
        }
        footer {
            position: absolute;
            bottom: 5mm; /* Đặt sát lề dưới */
            width: 100%;
            font-size: 12px; /* Giảm kích thước chữ ở footer */
        }
        .footer-content {
            display: flex; 
            justify-content: space-between; 
            width: 100%;
        }
    }
</style>
</head>
<body>
    <div class="supply-usage">
        <table style="width:100%">
            <tr>
                <td rowspan="4" style="width: 40px">
                    ${companyInfo.logo ? `<img src="${companyInfo.logo}" class="logo" alt="Logo"/>` : ''}
                </td>
                <td style="font-size: 12px;">${companyInfo.nameCPN}</td>
                <td style="text-align: right; margin-right: 20px;"></td>
            </tr>
            <tr><td style="font-size: 12px;">Địa chỉ: ${companyInfo.address}</td><td></td></tr>
            <tr><td style="font-size: 12px;">Điện thoại: ${companyInfo.phone}</td><td></td></tr>
        </table>
        <center>
            <h1>PHIẾU VẬT TƯ</h1>
        </center>
        <p><b>Họ Tên:</b> ${customerInfo.name.toUpperCase()} - <b>Tuổi:</b> ${customerInfo.age} - <b>Giới:</b> ${customerInfo.gender}</p>
        <p><b>Địa chỉ:</b> ${customerInfo.address} - <b>Số ĐT:</b> ${customerInfo.phone}</p>
        
        ${detailedUsage.map((item, index) => `
            <p style="display: flex; justify-content: space-between; width: 100%; margin: 2px 0;">
                <b style="margin-left: 20px;">${index + 1}. ${item.name}</b>
                <span style="flex-grow: 1; text-align: right; margin-right: 20px;">${item.quantity} ${item.unit}</span>
            </p>
            <p style="width: 100%; margin: 0;">
                <span style="margin-left: 30px;">Ghi chú: ${item.note || 'Không có'}</span>
            </p>
        `).join('')}
        <footer>
    <hr />
    <!-- Phần cuối trang với 2 cột -->
    <div class="doctor-notes" style="display: flex; justify-content: space-between; width: 100%; padding-top: 5px;">
        <div style="flex: 1; padding-left: 20px;">
            <b>Lời dặn:</b>
            <p>
                ${supplyUsage.instructions || "- Sử dụng theo hướng dẫn.<br/>- Kiểm tra kỹ vật tư trước khi sử dụng.<br/>- Liên hệ ngay khi có vấn đề."}
            </p>
        </div>
        <div style="flex: 1; text-align: center;">
            <p style="margin: 0; padding-top: 0px;">
                <i>Ngày ${new Date().getDate()} Tháng ${new Date().getMonth() + 1} Năm ${new Date().getFullYear()}</i><br/>
                <b>Người Phụ Trách</b>
            </p>
            <p style="margin-top: 40px;"><b>${companyInfo.name}</b></p>
        </div>
    </div>
</footer>
</body>
</html>`);
    printWindow.document.close();
    handleClose();
    setTimeout(() => {
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    }, 500);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title><FontAwesomeIcon icon={faPrint} /> Xem Vật Tư Sử Dụng</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Khách Hàng: <b>{customerInfo.name}</b></p>
        <p>Tuổi: <b>{customerInfo.age}</b> Giới: <b>{customerInfo.gender}</b></p>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button variant="secondary" onClick={handleClose}>Đóng</Button>
        <Button variant="primary" onClick={handlePrint}>In Phiếu</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PrintSupplyUsageModal;
