import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReceipt } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { Button } from 'react-bootstrap';
import { jwtDecode } from 'jwt-decode';
import Loading from './Loading';

const MedicineReceiptList = () => {
    const [receipts, setReceipts] = useState([]);
    const [selectedReceipt, setSelectedReceipt] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    const token = localStorage.getItem('token');
    const decoded = jwtDecode(token);
    const userId = decoded.userId;

    useEffect(() => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const fetchReceipts = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${apiUrl}/medicineReceipts?createdBy=${userId}`);
                setReceipts(response.data);
            } catch (error) {
                console.error('Lỗi khi lấy danh sách phiếu nhập thuốc:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchReceipts();
    }, [userId]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const openModal = (receipt) => {
        setSelectedReceipt(receipt);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <div className="m-2">
            <h2><FontAwesomeIcon icon={faReceipt} /> Danh sách Phiếu Nhập Thuốc</h2>
            {loading ? (<Loading />) : (
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>STT</th>
                            <th>Mã Phiếu</th>
                            <th>Ngày Nhập</th>
                            <th>Tổng Giá Trị</th>
                            <th>Ghi Chú</th>
                        </tr>
                    </thead>
                    <tbody>
                        {receipts.map((receipt, index) => (
                            <tr key={receipt._id} onClick={() => openModal(receipt)} style={{ cursor: 'pointer' }}>
                                <td>{index + 1}</td>
                                <td>{receipt.receiptCode.slice(0, 11)}</td>
                                <td>{formatDate(receipt.createdAt)}</td>
                                <td>{receipt.totalPrice.toLocaleString()} VND</td>
                                <td>{receipt.note}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            {/* Modal */}
            <Modal
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    },
                    content: {
                        position: 'relative',
                        inset: 'unset',
                        width: '1080px',
                        maxWidth: '90%',
                        padding: '20px',
                        borderRadius: '8px',
                    },
                }}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Chi tiết phiếu nhập"
                ariaHideApp={false}
            >
                <h2><FontAwesomeIcon icon={faReceipt} /> Chi tiết Phiếu Nhập</h2>
                {selectedReceipt && (
                    <div>
                        <p><strong>Mã Phiếu:</strong> <b>{selectedReceipt.receiptCode.slice(0, 11)}</b></p>
                        <p><strong>Ngày Nhập:</strong> {formatDate(selectedReceipt.createdAt)}</p>
                        <p><strong>Tổng Giá Trị:</strong> <b>{selectedReceipt.totalPrice.toLocaleString()}</b> VND</p>
                        <p><strong>Ghi Chú:</strong> {selectedReceipt.note}</p>
                        <h4>Danh Sách Thuốc</h4>
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>STT</th>
                                    <th>Tên Thuốc</th>
                                    <th>Số Lượng</th>
                                    <th>Giá Nhập</th>
                                    <th>Hạn Dùng</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedReceipt.medicines.map((medicine, index) => (
                                    <tr key={medicine.medicineId._id}>
                                        <td>{index + 1}</td>
                                        <td>{medicine.medicineId.name || 'Chưa có tên'}</td>
                                        <td>{medicine.quantity}</td>
                                        <td>{medicine.price.toLocaleString()} VND</td>
                                        <td>{formatDate(medicine.expirationDate)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                <Button variant="secondary" onClick={closeModal}>Đóng</Button>
            </Modal>
        </div>
    );
};

export default MedicineReceiptList;
