import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faEdit, faTrash, faUser, faEnvelope, faPhone, faBriefcase } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal, Form, InputGroup, Card } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';

const Staff = () => {
    const [userId, setUserId] = useState(null);
    const [staff, setStaff] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editingIndex, setEditingIndex] = useState(null);
    const [employeeData, setEmployeeData] = useState({
        name: '',
        email: '',
        phone: '',
        position: '',
        profilePicture: ''
    });
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (token) {
            try {
                const decoded = jwtDecode(token);
                setUserId(decoded.userId);
            } catch (error) {
                console.error("Lỗi khi giải mã token:", error);
            }
        }
    }, [token]);

    useEffect(() => {
        const fetchStaff = async () => {
            try {
                const response = await axios.get(`${apiUrl}/user/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setStaff(response.data.user.staff || []);
            } catch (error) {
                console.error('Lỗi khi lấy danh sách nhân viên:', error);
                toast.error('Không thể tải danh sách nhân viên!');
            }
        };

        if (userId) fetchStaff();
    }, [userId, apiUrl, token]);

    const handleClose = () => {
        setShowModal(false);
        setEditingIndex(null);
        setEmployeeData({ name: '', email: '', phone: '', position: '', profilePicture: '' });
    };

    const handleShow = () => setShowModal(true);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEmployeeData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSaveEmployee = async () => {
        try {
            const updatedStaff = editingIndex !== null
                ? [...staff.slice(0, editingIndex), employeeData, ...staff.slice(editingIndex + 1)]
                : [...staff, employeeData];

            await axios.put(`${apiUrl}/user/${userId}/staff`, { staff: updatedStaff }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setStaff(updatedStaff);
            toast.success('Lưu thông tin thành công!');
            handleClose();
        } catch (error) {
            console.error('Error saving employee:', error);
            toast.error('Lỗi khi lưu thông tin!');
        }
    };

    const handleEditEmployee = (index) => {
        setEditingIndex(index);
        setEmployeeData(staff[index]);
        handleShow();
    };

    const handleDeleteEmployee = async (index) => {
        try {
            const updatedStaff = staff.filter((_, i) => i !== index);
            await axios.put(`${apiUrl}/user/${userId}/staff`, { staff: updatedStaff }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setStaff(updatedStaff);
            toast.success('Xóa nhân viên thành công!');
        } catch (error) {
            console.error('Error deleting employee:', error);
            toast.error('Lỗi khi xóa nhân viên!');
        }
    };

    const handleKeyDown = (e, nextField) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Ngăn việc submit form
            const nextInput = document.getElementById(nextField);
            if (nextInput) {
                nextInput.focus(); // Chuyển tiêu điểm tới trường kế tiếp
            }
        }
    };


    return (
        <div className='m-1'>
            <Button variant="primary" className="mb-3" onClick={handleShow}>
                <FontAwesomeIcon icon={faUserPlus} /> Thêm Nhân Viên
            </Button>
            <div className="row">
                {staff.map((employee, index) => (
                    <div className="col-md-6 col-sm-12 mb-4" key={index}>
                        <Card className="h-100">
                            <Card.Body>
                                <div className="d-flex">
                                    {/* Hình đại diện */}
                                    <div className="me-3">
                                        <img
                                            src={employee.profilePicture || 'https://via.placeholder.com/100'}
                                            alt={employee.name}
                                            style={{
                                                width: '100px',
                                                height: '100px',
                                                borderRadius: '50%',
                                                objectFit: 'cover'
                                            }}
                                        />
                                    </div>
                                    {/* Thông tin nhân viên */}
                                    <div>
                                        <Card.Title>{employee.name}</Card.Title>
                                        <Card.Text>
                                            <FontAwesomeIcon icon={faEnvelope} /> {employee.email || 'Không có email'}<br />
                                            <FontAwesomeIcon icon={faPhone} /> {employee.phone || 'Không có số điện thoại'}<br />
                                            <FontAwesomeIcon icon={faBriefcase} /> {employee.position || 'Không có chức vụ'}
                                        </Card.Text>
                                    </div>
                                </div>
                            </Card.Body>
                            <Card.Footer className="d-flex justify-content-between">
                                <Button variant="warning" size="sm" onClick={() => handleEditEmployee(index)}>
                                    <FontAwesomeIcon icon={faEdit} /> Sửa
                                </Button>
                                <Button variant="danger" size="sm" onClick={() => handleDeleteEmployee(index)}>
                                    <FontAwesomeIcon icon={faTrash} /> Xóa
                                </Button>
                            </Card.Footer>
                        </Card>
                    </div>
                ))}
            </div>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingIndex !== null ? 'Chỉnh Sửa Nhân Viên' : 'Thêm Nhân Viên'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon={faUser} /></InputGroup.Text>
                                <Form.Control
                                    id="name"
                                    type="text"
                                    placeholder="Nhập họ tên"
                                    name="name"
                                    value={employeeData.name}
                                    onChange={handleInputChange}
                                    autoFocus
                                    onKeyDown={(e) => handleKeyDown(e, 'email')} // Chuyển tới email khi nhấn Enter
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon={faEnvelope} /></InputGroup.Text>
                                <Form.Control
                                    id="email"
                                    type="email"
                                    placeholder="Nhập email"
                                    name="email"
                                    value={employeeData.email}
                                    onChange={handleInputChange}
                                    onKeyDown={(e) => handleKeyDown(e, 'phone')} // Chuyển tới phone khi nhấn Enter
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon={faPhone} /></InputGroup.Text>
                                <Form.Control
                                    id="phone"
                                    type="text"
                                    placeholder="Nhập số điện thoại"
                                    name="phone"
                                    value={employeeData.phone}
                                    onChange={handleInputChange}
                                    onKeyDown={(e) => handleKeyDown(e, 'position')} // Chuyển tới position khi nhấn Enter
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon={faBriefcase} /></InputGroup.Text>
                                <Form.Control
                                    id="position"
                                    type="text"
                                    placeholder="Nhập chức vụ"
                                    name="position"
                                    value={employeeData.position}
                                    onChange={handleInputChange}
                                    onKeyDown={(e) => handleKeyDown(e, 'save')} // Chuyển tới nút Lưu khi nhấn Enter
                                />
                            </InputGroup>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Đóng</Button>
                    <Button variant="primary" id="save" onClick={handleSaveEmployee}>
                        {editingIndex !== null ? 'Lưu Thay Đổi' : 'Thêm Nhân Viên'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Staff;
