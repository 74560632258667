// frontend/src/pages/Supplies.js
import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPencil, faInbox, faBoxOpen, faFileInvoice, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { Table, Button, FormControl } from 'react-bootstrap';
import AddSupply from '../components/AddSupply';
import EditSupply from '../components/EditSupply';
import Loading from '../components/Loading';
import CreateSupplyReceipt from '../components/CreateSupplyReceipt';
import { Link } from 'react-router-dom';

const Supplies = () => {
    const [supplies, setSupplies] = useState([]);
    const [receipts, setReceipts] = useState([]);
    const [search, setSearch] = useState("");
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentSupplyId, setCurrentSupplyId] = useState(null);
    const [showReceiptModal, setShowReceiptModal] = useState(false);
    const [loading, setLoading] = useState(true);

    const token = localStorage.getItem('token');
    const decoded = jwtDecode(token);
    const userId = decoded.userId;
    const apiUrl = process.env.REACT_APP_API_URL;

    const fetchSupplies = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/supplies?createdBy=${userId}`);
            setSupplies(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Lỗi khi tải Vật tư:', error);
            setLoading(false);
        }
    }, [apiUrl, userId]);

    const fetchReceipts = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/supplyReceipts?createdBy=${userId}`);
            const receiptsData = response.data;

            const expirationMap = {};

            receiptsData.forEach(receipt => {
                receipt.supplies.forEach(supply => {
                    const supplyId = supply.supplyId._id;

                    if (supply.expirationDate) {
                        if (!expirationMap[supplyId]) {
                            expirationMap[supplyId] = []; // Khởi tạo mảng nếu chưa có
                        }
                        expirationMap[supplyId].push(supply.expirationDate); // Thêm hạn dùng vào mảng
                    }
                });
            });

            setReceipts(expirationMap); // Lưu expirationMap vào state
            setLoading(false);
        } catch (error) {
            console.error('Lỗi khi lấy danh sách phiếu nhập vật tư:', error);
            setLoading(false);
        }
    }, [apiUrl, userId]);

    useEffect(() => {
        fetchSupplies();
        fetchReceipts();
    }, [fetchSupplies, fetchReceipts]);

    const reFreshSupplies = () => {
        fetchSupplies();
    }

    const reFreshReceipts = () => {
        fetchReceipts();
    }

    const handleShowAdd = () => setShowAddModal(true);
    const handleCloseAdd = () => setShowAddModal(false);

    const handleShowEdit = (supplyId) => {
        setCurrentSupplyId(supplyId);
        setShowEditModal(true);
    };

    const handleCloseEdit = () => {
        setCurrentSupplyId(null);
        setShowEditModal(false);
    };

    const filteredSupplies = supplies.filter(supply =>
        supply.name.toLowerCase().includes(search.toLowerCase()) ||
        supply.supplyCode.includes(search)
    );

    // Lấy 10 Vật tư mới nhất
    const displayedSupplies = filteredSupplies
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sắp xếp theo thứ tự mới nhất
        .slice(0, 9); // Lấy 10 Vật tư đầu tiên

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Tháng từ 01 đến 12
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    return (
        <div className='m-2'>
            <h2><FontAwesomeIcon icon={faBoxOpen} /> Danh Sách Vật Tư</h2>
            <div className='d-flex mb-3'>
                <Button variant='primary' onClick={handleShowAdd} className='m-1' title='Thêm Vật tư mới'>
                    <FontAwesomeIcon icon={faPlus} /> Thêm Vật Tư
                </Button>
                <Button variant="primary" title='Nhập Vật tư/Sản phẩm' onClick={() => setShowReceiptModal(true)} className="m-1">
                    <FontAwesomeIcon icon={faInbox} /> Nhập Vật Tư
                </Button>
                <Button variant="primary" title='Danh sách phiếu nhập Vật tư/Sản phẩm' className='m-1' as={Link} to='/supplyreceipts'>
                    <FontAwesomeIcon icon={faFileInvoice} /> Phiếu Nhập
                </Button>
                <Button variant="success" title='Sử dụng Vật tư/Sản phẩm' className='m-1' as={Link} to='/supplyusage'>
                    <FontAwesomeIcon icon={faUserAlt} /> Sử Dụng
                </Button>
                <FormControl
                    type='text'
                    placeholder='Tìm kiếm vật tư...'
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className='ms-3'
                    style={{ flexGrow: 1 }}
                />
            </div>
            {loading ? (<Loading />) : (
                <Table striped bordered hover>
                    <thead className="text-center">
                        <tr>
                            <th>STT</th>
                            <th>Mã Vật Tư</th>
                            <th>Tên VT</th>
                            <th>Giá VT (VND)</th>
                            <th>Đơn vị</th>
                            <th>Số lượng</th>
                            <th>Hạn Dùng</th>
                            <th>Ghi chú</th>
                            <th>Hành động</th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayedSupplies.map((supply, index) => (
                            <tr key={supply._id}>
                                <td>{index + 1}</td>
                                <td>{supply.supplyCode.slice(0, 6)}</td>
                                <td>{supply.name}</td>
                                <td>{supply.price.toLocaleString('vi-VN')}</td>
                                <td>{supply.unit}</td>
                                <td>{supply.quantity}</td>
                                <td>
                                    {receipts[supply._id] && receipts[supply._id].length > 0 ? ( // Sử dụng receipts thay cho expirationMap
                                        <div>
                                            {receipts[supply._id].map((date, index) => (
                                                <div key={index}>{formatDate(new Date(date).toLocaleDateString())}</div>
                                            ))}
                                        </div>
                                    ) : (
                                        "Không có hạn dùng"
                                    )}
                                </td>

                                <td>{supply.note}</td>
                                <td className="text-center">
                                    <Button size="sm" variant='warning' onClick={() => handleShowEdit(supply._id)} title='Sửa thông tin Vật Tư' className='m-1'>
                                        <FontAwesomeIcon icon={faPencil} /> Sửa
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
            <AddSupply onReFresh={reFreshSupplies} show={showAddModal} handleClose={handleCloseAdd} />
            <EditSupply onReFresh={reFreshSupplies} show={showEditModal} handleClose={handleCloseEdit} supplyId={currentSupplyId} />
            <CreateSupplyReceipt onReFresh={reFreshSupplies} show={showReceiptModal} handleClose={() => setShowReceiptModal(false)} />
        </div>
    );
};

export default Supplies;
