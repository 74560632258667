import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faHandHoldingMedical, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Table, Button, FormControl } from 'react-bootstrap';
import AddService from '../components/AddService';
import EditService from '../components/EditService';
import Loading from '../components/Loading';

const ServiceList = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [services, setServices] = useState([]);
    const [search, setSearch] = useState("");
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentServiceId, setCurrentServiceId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [visibleColumns, setVisibleColumns] = useState({
        index: true,
        serviceCode: true,
        serviceName: true,
        serviceGroup: true,
        servicePrice: true,
        serviceUnit: true,
        serviceNote: true,
        serviceAction: true,
    });

    const token = localStorage.getItem('token');
    const decoded = jwtDecode(token);
    const userId = decoded.userId;

    const fetchServices = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/services?createdBy=${userId}`);
            setServices(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching services:', error);
            setLoading(false);
        }
    }, [apiUrl, userId]);

    useEffect(() => {
        fetchServices();
    }, [fetchServices]);

    const reFreshServices = () => {
        fetchServices();
    }

    const handleShowAdd = () => setShowAddModal(true);
    const handleCloseAdd = () => setShowAddModal(false);

    const handleShowEdit = (serviceId) => {
        setCurrentServiceId(serviceId);
        setShowEditModal(true);
    };

    const handleCloseEdit = () => {
        setCurrentServiceId(null);
        setShowEditModal(false);
    };

    const toggleColumnVisibility = (column) => {
        setVisibleColumns((prevState) => ({
            ...prevState,
            [column]: !prevState[column],
        }));
    };

    const filteredServices = services.filter(service =>
        service.name.toLowerCase().includes(search.toLowerCase()) ||
        service.serviceCode.includes(search)
    );

    const displayedServices = filteredServices
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, 10);

    return (
        <div className='m-2'>
            <h2><FontAwesomeIcon icon={faHandHoldingMedical} /> Danh Sách Dịch Vụ</h2>
            <div className="d-flex mb-3">
                <Button variant="primary" onClick={handleShowAdd}>
                    Thêm Dịch Vụ <FontAwesomeIcon icon={faHandHoldingMedical} className="m-1" />
                </Button>
                <FormControl
                    type="text"
                    placeholder="Tìm kiếm dịch vụ..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="m-1"
                    style={{ flexGrow: 1 }}
                />
            </div>
            {loading ? (<Loading />) : (
                <Table striped bordered hover>
                    <thead className="text-center">
                        <tr>
                            <th>STT <FontAwesomeIcon icon={visibleColumns.index ? faEye : faEyeSlash}
                                onClick={() => toggleColumnVisibility('index')}
                                style={{ cursor: 'pointer', marginLeft: '5px' }}
                            /></th>
                            <th>Mã DV<FontAwesomeIcon icon={visibleColumns.serviceCode ? faEye : faEyeSlash}
                                onClick={() => toggleColumnVisibility('serviceCode')}
                                style={{ cursor: 'pointer', marginLeft: '5px' }}
                            /></th>
                            <th>Tên DV<FontAwesomeIcon icon={visibleColumns.serviceName ? faEye : faEyeSlash}
                                onClick={() => toggleColumnVisibility('serviceName')}
                                style={{ cursor: 'pointer', marginLeft: '5px' }}
                            /></th>
                            <th>Nhóm Dịch Vụ<FontAwesomeIcon icon={visibleColumns.serviceGroup ? faEye : faEyeSlash}
                                onClick={() => toggleColumnVisibility('serviceGroup')}
                                style={{ cursor: 'pointer', marginLeft: '5px' }}
                            /></th>
                            <th>Giá DV (VND) <FontAwesomeIcon icon={visibleColumns.servicePrice ? faEye : faEyeSlash}
                                onClick={() => toggleColumnVisibility('servicePrice')}
                                style={{ cursor: 'pointer', marginLeft: '5px' }}
                            /></th>
                            <th>Đơn vị<FontAwesomeIcon icon={visibleColumns.index ? faEye : faEyeSlash}
                                onClick={() => toggleColumnVisibility('serviceUnit')}
                                style={{ cursor: 'pointer', marginLeft: '5px' }}
                            /></th>
                            <th>Ghi chú<FontAwesomeIcon icon={visibleColumns.serviceNote ? faEye : faEyeSlash}
                                onClick={() => toggleColumnVisibility('serviceNote')}
                                style={{ cursor: 'pointer', marginLeft: '5px' }}
                            /></th>
                            <th>Hành Động<FontAwesomeIcon icon={visibleColumns.serviceAction ? faEye : faEyeSlash}
                                onClick={() => toggleColumnVisibility('serviceAction')}
                                style={{ cursor: 'pointer', marginLeft: '5px' }}
                            /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayedServices.map((service, index) => (
                            <tr key={service._id}>
                                {visibleColumns.index ? <td>{index + 1}</td> : <td>&nbsp;</td>}
                                {visibleColumns.serviceCode ? <td>{service.serviceCode.slice(0, 6)}</td> : <td>&nbsp;</td>}
                                {visibleColumns.serviceName ? <td>{service.name}</td> : <td>&nbsp;</td>}
                                {visibleColumns.serviceGroup ? <td>{service.serviceGroup || 'Ngoại khoa'}</td> : <td>&nbsp;</td>}
                                {visibleColumns.servicePrice ? <td>{service.price.toLocaleString('vi-VN')}</td> : <td>&nbsp;</td>}
                                {visibleColumns.serviceUnit ? <td>{service.unit}</td> : <td>&nbsp;</td>}
                                {visibleColumns.serviceNote ? <td>{service.note}</td> : <td>&nbsp;</td>}
                                {visibleColumns.serviceAction ? <td className="text-center">
                                    <Button size="sm" variant="warning" onClick={() => handleShowEdit(service._id)} title='Sửa thông tin Dịch Vụ' className='m-1'>
                                        <FontAwesomeIcon icon={faPencil} /> Sửa
                                    </Button>
                                </td> : <td>&nbsp;</td>}
                            </tr>
                        ))}
                    </tbody>
                </Table>)}

            <AddService onReFresh={reFreshServices} show={showAddModal} handleClose={handleCloseAdd} />
            <EditService onReFresh={reFreshServices} show={showEditModal} handleClose={handleCloseEdit} serviceId={currentServiceId} />
        </div>
    );
};

export default ServiceList;
