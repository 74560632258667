import React, { useEffect, useState, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

const PrintPrescriptionModal = ({ show, handleClose, prescription }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('token');

  let userId;
  if (token && typeof token === 'string') {
    try {
      const decoded = jwtDecode(token);
      userId = decoded.userId;
    } catch (error) {
      console.error('Token không hợp lệ:', error);
    }
  }

  const [companyInfo, setCompanyInfo] = useState({ name: '', nameCPN: '', logo: '', address: '', phone: '' });
  const [customerInfo, setCustomerInfo] = useState({
    name: '',
    age: '',
    gender: '',
    address: '',
    phone: '',
  });
  const [detailedPrescriptions, setDetailedPrescriptions] = useState([]);

  const fetchUser = useCallback(async () => {
    if (userId) {
      try {
        const response = await axios.get(`${apiUrl}/user/${userId}`);
        setCompanyInfo({
          name: response.data.user.name,
          nameCPN: response.data.user.companyName,
          logo: response.data.user.logo,
          address: response.data.user.companyAddress,
          phone: response.data.user.companyPhone,
        });
      } catch (error) {
        console.error('Lỗi khi lấy thông tin người dùng:', error);
      }
    }
  }, [apiUrl, userId]);

  useEffect(() => {
    if (prescription) {
      const fetchCustomerInfo = async () => {
        try {
          const response = await axios.get(`${apiUrl}/customers/${prescription.customerId._id}`);
          const { name, birthday, gender, address, phone } = response.data;
          const age = new Date().getFullYear() - new Date(birthday).getFullYear();
          setCustomerInfo({ name, age, gender, address, phone });
        } catch (error) {
          console.error('Lỗi khi lấy thông tin khách hàng:', error);
        }
      };

      const fetchMedicinesDetails = async () => {
        try {
          const medicineDetails = await Promise.all(
            prescription.prescriptions.map(async (med) => {
              const response = await axios.get(`${apiUrl}/medicines/${med.medicineId._id}`);
              return {
                ...med,
                name: response.data.name,
                unit: response.data.unit,
              };
            })
          );
          setDetailedPrescriptions(medicineDetails);
        } catch (error) {
          console.error('Lỗi khi lấy chi tiết thuốc:', error);
        }
      };

      fetchCustomerInfo();
      fetchMedicinesDetails();
    }
    fetchUser();
  }, [prescription, fetchUser]);

  let day, month, year;

  if (prescription?.createdAt) {
    const createdAt = new Date(prescription.createdAt);
    day = createdAt.getDate();
    month = createdAt.getMonth() + 1; // getMonth() trả về giá trị từ 0-11, cần +1 để đúng tháng.
    year = createdAt.getFullYear();
  } else {
    day = month = year = 'N/A'; // Giá trị mặc định nếu không có dữ liệu
  }

  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
<head>
  <title>In Toa Thuốc</title>
  <style>
    @media print {
      @page { size: A5; margin: 5mm; }
    }
    body {
      font-family: Arial, sans-serif;
      font-size: 14px;
      margin: 0;
      padding: 0;
      width: 100%;
    }
    table {
      width: 100%;
      font-size: 14px;
      border-collapse: collapse;
    }
    .prescription {
      padding: 5px;
      width: 100%;
    }
    .header {
      text-align: center;
      margin-bottom: 5px;
    }
    .content {
      margin: 5px 0;
    }
    .logo {
      max-width: 50px;
    }
  </style>
</head>
<body>
<div class="prescription-container" style="display: flex; flex-direction: column; min-height: 100vh;">
  <div class="prescription-content" style="flex-grow: 1;">
    <!-- Nội dung toa thuốc chính -->
    <div class="prescription">
      <table style="width:100%">
        <tr>
          <td rowspan="4" style="width: 100px">
            ${companyInfo.logo ? `<img src="${companyInfo.logo}" class="logo" alt="Logo"/>` : ''}
          </td>
          <td>${companyInfo.nameCPN}</td>
          <td style="text-align: right; margin-right: 20px;"></td>
        </tr>
        <tr><td>Địa chỉ: ${companyInfo.address}</td><td></td></tr>
        <tr><td>Điện thoại: ${companyInfo.phone}</td><td></td></tr>
      </table>
      <center>
        <h1>TOA THUỐC</h1>
      </center>
      <p><b>Họ Tên: ${customerInfo.name.toUpperCase()}</b> - <b>Tuổi:</b> ${customerInfo.age} <b>Giới:</b> ${customerInfo.gender}</p>
      <p><b>Địa chỉ:</b> ${customerInfo.address} - <b>Số ĐT:</b> ${customerInfo.phone}</p>
      <p><b>Chẩn Đoán:</b> ${prescription.diagnosis}</p>

      ${detailedPrescriptions
        .map(
          (med, index) => `
          <p style="display: flex; justify-content: space-between; width: 100%;">
            <b style="margin-left: 20px;">${index + 1}. ${med.name}</b>
            <span style="flex-grow: 1; text-align: right; margin-right: 20px;">${med.days * med.timesPerDay * med.dosePerTime} ${med.unit}</span>
          </p>
          <p style="width: 100%;">
            <span style="margin-left: 30px;">Liều dùng: ${med.route} ${med.dosePerTime} ${med.unit} x ${med.timesPerDay} lần/ngày</span>
            <br />
          </p>`
        )
        .join('')}
    </div>
  </div>

  <!-- Phần cuối trang với 2 cột -->
  <div class="doctor-notes" style="display: flex; justify-content: space-between; width: 100%; padding-top: 0px;">
    <div style="flex: 1; padding-left: 20px;">
      <b>Lời dặn của bác sĩ:</b>
      <p>
        ${prescription.doctorAdvice || "- Sử dụng theo hướng dẫn của bác sĩ.<br/>- Ngưng thuốc và liên hệ ngay với bác sĩ khi có biểu hiện bất thường."}
      </p>
    </div>
    <div style="flex: 1; text-align: center;">
      <p style="margin: 0; padding-top: 0px;"><i>Ngày ${day} Tháng ${month} Năm ${year}</i><br/>
      <b>Bác Sĩ Điều Trị</b></p>
      <p style="margin-top: 40px;"><b>${companyInfo.name}</b></p>
    </div>
  </div>
</div>

</body>
</html>`);
    printWindow.document.close();
    handleClose();
    setTimeout(() => {
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    }, 500);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title><FontAwesomeIcon icon={faPrint} /> Xem Toa Thuốc</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Khách Hàng: <b>{customerInfo.name}</b></p>
        <p>Tuổi: <b>{customerInfo.age}</b> Giới: <b>{customerInfo.gender}</b></p>
        <p>Chẩn Đoán: <b>{prescription?.diagnosis}</b></p>
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-center'>
        <Button variant="secondary" onClick={handleClose}>Đóng</Button>
        <Button variant="primary" onClick={handlePrint}>Xem Toa Thuốc</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PrintPrescriptionModal;
