import React, { useState, useEffect, useCallback } from 'react';
import { Button, Card, Col, Row, Form } from 'react-bootstrap';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import AddImageModal from '../components/AddImageModal';
import ViewImageModal from '../components/ViewImageModal';
import EditImageModal from '../components/EditImageModal';
import ModalConfirm from '../components/ModalConfirm';
import Loading from '../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages, faPhotoFilm, faTrashAlt, faEdit, faLightbulb } from '@fortawesome/free-solid-svg-icons';

const ImageGallery = () => {
    const [albums, setAlbums] = useState([]);
    const [filteredAlbums, setFilteredAlbums] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showAddModal, setShowAddModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedAlbum, setSelectedAlbum] = useState(null);
    const [loading, setLoading] = useState(true);
    const apiUrl = process.env.REACT_APP_API_URL;

    const token = localStorage.getItem('token');
    let userId;
    if (token && typeof token === 'string') {
        try {
            const decoded = jwtDecode(token);
            userId = decoded.userId;
        } catch (error) {
            console.error('Token không hợp lệ:', error);
        }
    }

    const fetchAlbums = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/albums?createdBy=${userId}`);
            setAlbums(response.data);
            setFilteredAlbums(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Lỗi khi lấy album:", error);
            toast.error('Lỗi khi lấy album!');
            setLoading(false);
        }
    }, [userId, apiUrl]);

    useEffect(() => {
        fetchAlbums();
    }, [fetchAlbums]);

    const reFreshFetchAlbums = () => {
        fetchAlbums();
    };

    const handleShowViewModal = (album) => {
        setSelectedAlbum(album);
        setShowViewModal(true);
    };

    const handleCloseViewModal = () => setShowViewModal(false);

    const handleShowAddModal = () => setShowAddModal(true);
    const handleCloseAddModal = () => setShowAddModal(false);

    const handleShowEditModal = (album) => {
        setSelectedAlbum(album);
        setShowEditModal(true);
    };

    const handleCloseEditModal = () => setShowEditModal(false);

    const handleDeleteAlbum = (album) => {
        setSelectedAlbum(album);
        setShowConfirmModal(true);
    };

    const handleConfirmDelete = async () => {
        try {
            await axios.delete(`${apiUrl}/albums/${selectedAlbum._id}`);
            setAlbums(albums.filter((album) => album._id !== selectedAlbum._id));
            setFilteredAlbums(filteredAlbums.filter((album) => album._id !== selectedAlbum._id));
            toast.success('Album đã được xóa!');
        } catch (error) {
            console.error("Lỗi khi xóa album:", error);
            toast.error('Lỗi khi xóa album!');
        } finally {
            setShowConfirmModal(false);
        }
    };

    const handleCloseConfirmModal = () => setShowConfirmModal(false);

    const handleSearchChange = (event) => {
        const term = event.target.value.toLowerCase();
        setSearchTerm(term);

        const filtered = albums.filter(album =>
            album.name.toLowerCase().includes(term) ||
            album.images.some(image => image.name.toLowerCase().includes(term))
        );

        setFilteredAlbums(filtered);
    };

    return (
        <div className='m-2' style={{ maxWidth: '100%', overflowX: 'hidden' }}>
            <h2><FontAwesomeIcon icon={faPhotoFilm} /> Quản Lý Hình Ảnh</h2>
            <div className="d-flex mb-3">
                <Button variant="primary" onClick={handleShowAddModal} className="m-1">
                    <FontAwesomeIcon icon={faImages} />&nbsp;Thêm Album
                </Button>
                <Form.Control
                    type="text"
                    placeholder="Tìm kiếm theo tên hoặc mô tả hình ảnh..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </div>
            <div className='text-muted m-1'><FontAwesomeIcon className="text-warning" icon={faLightbulb} />&nbsp;<small><i>Cắt chỉnh hình ảnh trước khi tải lên để việc lưu trữ và quản lý được tốt nhất.</i></small></div>
            {loading ? (<Loading />) : (
                <Row>
                    {filteredAlbums.map((album) => (
                        <Col md={3} key={uuidv4()} className="mb-4">
                            <Card style={{ cursor: 'pointer', position: 'relative' }} onClick={() => handleShowViewModal(album)}>
                                <div
                                    style={{
                                        backgroundImage: `url(data:${album.images[0]?.contentType};base64,${album.images[0]?.data || ''})`,
                                        height: '250px', // Chiều cao của hình nền
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        borderRadius: '8px'

                                    }}
                                    onContextMenu={(e) => e.preventDefault()}
                                />
                                <Card.Body>
                                    <Card.Title><h6>{album.name}</h6></Card.Title>
                                    <Card.Text>
                                        <u>Mô tả:</u> {album.notes}<br />
                                        <u>Ngày tạo:</u> {new Date(album.createdAt).toLocaleDateString('vi-VN')}
                                    </Card.Text>
                                </Card.Body>
                                <div className="card-actions">
                                    <FontAwesomeIcon
                                        icon={faEdit}
                                        className="action-icon edit-icon m-2"
                                        onClick={(e) => { e.stopPropagation(); handleShowEditModal(album); }}
                                        title='Sửa Album'
                                    />
                                    <FontAwesomeIcon
                                        icon={faTrashAlt}
                                        className="action-icon delete-icon m-2"
                                        onClick={(e) => { e.stopPropagation(); handleDeleteAlbum(album); }}
                                        title='Xóa Album'
                                    />
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
            )}
            <AddImageModal show={showAddModal} handleClose={handleCloseAddModal} onReFresh={reFreshFetchAlbums} />
            <ViewImageModal show={showViewModal} handleClose={handleCloseViewModal} album={selectedAlbum} />
            <EditImageModal
                show={showEditModal}
                handleClose={handleCloseEditModal}
                album={selectedAlbum}
                onReFresh={reFreshFetchAlbums}
            />
            <ModalConfirm show={showConfirmModal} handleClose={handleCloseConfirmModal} handleConfirm={handleConfirmDelete} />
        </div>
    );
};

export default ImageGallery;
