import React, { useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

const CopyText = ({ textToCopy, buttonLabel, successMessage }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        if (!textToCopy) {
            toast.warn("Không có nội dung để sao chép!");
            return;
        }
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                setCopied(true);
                toast.success(successMessage || "Đã sao chép!");
                setCopied(false);
            })
            .catch((error) => {
                console.error("Lỗi khi sao chép:", error);
                toast.error("Không thể sao chép nội dung!");
            });
    };

    return (
        <div>
            <a onClick={handleCopy} disabled={copied}>
                {copied ? "Đã sao chép!" : buttonLabel || "Sao chép"}
            </a>
        </div>
    );
};

// Xác định kiểu dữ liệu và giá trị mặc định cho props
CopyText.propTypes = {
    textToCopy: PropTypes.string.isRequired, // Văn bản cần sao chép
    buttonLabel: PropTypes.string, // Nhãn của nút
    successMessage: PropTypes.string, // Tin nhắn khi sao chép thành công
};

CopyText.defaultProps = {
    buttonLabel: "Sao chép",
    successMessage: "Đã sao chép thành công!",
};

export default CopyText;
