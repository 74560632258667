import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';
import { Badge, Button, FormControl, Table } from 'react-bootstrap';
import AddReceiptModal from '../components/AddReceiptModal';
import ModalConfirm from '../components/ModalConfirm';
import PrintReceiptModal from '../components/PrintReceiptModal';
import PayDebtModal from '../components/PayDebtModal';
import Loading from '../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faPrint, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import formatDate from '../components/FormatDate';
import PageWrapper from '../components/PageWrapper';

const Receipts = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');
    let userId;
    if (token && typeof token === 'string') {
        try {
            const decoded = jwtDecode(token);
            userId = decoded.userId;
        } catch (error) {
            console.error('Token không hợp lệ:', error);
        }
    } else {
        console.warn('Token không hợp lệ hoặc không tồn tại.');
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const updateIsMobile = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', updateIsMobile);
        return () => window.removeEventListener('resize', updateIsMobile);
    }, []);

    const [receipts, setReceipts] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [showPayDebtModal, setShowPayDebtModal] = useState(false);
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [selectedReceipt, setSelectedReceipt] = useState(null);

    // Các state cho form thêm phiếu thu
    const [search, setSearch] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [address, setAddress] = useState('');
    const [services, setServices] = useState([]);
    const [amount, setAmount] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [remainingAmount, setRemainingAmount] = useState(0);
    const [promoteAmount, setPromoteAmount] = useState(0);
    const [amountInWords, setAmountInWords] = useState('');
    const [notes, setNotes] = useState('');
    const [loading, setLoading] = useState(true);
    const [totalReceiptsForYear, setTotalReceiptsForYear] = useState(0);

    const fetchReceipts = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/receipts?createdBy=${userId}`);
            if (response.data.success) {
                const receiptsWithDetails = await Promise.all(
                    response.data.receipts.map(async (receipt) => {
                        const customerId = receipt.customerId?._id || receipt.customerId?.$oid || receipt.customerId;

                        // Lấy thông tin khách hàng
                        let customerName = 'Không có tên';
                        let customerBirthday = null;
                        let customerGender = 'Không xác định';
                        if (customerId) {
                            try {
                                const customerResponse = await axios.get(`${apiUrl}/customers/${customerId}`);
                                customerName = customerResponse.data.name || 'Không có tên';
                                customerBirthday = customerResponse.data.birthday;
                                customerGender = customerResponse.data.gender || 'Không xác định';
                            } catch (error) {
                                console.error('Lỗi khi lấy thông tin khách hàng:', error);
                            }
                        }

                        return {
                            ...receipt,
                            customerName,
                            customerBirthday,
                            customerGender,
                        };
                    })
                );

                setReceipts(receiptsWithDetails.sort((a, b) => new Date(b.date) - new Date(a.date)));
            } else {
                toast.error('Lỗi khi lấy danh sách phiếu thu!');
                setReceipts([]);
            }
        } catch (error) {
            toast.error('Lỗi khi lấy danh sách phiếu thu!');
            console.error('Lỗi khi lấy danh sách phiếu thu:', error);
            setReceipts([]);
        } finally {
            setLoading(false);
        }
    }, [apiUrl, userId]);

    useEffect(() => {
        fetchReceipts();
    }, [fetchReceipts]);

    const handleCreateReceipt = async () => {
        try {
            setLoading(true);
            const response = await axios.post(`${apiUrl}/receipts?createdBy=${userId}`, {
                customerName,
                address,
                services,
                amount,
                totalPrice,
                remainingAmount,
                promoteAmount,
                amountInWords,
                notes
            });
            if (response.data.success) {
                toast.success('Tạo phiếu thu thành công!');
                setShowAddModal(false);
                fetchReceipts();
                resetForm();
                setLoading(false);
            }
        } catch (error) {
            toast.error('Lỗi khi tạo phiếu thu!');
            console.error('Lỗi khi tạo phiếu thu:', error);
            setLoading(false);
        }
    };

    const resetForm = () => {
        setCustomerName('');
        setAddress('');
        setServices([]);
        setAmount(0);
        setTotalPrice(0);
        setRemainingAmount(0);
        setPromoteAmount(0);
        setAmountInWords('');
        setNotes('');
    };

    const filteredReceipts = receipts.filter(receipt =>
        receipt.customerName.toLowerCase().includes(search.toLowerCase()) ||
        receipt.receiptCode.includes(search)
    );

    const displayedReceipts = filteredReceipts.slice(0, 10); // Giới hạn số

    const handleDeleteReceipt = (id) => {
        setSelectedReceipt(id);
        setShowModalConfirm(true);
    };

    const handleConfirmDelete = async () => {
        if (selectedReceipt) {
            try {
                await axios.delete(`${apiUrl}/receipts/${selectedReceipt}`);
                toast.success('Xóa phiếu thu thành công!');
                fetchReceipts();
            } catch (error) {
                console.error('Lỗi khi xóa phiếu thu:', error);
            }
        }
        setShowModalConfirm(false);
    };

    const fetchTotalReceiptsYears = useCallback(async () => {
        try {
            const response = await axios.get(`${apiUrl}/receipts?createdBy=${userId}`);
            const currentYear = new Date().getFullYear();
            const totalReceipts = response.data.receipts.filter(receipt => {
                const receiptYear = new Date(receipt.date).getFullYear();
                return receiptYear === currentYear;
            });
            setTotalReceiptsForYear(totalReceipts.length);
        } catch (error) {
            console.error('Error fetching total receipts:', error);
            setTotalReceiptsForYear(0);
        }
    }, [apiUrl, userId]);

    useEffect(() => {
        fetchTotalReceiptsYears();
    }, [fetchTotalReceiptsYears]);


    const handlePrintReceipt = (receipt) => {
        const updatedReceipt = {
            ...receipt,
            receiptCode: receipt.receiptCode.slice(0, 10),
            printDate: new Date(receipt.date)
        };

        const day = updatedReceipt.printDate.getDate();
        const month = updatedReceipt.printDate.getMonth() + 1;
        const year = updatedReceipt.printDate.getFullYear();

        updatedReceipt.printDay = day;
        updatedReceipt.printMonth = month;
        updatedReceipt.printYear = year;
        updatedReceipt.totalReceiptsForYear = totalReceiptsForYear;

        setSelectedReceipt(updatedReceipt);
        setShowPrintModal(true);
    };

    const handleShowAdd = () => setShowAddModal(true);

    return (
        <div className="m-2">
            <PageWrapper
                pageTitle="Phiếu Thu"
                pageIcon={<FontAwesomeIcon icon={faFileInvoiceDollar} />}>
                <div className="d-flex mb-3">
                    <Button
                        variant="primary"
                        onClick={handleShowAdd}
                        className="add-customer-btn"
                        title="Thêm Khách Hàng"
                    >
                        <FontAwesomeIcon icon={faPlus} size="lg" />
                    </Button>
                    <FormControl
                        type="text"
                        placeholder="Tìm kiếm phiếu thu..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className="m-1"
                        style={{ flexGrow: 1 }}
                    />
                </div>
                {loading ? (
                    <Loading />
                ) : isMobile ? (
                    <div className="row">
                        {filteredReceipts.map((receipt, index) => (
                            <div className="col-md-6 col-sm-12 mb-4" key={receipt._id}>
                                <div className="card h-100">
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            <Badge bg="secondary">{index + 1}</Badge> {receipt.customerName} - {receipt.customerBirthday ? new Date(receipt.customerBirthday).getFullYear() : 'N/A'}
                                        </h5>
                                        <div className="d-flex">
                                            <div className="me-3 d-flex justify-content-center align-items-center">
                                                <img
                                                    src={
                                                        receipt.customerGender === 'Nam'
                                                            ? 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEivYejoR2LHSmj1k4rPX5GAzkudAMk1bry1vGcUIMKT6KGqVdZHCXei00LlHmy9sVd-KgyBGoBnjUMEadamNiYPCw9ujoBgsZ7d42o2ICLNeiFb_-EZVJ4JJXj9VaIpNDew1kj8OTQzjp682jz71pNprN_YaK5nFQxZ5CKDVsdpkObFahGt-KLNsIIU0vP1/s16000/noProfile.png'
                                                            : 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEj4zZog9334BSk5TeAduz4vggNF4RH0O7Frj0Rz46ze56CcfU3GMVrAB1Ly5Dz79AN2tbHhlFnfZ8ZvWqWJhj7TAW4vmEySIb-BKG8_Ez0PiQe6taDRTP8P4wrVOQZmjLQix8t1DIec6vA0_11jTM-xB4DER2C2lzHFO54Zqt9cpUfDOMQkN1TquUTCNFN9/s200/default-avatar-female.jpg'
                                                    }
                                                    alt={receipt.customerName}
                                                    style={{
                                                        width: '100px',
                                                        height: '100px',
                                                        borderRadius: '50%',
                                                        objectFit: 'cover',
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <p className="card-text mb-1" style={{ fontSize: '0.9em' }}>
                                                    <b>Số Phiếu Thu:</b> {receipt.receiptCode.slice(0, 10)}<br />
                                                    <b>Địa Chỉ:</b> {receipt.address || "Không có địa chỉ"}<br />
                                                    <b>Nội Dung:</b> {receipt.services || "Không có dịch vụ"}<br />
                                                    <b>Tổng Tiền:</b> <span className='text-success'><strong>{receipt.totalPrice.toLocaleString('vi-VN')}</strong></span>đ<br />
                                                    <b>Đã Đóng:</b> <span className='text-dark'><strong>{receipt.amount.toLocaleString('vi-VN')}</strong></span>đ<br />
                                                    <b>Còn Nợ:</b> <span className={receipt.remainingAmount > 0 ? 'text-danger' : 'text-success'}><strong>{receipt.remainingAmount.toLocaleString('vi-VN')}</strong></span>đ<br />
                                                    <b>Ngày Lập:</b> {formatDate(receipt.date)}<br />
                                                    <b>Ghi Chú:</b> {receipt.notes || "Không có ghi chú"}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer d-flex justify-content-between">
                                        <Button
                                            variant="info"
                                            size="sm"
                                            onClick={() => handlePrintReceipt(receipt)}
                                        >
                                            <FontAwesomeIcon icon={faPrint} /> In
                                        </Button>
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() => handleDeleteReceipt(receipt._id)}
                                            disabled={receipt.remainingAmount > 0}
                                        >
                                            <FontAwesomeIcon icon={faTrash} /> Xóa
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <Table striped bordered hover>
                        <thead className="text-center">
                            <tr>
                                <th>STT</th>
                                <th>Số Phiếu Thu</th>
                                <th>Tên Khách Hàng</th>
                                <th>Địa Chỉ</th>
                                <th>Nội Dung</th>
                                <th>Tổng Tiền</th>
                                <th>Đã Nộp</th>
                                <th>Khuyến Mại</th>
                                <th>Còn Nợ</th>
                                <th>Ngày Lập</th>
                                <th>Ghi Chú</th>
                                <th>Hành Động</th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayedReceipts.map((receipt, index) => (
                                <tr key={receipt._id}>
                                    <td>{index + 1}</td>
                                    <td>{receipt.receiptCode.slice(0, 10)}</td>
                                    <td style={{ color: receipt.remainingAmount > 0 ? 'red' : 'black' }}>
                                        <b>{receipt.customerName}</b>
                                    </td>
                                    <td>{receipt.address}</td>
                                    <td>{receipt.services}</td>
                                    <td>{receipt.totalPrice.toLocaleString('vi-VN')}</td>
                                    <td>{receipt.amount.toLocaleString('vi-VN')}</td>
                                    <td>{receipt.promoteAmount.toLocaleString('vi-VN')}</td>
                                    <td>{receipt.remainingAmount.toLocaleString('vi-VN')}</td>
                                    <td>{formatDate(receipt.date)}</td>
                                    <td>{receipt.notes || 'Không có ghi chú'}</td>
                                    <td className="text-center">
                                        <Button variant="info" size="sm" onClick={() => handlePrintReceipt(receipt)} className='m-1'>
                                            <FontAwesomeIcon icon={faPrint} /> in phiếu thu
                                        </Button>
                                        <Button variant="danger" size="sm" onClick={() => handleDeleteReceipt(receipt._id)} className='m-1' disabled={receipt.remainingAmount}>
                                            <FontAwesomeIcon icon={faTrash} /> xóa
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}

                <AddReceiptModal
                    onReFresh={fetchReceipts}
                    show={showAddModal}
                    handleClose={() => setShowAddModal(false)}
                    handleCreateReceipt={handleCreateReceipt}
                    customerName={customerName}
                    setCustomerName={setCustomerName}
                    address={address}
                    setAddress={setAddress}
                    services={services}
                    setServices={setServices}
                    amount={amount}
                    setAmount={setAmount}
                    totalPrice={totalPrice}
                    setTotalPrice={setTotalPrice}
                    promoteAmount={promoteAmount}
                    setPromoteAmount={setPromoteAmount}
                    remainingAmount={remainingAmount}
                    setRemainingAmount={setRemainingAmount}
                    amountInWords={amountInWords}
                    setAmountInWords={setAmountInWords}
                    notes={notes}
                    setNotes={setNotes}
                />

                <PrintReceiptModal
                    show={showPrintModal}
                    handleClose={() => setShowPrintModal(false)}
                    receipt={selectedReceipt || {}}
                />

                <PayDebtModal
                    show={showPayDebtModal}
                    handleClose={() => setShowPayDebtModal(false)}
                    receipt={selectedReceipt}
                />
                <ModalConfirm
                    show={showModalConfirm}
                    handleClose={() => setShowModalConfirm(false)}
                    handleConfirm={handleConfirmDelete}
                />
            </PageWrapper>
        </div>
    );
};

export default Receipts;
