import React, { useState, useRef, useEffect } from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import axios from "axios";
import { jwtDecode } from 'jwt-decode';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faBalanceScale, faListNumeric, faStickyNote, faPills, faUserDoctor } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

const AddMedicine = ({ show, handleClose }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [medicineData, setMedicineData] = useState({
        name: '',
        price: '',
        unit: '',
        quantity: '',
        usemed: '',
        note: '',
        createdBy: ''
    });

    const inputRefs = useRef([]);

    useEffect(() => {
        if (show) {
            inputRefs.current[0]?.focus();
        }
    }, [show]);

    const handleKeyDown = (e, index) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const nextIndex = index + 1;
            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex]?.focus();
            } else {
                document.getElementById('submitButton')?.focus();
            }
        }
    };

    const resetForm = () => {
        setMedicineData({
            name: '',
            price: '',
            unit: '',
            quantity: '',
            usemed: '',
            note: '',
            createdBy: ''
        });
    };

    const handleSave = () => {
        const token = localStorage.getItem('token');
        const decoded = jwtDecode(token);
        const userId = decoded.userId;
        axios.post(`${apiUrl}/medicines`, {
            ...medicineData,
            createdBy: userId,
        },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(() => {
                toast.success('Thêm thuốc thành công!');
                resetForm();
                handleClose();
                window.location.reload();
            })
            .catch(error => {
                console.error('Error adding medicine:', error);
            });
    };

    const handleModalClose = () => {
        resetForm();
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleModalClose}>
            <Modal.Header closeButton>
                <Modal.Title><FontAwesomeIcon icon={faPills} /> Thêm Thuốc</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className='m-1' controlId="formName">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faPills} /></InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Tên thuốc"
                                value={medicineData.name}
                                onChange={(e) => setMedicineData({ ...medicineData, name: e.target.value })}
                                ref={el => inputRefs.current[0] = el}
                                onKeyDown={(e) => handleKeyDown(e, 0)}
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className='m-1' controlId="formPrice">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faDollarSign} /></InputGroup.Text>
                            <Form.Control
                                type="number"
                                placeholder="Giá thuốc"
                                value={medicineData.price}
                                onChange={(e) => setMedicineData({ ...medicineData, price: e.target.value })}
                                ref={el => inputRefs.current[1] = el}
                                onKeyDown={(e) => handleKeyDown(e, 1)}
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className='m-1' controlId="formUnit">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faBalanceScale} /></InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Đơn vị"
                                value={medicineData.unit}
                                onChange={(e) => setMedicineData({ ...medicineData, unit: e.target.value })}
                                ref={el => inputRefs.current[2] = el}
                                onKeyDown={(e) => handleKeyDown(e, 2)}
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className='m-1' controlId="formQuantity">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faListNumeric} /></InputGroup.Text>
                            <Form.Control
                                type="number"
                                placeholder="Số lượng"
                                value={medicineData.quantity}
                                onChange={(e) => setMedicineData({ ...medicineData, quantity: e.target.value })}
                                ref={el => inputRefs.current[3] = el}
                                onKeyDown={(e) => handleKeyDown(e, 3)}
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className='m-1' controlId="formUsemed">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faUserDoctor} /></InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Đường dùng"
                                value={medicineData.usemed}
                                onChange={(e) => setMedicineData({ ...medicineData, usemed: e.target.value })}
                                ref={el => inputRefs.current[4] = el}
                                onKeyDown={(e) => handleKeyDown(e, 4)}
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className='m-1' controlId="formNote">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faStickyNote} /></InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Ghi chú"
                                value={medicineData.note}
                                onChange={(e) => setMedicineData({ ...medicineData, note: e.target.value })}
                                ref={el => inputRefs.current[5] = el}
                                onKeyDown={(e) => handleKeyDown(e, 5)}
                            />
                        </InputGroup>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <Button variant="secondary" onClick={handleModalClose}>
                    Đóng
                </Button>
                <Button id="submitButton" variant="primary" onClick={handleSave}>
                    Lưu
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddMedicine;
