import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faBalanceScale, faStickyNote, faPen, faList, faHandHoldingMedical } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

const EditService = ({ show, handleClose, serviceId, onReFresh }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [serviceData, setServiceData] = useState({
        name: '',
        price: '',
        unit: '',
        note: '',
        serviceGroup: 'Ngoại khoa' // Thêm trường Nhóm Dịch Vụ mặc định
    });

    useEffect(() => {
        if (serviceId) {
            axios.get(`${apiUrl}/services/${serviceId}`)
                .then(response => {
                    setServiceData(response.data);
                })
                .catch(error => {
                    console.error("Error fetching service data:", error);
                });
        }
    }, [serviceId, apiUrl, setServiceData]);

    const handleSave = () => {
        axios.put(`${apiUrl}/services/${serviceId}`, serviceData)
            .then(() => {
                toast.success('Sửa dịch vụ thành công!');
                handleClose();
                onReFresh();
            })
            .catch(error => {
                console.error('Error updating service:', error);
            });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title><FontAwesomeIcon icon={faPen} /> Sửa Dịch Vụ</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formName">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faHandHoldingMedical} />Tên dịch vụ</InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="nhập tên dịch vụ"
                                value={serviceData.name}
                                onChange={(e) => setServiceData({ ...serviceData, name: e.target.value })}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group controlId="formPrice">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faDollarSign} />Giá (VND)</InputGroup.Text>
                            <Form.Control
                                type="number"
                                placeholder="Giá dịch vụ"
                                value={serviceData.price}
                                onChange={(e) => setServiceData({ ...serviceData, price: e.target.value })}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group controlId="formUnit">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faBalanceScale} />Đơn vị (Lần/Liệu trình)</InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Đơn vị tính"
                                value={serviceData.unit}
                                onChange={(e) => setServiceData({ ...serviceData, unit: e.target.value })}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group controlId="formServiceGroup">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faList} />Nhóm dịch vụ</InputGroup.Text>
                            <Form.Select
                                value={serviceData.serviceGroup}
                                onChange={(e) => setServiceData({ ...serviceData, serviceGroup: e.target.value })}
                            >
                                <option value="Ngoại khoa">Ngoại khoa</option>
                                <option value="Nội khoa">Nội khoa</option>
                                <option value="Spa">Spa</option>
                            </Form.Select>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group controlId="formNote">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faStickyNote} />Mô tả</InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Mô tả dịch vụ"
                                value={serviceData.note}
                                onChange={(e) => setServiceData({ ...serviceData, note: e.target.value })}
                            />
                        </InputGroup>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <Button variant="secondary" onClick={handleClose}>
                    Đóng
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Lưu lại
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditService;
