import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserEdit, faBirthdayCake, faVenusMars, faPhone, faEnvelope, faHome, faStickyNote } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

const EditCustomer = ({ show, handleClose, customerId, onReFresh }) => {
    const apiUrl = process.env.REACT_APP_API_URL; // URL API

    const token = localStorage.getItem('token');
    let userId;
    if (token && typeof token === 'string') {
        try {
            const decoded = jwtDecode(token);
            userId = decoded.userId;
        } catch (error) {
            console.error('Token không hợp lệ:', error);
        }
    } else {
        console.warn('Token không hợp lệ hoặc không tồn tại.');
    }
    const [customerData, setCustomerData] = useState({
        name: '',
        birthday: '',
        gender: '',
        phone: '',
        email: '',
        address: '',
        note: ''
    });

    useEffect(() => {
        if (customerId) {
            axios.get(`${apiUrl}/customers/${customerId}?createdBy=${userId}`)
                .then(response => {
                    setCustomerData(response.data);
                })
                .catch(error => {
                    toast.error('Lỗi khi tải thông tin!');
                    console.error("Error fetching customer data:", error);
                });
        }
    }, [customerId, setCustomerData, apiUrl, userId]); // Nếu cần thiết    

    const handleSave = () => {
        axios.put(`${apiUrl}/customers/${customerId}`, customerData)
            .then(() => {
                toast.success('Cập nhật khách hàng thành công!');
                handleClose();
                onReFresh();
            })
            .catch(error => {
                toast.error('Lỗi khi cập nhật khách hàng!');
                console.error('Error updating customer:', error);
            });
    };


    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title><FontAwesomeIcon icon={faUserEdit} /> Sửa Khách Hàng</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formName">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faUser} /></InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Tên"
                                value={customerData.name}
                                onChange={(e) => setCustomerData({ ...customerData, name: e.target.value })}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group controlId="formBirthday">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faBirthdayCake} /></InputGroup.Text>
                            <Form.Control
                                type="date"
                                placeholder="Ngày Sinh"
                                value={customerData.birthday ? customerData.birthday.slice(0, 10) : ''}
                                onChange={(e) => setCustomerData({ ...customerData, birthday: e.target.value })}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group controlId="formGender">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faVenusMars} /></InputGroup.Text>
                            <Form.Control
                                as="select"
                                value={customerData.gender}
                                onChange={(e) => setCustomerData({ ...customerData, gender: e.target.value })}
                            >
                                <option value="Nam">Nam</option>
                                <option value="Nữ">Nữ</option>
                            </Form.Control>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group controlId="formPhone">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faPhone} /></InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Số Điện Thoại"
                                value={customerData.phone}
                                onChange={(e) => setCustomerData({ ...customerData, phone: e.target.value })}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group controlId="formEmail">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faEnvelope} /></InputGroup.Text>
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                value={customerData.email}
                                onChange={(e) => setCustomerData({ ...customerData, email: e.target.value })}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group controlId="formAddress">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faHome} /></InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Địa Chỉ"
                                value={customerData.address}
                                onChange={(e) => setCustomerData({ ...customerData, address: e.target.value })}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group controlId="formNote">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faStickyNote} /></InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Ghi Chú"
                                value={customerData.note}
                                onChange={(e) => setCustomerData({ ...customerData, note: e.target.value })}
                            />
                        </InputGroup>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <Button variant="secondary" onClick={handleClose}>
                    Đóng
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Lưu lại
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditCustomer;
