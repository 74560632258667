import React, { useState, useRef, useEffect } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingMedical, faDollarSign, faBalanceScale, faStickyNote, faList } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

const AddService = ({ show, handleClose, onReFresh }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [serviceData, setServiceData] = useState({
        name: '',
        price: '',
        unit: '',
        note: '',
        createdBy: '',
        serviceGroup: 'Ngoại khoa'
    });

    const inputRefs = useRef([]);

    useEffect(() => {
        if (show) {
            inputRefs.current[0]?.focus();
        }
    }, [show]);

    const handleKeyDown = (e, index) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const nextIndex = index + 1;
            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex]?.focus();
            } else {
                document.getElementById('saveButton')?.focus();
            }
        }
    };

    const resetForm = () => {
        setServiceData({
            name: '',
            price: '',
            unit: '',
            note: '',
            createdBy: '',
            serviceGroup: 'Ngoại khoa'
        });
    };

    const handleSave = () => {
        const token = localStorage.getItem('token');
        const decoded = jwtDecode(token);
        const userId = decoded.userId;
        axios.post(`${apiUrl}/services`, {
            ...serviceData,
            createdBy: userId,
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(() => {
                toast.success('Thêm dịch vụ thành công!');
                onReFresh();
                resetForm();
                handleClose();
            })
            .catch(error => {
                console.error('Error adding service:', error);
            });
    };

    const handleModalClose = () => {
        resetForm();
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleModalClose}>
            <Modal.Header closeButton>
                <Modal.Title><FontAwesomeIcon icon={faHandHoldingMedical} /> Thêm Dịch Vụ</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className='m-1' controlId="formName">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faHandHoldingMedical} />Tên dịch vụ</InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="nhập tên dịch vụ"
                                value={serviceData.name}
                                onChange={(e) => setServiceData({ ...serviceData, name: e.target.value })}
                                ref={el => inputRefs.current[0] = el}
                                onKeyDown={(e) => handleKeyDown(e, 0)}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className='m-1' controlId="formPrice">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faDollarSign} />Giá (VND)</InputGroup.Text>
                            <Form.Control
                                type="number"
                                placeholder="nhập giá"
                                value={serviceData.price}
                                onChange={(e) => setServiceData({ ...serviceData, price: e.target.value })}
                                ref={el => inputRefs.current[1] = el}
                                onKeyDown={(e) => handleKeyDown(e, 1)}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className='m-1' controlId="formUnit">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faBalanceScale} />Đơn vị (Lần/Liệu Trình)</InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="đơn vị tính"
                                value={serviceData.unit}
                                onChange={(e) => setServiceData({ ...serviceData, unit: e.target.value })}
                                ref={el => inputRefs.current[2] = el}
                                onKeyDown={(e) => handleKeyDown(e, 2)}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className='m-1' controlId="formServiceGroup">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faList} />Nhóm dịch vụ</InputGroup.Text>
                            <Form.Select
                                value={serviceData.serviceGroup}
                                onChange={(e) => setServiceData({ ...serviceData, serviceGroup: e.target.value })}
                                ref={el => inputRefs.current[3] = el}
                                onKeyDown={(e) => handleKeyDown(e, 3)}
                            >
                                <option value="Ngoại khoa">Ngoại khoa</option>
                                <option value="Nội khoa">Nội khoa</option>
                                <option value="Spa">Spa</option>
                            </Form.Select>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className='m-1' controlId="formNote">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faStickyNote} />Mô tả</InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="nhập mô tả về dịch vụ"
                                value={serviceData.note}
                                onChange={(e) => setServiceData({ ...serviceData, note: e.target.value })}
                                ref={el => inputRefs.current[4] = el}
                                onKeyDown={(e) => handleKeyDown(e, 4)}
                            />
                        </InputGroup>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <Button variant="secondary" onClick={handleModalClose}>
                    Đóng
                </Button>
                <Button id="saveButton" variant="primary" onClick={handleSave}>
                    Lưu
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddService;
