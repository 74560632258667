import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faDollarSign, faBalanceScale, faStickyNote, faPen, faUserDoctor } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

const EditMedicine = ({ show, handleClose, medicineId, onReFresh }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [medicineData, setMedicineData] = useState({
        name: '',
        price: '',
        unit: '',
        quantity: '',
        usemed: '',
        note: ''
    });

    useEffect(() => {
        if (medicineId) {
            axios.get(`${apiUrl}/medicines/${medicineId}`)
                .then(response => {
                    setMedicineData(response.data);
                })
                .catch(error => {
                    console.error("Error fetching medicine data:", error);
                });
        }
    }, [medicineId, apiUrl, setMedicineData]);

    const handleSave = () => {
        axios.put(`${apiUrl}/medicines/${medicineId}`, medicineData)
            .then(() => {
                toast.success('Sửa thuốc thành công!')
                handleClose();
                onReFresh();
            })
            .catch(error => {
                console.error('Error updating medicine:', error);
            });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title><FontAwesomeIcon icon={faPen} /> Sửa Thuốc</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formName">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faTag} /></InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Tên thuốc"
                                value={medicineData.name}
                                onChange={(e) => setMedicineData({ ...medicineData, name: e.target.value })}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group controlId="formPrice">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faDollarSign} /></InputGroup.Text>
                            <Form.Control
                                type="number"
                                placeholder="Giá thuốc"
                                value={medicineData.price}
                                onChange={(e) => setMedicineData({ ...medicineData, price: e.target.value })}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group controlId="formUnit">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faBalanceScale} /></InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Đơn vị"
                                value={medicineData.unit}
                                onChange={(e) => setMedicineData({ ...medicineData, unit: e.target.value })}
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="formUsemed">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faUserDoctor} /></InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Đường dùng"
                                value={medicineData.usemed}
                                onChange={(e) => setMedicineData({ ...medicineData, usemed: e.target.value })}
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="formNote">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faStickyNote} /></InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Ghi Chú"
                                value={medicineData.note}
                                onChange={(e) => setMedicineData({ ...medicineData, note: e.target.value })}
                            />
                        </InputGroup>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <Button variant="secondary" onClick={handleClose}>
                    Đóng
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Lưu lại
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditMedicine;
