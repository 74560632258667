import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Dropdown, Offcanvas } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHome, faComments, faFileInvoiceDollar, faCalendarDay,
    faTasks, faImage, faHandHoldingHeart, faBoxOpen, faPills,
    faMoneyBill, faCog, faUserCog, faSignOutAlt, faBars,
    faCogs
} from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/images/ohs.plus.png';

const CustomNavbar = ({ handleLogout }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

    const toggleMenu = () => setShowMenu(!showMenu);

    // Theo dõi kích thước màn hình
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 576);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            {/* Navbar trên PC */}
            {!isMobile && (
                <Navbar bg="light" expand="xl" fixed="top" className='custom-navbar'>
                    <Navbar.Brand href="/">
                        <img
                            src={logo}
                            width="68"
                            height="68"
                            className="d-inline-block align-top ms-3"
                            alt="OHS.Plus"
                        />
                    </Navbar.Brand>
                    <Nav className="me-auto text-center">
                        <Nav.Link href="/"><FontAwesomeIcon icon={faHome} size='2x' /><br />Trang chủ</Nav.Link>
                        <Nav.Link href="/consultations"><FontAwesomeIcon icon={faComments} size='2x' /><br />Tư Vấn/Khám</Nav.Link>
                        <Nav.Link href="/receipts"><FontAwesomeIcon icon={faFileInvoiceDollar} size='2x' /><br />Phiếu Thu</Nav.Link>
                        <Nav.Link href="/appointments"><FontAwesomeIcon icon={faCalendarDay} size='2x' /><br />Lịch Hẹn</Nav.Link>
                        <Nav.Link href="/courses"><FontAwesomeIcon icon={faTasks} size='2x' /><br />Liệu Trình</Nav.Link>
                        <Nav.Link href="/images"><FontAwesomeIcon icon={faImage} size='2x' /><br />Hình Ảnh</Nav.Link>
                        <Nav.Link href="/services"><FontAwesomeIcon icon={faHandHoldingHeart} size='2x' /><br />Dịch Vụ</Nav.Link>
                        <Nav.Link href="/supplies"><FontAwesomeIcon icon={faBoxOpen} size='2x' /><br />Vật Tư/Sản phẩm</Nav.Link>
                        <Nav.Link href="/medicines"><FontAwesomeIcon icon={faPills} size='2x' /><br />Thuốc/Kê toa</Nav.Link>
                        <Nav.Link href="/debts"><FontAwesomeIcon icon={faMoneyBill} size='2x' /><br />Quản Lý Nợ</Nav.Link>
                    </Nav>
                    <Dropdown align="end" className='m-1'>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <FontAwesomeIcon icon={faCog} /> Cài đặt
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="/settings/account"><FontAwesomeIcon icon={faUserCog} /> Cá nhân</Dropdown.Item>
                            <Dropdown.Item onClick={handleLogout}><FontAwesomeIcon icon={faSignOutAlt} /> Đăng xuất</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Navbar>
            )}

            {/* Menu trên Mobile */}
            {isMobile && (
                <>
                    <div className="d-flex fixed-bottom justify-content-start p-2">
                        <button
                            className="menu-btn"
                            onClick={toggleMenu}
                        >
                            <FontAwesomeIcon icon={faBars} size="lg" />
                        </button>
                    </div>
                    <Offcanvas show={showMenu} onHide={toggleMenu} placement="start">
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>Menu</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body><div className="app-menu">
                            <a href="/" className="menu-item">
                                <FontAwesomeIcon icon={faHome} size="2x" />
                                <span>Trang chủ</span>
                            </a>
                            <a href="/consultations" className="menu-item">
                                <FontAwesomeIcon icon={faComments} size="2x" />
                                <span>Tư Vấn/Khám</span>
                            </a>
                            <a href="/receipts" className="menu-item">
                                <FontAwesomeIcon icon={faFileInvoiceDollar} size="2x" />
                                <span>Phiếu Thu</span>
                            </a>
                            <a href="/appointments" className="menu-item">
                                <FontAwesomeIcon icon={faCalendarDay} size="2x" />
                                <span>Lịch Hẹn</span>
                            </a>
                            <a href="/courses" className="menu-item">
                                <FontAwesomeIcon icon={faTasks} size="2x" />
                                <span>Liệu Trình</span>
                            </a>
                            <a href="/images" className="menu-item">
                                <FontAwesomeIcon icon={faImage} size="2x" />
                                <span>Hình Ảnh</span>
                            </a>
                            <a href="/services" className="menu-item">
                                <FontAwesomeIcon icon={faHandHoldingHeart} size="2x" />
                                <span>Dịch Vụ</span>
                            </a>
                            <a href="/supplies" className="menu-item">
                                <FontAwesomeIcon icon={faBoxOpen} size="2x" />
                                <span>Vật Tư/Sản phẩm</span>
                            </a>
                            <a href="/medicines" className="menu-item">
                                <FontAwesomeIcon icon={faPills} size="2x" />
                                <span>Thuốc/Kê toa</span>
                            </a>
                            <a href="/debts" className="menu-item">
                                <FontAwesomeIcon icon={faMoneyBill} size="2x" />
                                <span>Quản Lý Nợ</span>
                            </a>
                            <a href="/settings/account" className="menu-item">
                                <FontAwesomeIcon icon={faCogs} size="2x" />
                                <span>Cài Đặt</span>
                            </a>
                        </div>
                        </Offcanvas.Body>
                    </Offcanvas>
                </>
            )}
        </>
    );
};

export default CustomNavbar;
