import React, { useState, useEffect, useCallback } from 'react';
import { Table, Button } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import formatDate from './FormatDate';

const AppoitmentsList = ({ apiUrl, userId, onFresh, refreshList }) => {
    const [appointments, setAppointments] = useState([]);

    const translateStatus = (status) => {
        switch (status) {
            case 'Pending':
                return 'Đang chờ';
            case 'Completed':
                return 'Hoàn thành';
            case 'Canceled':
                return 'Đã hủy';
            default:
                return 'Không xác định'; // Nếu có trạng thái nào khác
        }
    };
    const fetchAppointments = useCallback(async () => {
        try {
            const response = await axios.get(`${apiUrl}/appointments?createdBy=${userId}`);
            const sortedAppointments = response.data.sort((b, a) => new Date(b.start) - new Date(a.start));
            setAppointments(sortedAppointments);
        } catch (error) {
            console.error("Lỗi khi lấy danh sách cuộc hẹn:", error);
            toast.error("Không thể lấy danh sách cuộc hẹn!");
        }
    }, [apiUrl, userId]);
    useEffect(() => {

        fetchAppointments();
    }, [fetchAppointments]);

    useEffect(() => {
        if (refreshList) {
            refreshList(fetchAppointments);
        }
    }, [refreshList, fetchAppointments]);


    const handleDelete = async (appointmentId) => {
        try {
            await axios.delete(`${apiUrl}/appointments/${appointmentId}?createdBy=${userId}`);
            setAppointments(appointments.filter(appointment => appointment._id !== appointmentId));
            toast.success("Xóa cuộc hẹn thành công!");
            onFresh();
        } catch (error) {
            console.error("Lỗi khi xóa cuộc hẹn:", error);
            toast.error("Không thể xóa cuộc hẹn!");
        }
    };

    const canDelete = (appointment) => {
        const now = new Date();
        return appointment.status !== "Pending" || new Date(appointment.start) < now;
    };

    return (
        <div className="mt-4">
            <hr />
            <h3>Danh Sách Tất Cả Lịch Hẹn</h3>
            <Table striped bordered hover responsive>
                <thead className='text-center'>
                    <tr>
                        <th>STT</th>
                        <th>Tên Khách Hàng</th>
                        <th>Loại Nhắc Hẹn</th>
                        <th>Ngày Hẹn</th>
                        <th>Trạng Thái</th>
                        <th>Hành Động</th>
                    </tr>
                </thead>
                <tbody>
                    {appointments.length > 0 ? (
                        appointments.map((appointment, index) => {
                            const customer = appointment.customerId; // Assumes customer info is populated
                            return (
                                <tr key={appointment._id}>
                                    <td>{index + 1}</td>
                                    <td>{customer?.name || 'N/A'}</td>
                                    <td>{appointment.title}</td>
                                    <td>{formatDate(appointment.start)}</td>
                                    <td>{translateStatus(appointment.status)}</td>
                                    <td className='text-center'>
                                        {canDelete(appointment) ? (
                                            <Button
                                                variant="danger"
                                                onClick={() => handleDelete(appointment._id)}
                                            >
                                                Xóa
                                            </Button>
                                        ) : (
                                            <Button variant="secondary" disabled>
                                                Xóa
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan="6" className="text-center">Chưa có lịch hẹn nào</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default AppoitmentsList;
