import React, { useState, useEffect } from "react";
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBox, faBalanceScale, faDollarSign, faFileEdit, faListNumeric, faStickyNote } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

const EditSupply = ({ show, handleClose, supplyId, onReFresh }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [supplyData, setSupplyData] = useState({
        name: '',
        price: '',
        unit: '',
        quantity: '',
        note: ''
    });

    useEffect(() => {
        if (supplyId) {
            axios.get(`${apiUrl}/supplies/${supplyId}`)
                .then(response => {
                    setSupplyData(response.data);
                })
                .catch(error => {
                    console.error("Lỗi tải dữ liệu vật tư:", error);
                });
        }
    }, [supplyId, apiUrl, setSupplyData]);

    const handleSave = () => {
        axios.put(`${apiUrl}/supplies/${supplyId}`, supplyData)
            .then(() => {
                toast.success('Sửa vật tư thành công!')
                handleClose();
                onReFresh();
            })
            .catch(error => {
                console.error('Lỗi cập nhật vật tư:', error);
            });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title><FontAwesomeIcon icon={faFileEdit} /> Sửa Vật Tư</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formName">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faBox} /></InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Tên vật tư"
                                value={supplyData.name}
                                onChange={(e) => setSupplyData({ ...supplyData, name: e.target.value })}
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="formPrice">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faDollarSign} /></InputGroup.Text>
                            <Form.Control
                                type="number"
                                placeholder="Giá vật tư"
                                value={supplyData.price}
                                onChange={(e) => setSupplyData({ ...supplyData, price: Number(e.target.value) })}
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="formUnit">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faBalanceScale} /></InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Đơn vị"
                                value={supplyData.unit}
                                onChange={(e) => setSupplyData({ ...supplyData, unit: e.target.value })}
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="formQuantity">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faListNumeric} /></InputGroup.Text>
                            <Form.Control
                                type="number"
                                placeholder="Số lượng"
                                value={supplyData.quantity}
                                onChange={(e) => setSupplyData({ ...supplyData, quantity: Number(e.target.value) })}
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="formNote">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faStickyNote} /></InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Ghi chú"
                                value={supplyData.note}
                                onChange={(e) => setSupplyData({ ...supplyData, note: e.target.value })}
                            />
                        </InputGroup>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <Button variant="secondary" onClick={handleClose}>
                    Đóng
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Lưu lại
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditSupply;
