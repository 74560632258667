import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { jwtDecode } from 'jwt-decode';
import Select from 'react-select';
import formatDate from './FormatDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBirthdayCake, faCommentDots, faHandHoldingMedical, faNoteSticky, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';

const EditConsultation = ({ show, handleClose, consultationId, onReFresh }) => {
    const [consultationData, setConsultationData] = useState({
        customerId: '',
        services: [],
        note: '',
        executionDate: '', // Ngày thực hiện
        staffId: null // Người thực hiện
    });
    const [staffOptions, setStaffOptions] = useState([]);

    const [availableServices, setAvailableServices] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [unselectedServices, setUnselectedServices] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [customerDetails, setCustomerDetails] = useState({});
    const apiUrl = process.env.REACT_APP_API_URL; // URL API

    const token = localStorage.getItem('token');
    let userId;
    if (token && typeof token === 'string') {
        try {
            const decoded = jwtDecode(token);
            userId = decoded.userId;
        } catch (error) {
            console.error('Token không hợp lệ:', error);
        }
    } else {
        console.warn('Token không hợp lệ hoặc không tồn tại.');
    }

    useEffect(() => {
        const fetchStaffOptions = async () => {
            try {
                const staffResponse = await axios.get(`${apiUrl}/user/${userId}/staff`);
                setStaffOptions(staffResponse.data.staff.map(s => ({
                    value: s._id,
                    label: s.name
                })));
            } catch (error) {
                console.error("Lỗi khi lấy danh sách nhân viên:", error);
            }
        };

        fetchStaffOptions();
    }, [apiUrl, userId]);

    // Hàm lấy chi tiết tư vấn

    const fetchConsultationDetails = useCallback(async () => {
        try {
            const response = await axios.get(`${apiUrl}/consultations/${consultationId}?createdBy=${userId}`);
            const { customerId, services, note, executionDate, staffId } = response.data;

            // Lấy thông tin dịch vụ và giá như trước
            const servicesResponse = await axios.get(`${apiUrl}/services?createdBy=${userId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            const allServices = servicesResponse.data;

            const selectedWithPrice = services.map(service => {
                const fullService = allServices.find(s => s.id === service.serviceId.id);
                return {
                    ...service,
                    price: fullService ? fullService.price : 0
                };
            });

            setConsultationData({ customerId, services: selectedWithPrice, note, executionDate, staffId });
            setSelectedServices(selectedWithPrice);

            if (customerId) {
                const customerResponse = await axios.get(`${apiUrl}/customers/${customerId}`);
                setCustomerDetails(customerResponse.data);
            }
        } catch (error) {
            console.error("Lỗi khi lấy chi tiết tư vấn:", error);
        }
    }, [apiUrl, userId, consultationId, token]);


    useEffect(() => {
        if (consultationId)
            fetchConsultationDetails();  // Lấy danh sách dịch vụ
    }, [consultationId, fetchConsultationDetails]);



    // Hàm lấy danh sách dịch vụ
    const fetchAvailableServices = useCallback(async () => {
        try {
            const response = await axios.get(`${apiUrl}/services?createdBy=${userId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            const allServices = response.data; // Tất cả dịch vụ
            const selectedServiceIds = new Set(selectedServices.map(s => s.serviceId.id)); // Lấy danh sách ID dịch vụ đã chọn
            const unselected = allServices.filter(service => !selectedServiceIds.has(service.id)); // Dịch vụ chưa chọn
            setAvailableServices(allServices); // Cập nhật danh sách dịch vụ
            setUnselectedServices(unselected); // Cập nhật dịch vụ chưa chọn
        } catch (error) {
            console.error("Lỗi khi lấy danh sách dịch vụ:", error);
        }
    }, [apiUrl, userId, selectedServices, token]);

    useEffect(() => {
        fetchAvailableServices();
    }, [fetchAvailableServices]);

    // Hàm toggle dịch vụ đã chọn
    const handleServiceToggle = (serviceId) => {
        const updatedSelectedServices = selectedServices.filter(service => service.serviceId.id !== serviceId); // Cập nhật danh sách dịch vụ đã chọn
        const serviceToUnselect = availableServices.find(service => service.id === serviceId); // Dịch vụ cần bỏ chọn
        setSelectedServices(updatedSelectedServices); // Cập nhật state
        setUnselectedServices([...unselectedServices, serviceToUnselect]); // Cập nhật dịch vụ chưa chọn
    };

    // Hàm thêm dịch vụ
    const handleAddService = (serviceId) => {
        const newService = availableServices.find(service => service.id === serviceId); // Tìm dịch vụ
        setSelectedServices([...selectedServices, { serviceId: { id: serviceId }, name: newService.name, price: newService.price }]); // Thêm dịch vụ vào danh sách đã chọn
        setUnselectedServices(unselectedServices.filter(service => service.id !== serviceId)); // Cập nhật danh sách dịch vụ chưa chọn
    };

    // Hàm xử lý thay đổi input
    const handleInputChange = (e) => {
        const { name, value } = e.target; // Lấy name và value từ input
        setConsultationData((prevData) => ({ ...prevData, [name]: value })); // Cập nhật state consultationData
    };

    // Hàm lưu thay đổi
    const handleSaveChanges = async () => {
        try {
            const updatedData = {
                ...consultationData,
                services: selectedServices.map(service => ({
                    serviceId: service.serviceId,
                    name: service.name,
                    price: service.price
                }))
            };
            await axios.put(`${apiUrl}/consultations/${consultationId}`, updatedData);
            onReFresh();
            handleClose();
        } catch (error) {
            console.error("Lỗi khi cập nhật tư vấn:", error);
        }
    };

    const formatDateForInput = (date) => {
        if (!date) return '';
        const d = new Date(date);
        return d.toISOString().split('T')[0]; // Trả về định dạng "yyyy-MM-dd"
    };

    // Hàm tính tổng chi phí
    const calculateTotalCost = () => {
        return selectedServices.reduce((total, service) => total + (service.price || 0), 0);
    };

    // Hàm lọc dịch vụ
    const filteredServices = unselectedServices
        .filter(service =>
            service.name.toLowerCase().includes(searchTerm.toLowerCase()) && // Tìm kiếm theo tên dịch vụ
            !selectedServices.some(selected => selected.serviceId.id === service.id) // Chỉ hiển thị dịch vụ chưa chọn
        );

    return (
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title><FontAwesomeIcon icon={faCommentDots} /> Chỉnh sửa Tư Vấn</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FontAwesomeIcon icon={faUser} /> <b><span className='text-success'>{customerDetails.name}</span> - <FontAwesomeIcon icon={faBirthdayCake} /> {formatDate(customerDetails.birthday)} - <FontAwesomeIcon icon={faPhone} /> {customerDetails.phone}</b>
                <hr />
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label><FontAwesomeIcon icon={faHandHoldingMedical} /> <b>Dịch Vụ Đã Chọn - Tổng chi phí: <span className='text-danger'>{calculateTotalCost().toLocaleString('vi-VN')}</span> VND</b></Form.Label>
                        {selectedServices.map(service => (
                            <div key={uuidv4()} className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={`selected-${service.serviceId.id}`}
                                    checked
                                    onChange={() => handleServiceToggle(service.serviceId.id)} // Xử lý toggle
                                />
                                <label className="form-check-label" htmlFor={`selected-${service.serviceId.id}`}>
                                    {service.name} - {(service.price || 0).toLocaleString('vi-VN')} VND
                                </label>
                            </div>
                        ))}

                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label><FontAwesomeIcon icon={faHandHoldingMedical} /> <b>Danh Sách Dịch Vụ</b></Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Tìm kiếm dịch vụ..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)} // Cập nhật từ khóa tìm kiếm
                        />
                        <div style={{ maxHeight: '100px', overflowY: 'auto', marginTop: '5px' }}>
                            {filteredServices.map(service => (
                                <div key={uuidv4()} className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={service.id}
                                        checked={false}
                                        onChange={() => handleAddService(service.id)} // Xử lý thêm dịch vụ
                                    />
                                    <label className="form-check-label" htmlFor={service.id}>
                                        {service.name} - {service.price.toLocaleString('vi-VN')} VND
                                    </label>
                                </div>
                            ))}
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Ngày Thực Hiện</Form.Label>
                        <Form.Control
                            type="date"
                            name="executionDate"
                            value={formatDateForInput(consultationData.executionDate)} // Định dạng đúng
                            onChange={(e) => setConsultationData(prev => ({ ...prev, executionDate: e.target.value }))}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Người Thực Hiện</Form.Label>
                        <Select
                            options={staffOptions}
                            value={staffOptions.find(option => option.value === consultationData.staffId)}
                            onChange={(option) => setConsultationData(prev => ({ ...prev, staffId: option?.value || null }))}
                            placeholder="Chọn nhân viên thực hiện..."
                            isClearable
                            className="w-100"
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label><FontAwesomeIcon icon={faNoteSticky} /> <b>Ghi Chú</b></Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={1}
                            name="note"
                            value={consultationData.note}
                            onChange={handleInputChange} // Xử lý thay đổi ghi chú
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <Button variant="secondary" onClick={handleClose}>Hủy</Button>
                <Button variant="primary" onClick={handleSaveChanges}>Lưu Thay Đổi</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditConsultation;
