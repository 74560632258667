// versionsData.js
export const versionUpdates = [
    {
        version: "1.0.0.8",
        date: "2024-11-12",
        changes: [
            "Cập nhật tính năng Kê toa, Lưu và sử dụng toa mẫu.",
            "Có 2 lựa chọn là chỉ kê toa và kê toa có trừ thuốc trong kho.",
            "Bổ sung tính năng tải ảnh về khi xem ảnh trong phần Hình Ảnh"
        ]
    },
    {
        version: "1.0.0.7",
        date: "2024-11-10",
        changes: [
            "Cập nhật các mục nhập liệu tự chuyển qua trường tiếp theo khi bấm Enter",
            "Bổ sung thêm chức năng Nhập Thuốc, Phiếu Nhập Thuốc",
            "Chức năng kê toa sắp hoàn thiện"
        ]
    },
    {
        version: "1.0.0.6",
        date: "2024-11-09",
        changes: [
            "Chỉnh sửa lại mục Lịch Hẹn về cách hiển thị và có thể tạo lịch hẹn cho nhiều khách hàng trong 1 lần",
            "Chỉnh sửa lại mục Quản Lý Nợ",
            "Cập nhật khi nhập form chỉ cần nhấn Enter để chuyển tới ô tiếp theo",
            "Hoàn thiện mục Sửa, Xóa có điều kiện của các mục liên quan."
        ]
    },
    {
        version: "1.0.0.5",
        date: "2024-11-08",
        changes: [
            "Chỉnh sửa hiệu ứng loading.",
            "Thêm tính năng ẩn hiện các cột dữ liệu.",
            "Hoàn thiện mục Liệu Trình",
            "Thêm đầy đủ tính năng Xóa, Sửa cho mục Quản Lý Hình Ảnh"
        ]
    },
    {
        version: "1.0.0.4",
        date: "2024-11-05",
        changes: [
            "Chính thức public cho bạn bè test thử.",
            "Cũng có một vài lỗi nhưng cơ bản là hài lòng.",
            "Public theo phương châm HOÀN THÀNH hơn chờ HOÀN HẢO. Hihi!!!",
            "Thử webhook cho việc cập nhật tính năng mới nhanh gọn hơn nhưng chưa thành công."
        ]
    },
    {
        version: "1.0.0.3",
        date: "2024-10-30",
        changes: [
            "Chính thức sắm VPS để deploy ứng dụng.",
            "Mò cài NodeJS server hết mấy ngày.",
            "Chỉnh sửa lại giao diện, bỏ Menusidebar"
        ]
    },
    {
        version: "1.0.0.2",
        date: "2024-10-28",
        changes: [
            "Chạy thử các tính năng cơ bản trên local.",
            "Fix lỗi mệt mà không được nghỉ",
            "Do mò mẫm nên nhiều khi bị ẻm ChatGPT đi chơi hơi xa."
        ]
    },
    {
        version: "1.0.0.0",
        date: "2024-10-18",
        changes: [
            "Bắt đầu triển khai ứng dụng.",
            "Công lao nhiều nhất thuộc về ChatGPT."
        ]
    },
];
