import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faUser, faBirthdayCake, faVenusMars, faPhone, faEnvelope, faHome, faStickyNote } from '@fortawesome/free-solid-svg-icons';

const AddCustomer = ({ show, handleClose, onReFresh }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [customerData, setCustomerData] = useState({
        name: '',
        birthday: '',
        gender: 'Nam', // Giới tính mặc định
        phone: '',
        email: '',
        address: '',
        note: '',
        createdBy: ''
    });

    // Tạo ref cho các mục nhập
    const nameRef = useRef(null);
    const birthdayRef = useRef(null);
    const genderRef = useRef(null);
    const phoneRef = useRef(null);
    const emailRef = useRef(null);
    const addressRef = useRef(null);
    const noteRef = useRef(null);

    useEffect(() => {
        if (show && nameRef.current) {
            nameRef.current.focus(); // Focus vào ô nhập tên khách hàng khi modal mở lên
        }
    }, [show]);

    const handleSave = () => {
        const token = localStorage.getItem('token'); // Lấy token từ localStorage
        const decoded = jwtDecode(token);
        const userId = decoded.userId;
        axios.post(`${apiUrl}/customers`, {
            ...customerData,
            createdBy: userId,
        },
            {
                headers: {
                    Authorization: `Bearer ${token}` // Thêm token vào header
                }
            })
            .then(() => {
                toast.success('Thêm khách hàng thành công!');
                onReFresh();
                setCustomerData({ // Reset form sau khi thêm thành công
                    name: '',
                    birthday: '',
                    gender: 'Nam',
                    phone: '',
                    email: '',
                    address: '',
                    note: '',
                    createdBy: ''
                });
                handleClose(); // Đóng modal sau khi thêm khách hàng
            })
            .catch(error => {
                toast.error('Lỗi khi thêm khách hàng!');
                console.error('Error adding customer:', error);
            });
    };

    const handleKeyDown = (event, nextRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (nextRef && nextRef.current) {
                nextRef.current.focus();
            } else {
                handleSave(); // Thực hiện lưu khi đến phần cuối form
            }
        }
    };

    return (
        <Modal show={show} onHide={handleClose} size='xl'>
            <Modal.Header closeButton>
                <Modal.Title> <FontAwesomeIcon icon={faUserPlus} /> Thêm Khách Hàng</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className='m-1' controlId="formName">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faUser} />&nbsp;Họ Và Tên</InputGroup.Text>
                            <Form.Control
                                type="text"
                                ref={nameRef}
                                placeholder="..."
                                value={customerData.name}
                                onChange={(e) => setCustomerData({ ...customerData, name: e.target.value })}
                                onKeyDown={(e) => handleKeyDown(e, birthdayRef)}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className='m-1' controlId="formBirthday">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faBirthdayCake} />&nbsp;Ngày Sinh</InputGroup.Text>
                            <Form.Control
                                type="date"
                                ref={birthdayRef}
                                placeholder="Ngày Sinh"
                                value={customerData.birthday}
                                onChange={(e) => setCustomerData({ ...customerData, birthday: e.target.value })}
                                onKeyDown={(e) => handleKeyDown(e, genderRef)}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className='m-1' controlId="formGender">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faVenusMars} />&nbsp;Giới Tính</InputGroup.Text>
                            <Form.Control
                                as="select"
                                ref={genderRef}
                                value={customerData.gender}
                                onChange={(e) => setCustomerData({ ...customerData, gender: e.target.value })}
                                onKeyDown={(e) => handleKeyDown(e, phoneRef)}
                            >
                                <option value="Nữ">Nữ</option>
                                <option value="Nam">Nam</option>
                            </Form.Control>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className='m-1' controlId="formPhone">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faPhone} />&nbsp;Điện Thoại</InputGroup.Text>
                            <Form.Control
                                type="text"
                                ref={phoneRef}
                                placeholder="..."
                                value={customerData.phone}
                                onChange={(e) => setCustomerData({ ...customerData, phone: e.target.value })}
                                onKeyDown={(e) => handleKeyDown(e, emailRef)}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className='m-1' controlId="formEmail">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faEnvelope} />&nbsp;Email</InputGroup.Text>
                            <Form.Control
                                type="email"
                                ref={emailRef}
                                placeholder="..."
                                value={customerData.email}
                                onChange={(e) => setCustomerData({ ...customerData, email: e.target.value })}
                                onKeyDown={(e) => handleKeyDown(e, addressRef)}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className='m-1' controlId="formAddress">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faHome} />&nbsp;Địa Chỉ</InputGroup.Text>
                            <Form.Control
                                type="text"
                                ref={addressRef}
                                placeholder="..."
                                value={customerData.address}
                                onChange={(e) => setCustomerData({ ...customerData, address: e.target.value })}
                                onKeyDown={(e) => handleKeyDown(e, noteRef)}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className='m-1' controlId="formNote">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faStickyNote} />&nbsp;Ghi Chú</InputGroup.Text>
                            <Form.Control
                                type="text"
                                ref={noteRef}
                                placeholder="..."
                                value={customerData.note}
                                onChange={(e) => setCustomerData({ ...customerData, note: e.target.value })}
                                onKeyDown={(e) => handleKeyDown(e, null)} // Không có ref tiếp theo, gọi hàm lưu
                            />
                        </InputGroup>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <Button variant="secondary" onClick={handleClose}>
                    Đóng
                </Button>
                <Button variant="primary" onClick={handleSave} disabled={!customerData.name}>
                    Lưu
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddCustomer;