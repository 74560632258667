import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import CustomerList from './pages/CustomerList';
import Consultations from './pages/Consultations';
import Appointments from './pages/Appointments';
import Courses from './pages/Courses';
import ImageGallery from './pages/ImageGallery';
import ServiceList from './pages/ServiceList';
import Supplies from './pages/Supplies';
import SupplyUsage from './components/SupplyUsage';
import SupplyReceiptList from './components/SupplyReceiptList';
import Medicines from './pages/Medicine';
import MedicineReceiptList from './components/MedicineReceiptList';
import Prescription from './components/Prescription';
import Receipts from './pages/Receipts';
import Debts from './pages/Debts';
import Login from './pages/Login';
import Register from './pages/Register';
import Settings from './pages/Settings';
import Profile from './pages/Profile';
import NotFound from './components/NotFound';
import Support from './components/Support';
import VersionsPage from './pages/VersionsPage';
import AccountSettings from './pages/AccountSettings';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import CustomNavbar from './components/CustomNavbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import CustomToast from './components/CustomToast';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  };

  return (
    <Router>
      <div className={isLoggedIn ? 'profile' : 'public-view'} onContextMenu={(e) => e.preventDefault()}>
        {isLoggedIn && (
          <CustomNavbar />
        )}
        <div className='app-container'>
          <div className='flex-grow-1'>
            <Routes>
              <Route path="/login" element={isLoggedIn ? <Navigate to="/" /> : <Login setIsLoggedIn={setIsLoggedIn} />} />
              <Route path="/register" element={isLoggedIn ? <Navigate to="/" /> : <Register />} />
              <Route path="/customers" element={isLoggedIn ? <CustomerList /> : <Navigate to="/login" />} />
              <Route path="/consultations" element={isLoggedIn ? <Consultations /> : <Navigate to="/login" />} />
              <Route path="/appointments" element={isLoggedIn ? <Appointments /> : <Navigate to="/login" />} />
              <Route path="/courses" element={isLoggedIn ? <Courses /> : <Navigate to="/login" />} />
              <Route path="/images" element={isLoggedIn ? <ImageGallery /> : <Navigate to="/login" />} />
              <Route path="/services" element={isLoggedIn ? <ServiceList /> : <Navigate to="/login" />} />
              <Route path="/supplies" element={isLoggedIn ? <Supplies /> : <Navigate to="/login" />} />
              <Route path="/supplyreceipts" element={isLoggedIn ? <SupplyReceiptList /> : <Navigate to="/login" />} />
              <Route path="/supplyusage" element={isLoggedIn ? <SupplyUsage /> : <Navigate to="/login" />} />
              <Route path="/medicines" element={isLoggedIn ? <Medicines /> : <Navigate to="/login" />} />
              <Route path="/medicinereceipts" element={isLoggedIn ? <MedicineReceiptList /> : <Navigate to="/login" />} />
              <Route path="/prescription" element={isLoggedIn ? <Prescription /> : <Navigate to="/login" />} />
              <Route path="/receipts" element={isLoggedIn ? <Receipts /> : <Navigate to="/login" />} />
              <Route path="/debts" element={isLoggedIn ? <Debts /> : <Navigate to="/login" />} />
              <Route path="/settings" element={isLoggedIn ? <Settings /> : <Navigate to="/login" />} />
              <Route path="/settings/account" element={isLoggedIn ? <AccountSettings /> : <Navigate to="/login" />} />
              <Route path='/:slug' element={<Profile />} />
              <Route path="/" element={isLoggedIn ? <CustomerList /> : <Navigate to="/login" />} />
              <Route path='*' element={<NotFound />} />
              <Route path='/versions' element={isLoggedIn ? <VersionsPage /> : <Navigate to="/login" />} />
            </Routes>
          </div>
        </div>
        <footer className="bg-light text-center text-lg-start mt-auto footer-app" fixed="bottom">
          <div className="text-center p-1">
            © {new Date().getFullYear()} <b>OHS.Plus</b>. All rights reserved.
          </div>
          {isLoggedIn ?
            <div className="d-flex justify-content-between m-2">
              <div>
                <Link as={Link} to="/versions"> <FontAwesomeIcon icon={faInfoCircle} /></Link>
              </div>
              <div className="ml-auto support-link">
                <Link as={Link} to='tel:+84985660198'>
                  <Support />
                </Link>
              </div>
            </div> : ''}
        </footer>
        <CustomToast />
      </div>
    </Router>
  );
};

export default App;
