import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCapsules } from '@fortawesome/free-solid-svg-icons';

const CreateMedicineReceipt = ({ show, handleClose, onReFresh }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [medicines, setMedicines] = useState([]);
    const [receiptMedicines, setReceiptMedicines] = useState([{ medicineId: '', quantity: '', price: '', expirationDate: '' }]);
    const [note, setNote] = useState('');
    const selectRef = useRef(null);
    const inputRefs = useRef([]);

    useEffect(() => {
        const fetchAllMedicines = async () => {
            try {
                const token = localStorage.getItem('token');
                const decoded = jwtDecode(token);
                const userId = decoded.userId;
                const response = await axios.get(`${apiUrl}/medicines?createdBy=${userId}`);
                setMedicines(response.data.map(med => ({ value: med._id, label: med.name })));
            } catch (error) {
                console.error('Lỗi khi lấy danh sách thuốc:', error);
            }
        };
        fetchAllMedicines();
    }, [apiUrl]);

    useEffect(() => {
        if (show && selectRef.current) {
            selectRef.current.focus();
        }
    }, [show]);

    const handleAddMedicine = () => {
        setReceiptMedicines([...receiptMedicines, { medicineId: '', quantity: 0, price: 0, expirationDate: '' }]);
    };

    const handleRemoveMedicine = (index) => {
        setReceiptMedicines(receiptMedicines.filter((_, i) => i !== index));
    };

    const handleSelectChange = (selectedOption, index) => {
        setReceiptMedicines(receiptMedicines.map((r, i) =>
            i === index ? { ...r, medicineId: selectedOption.value } : r
        ));
        inputRefs.current[index * 4 + 1]?.focus();
    };

    const handleKeyDown = (event, index, field) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            let nextIndex;

            if (field === 'quantity') {
                nextIndex = index * 4 + 2;
            } else if (field === 'price') {
                nextIndex = index * 4 + 3;
            } else if (field === 'expirationDate') {
                nextIndex = 'submitButton';
            }

            if (nextIndex === 'submitButton') {
                document.getElementById('submitButton')?.focus();
            } else if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');
            const decoded = jwtDecode(token);
            const userId = decoded.userId;
            await axios.post(`${apiUrl}/medicineReceipts`, {
                medicines: receiptMedicines,
                note,
                createdBy: userId,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success('Tạo phiếu nhập thuốc thành công!');
            handleClose();
            onReFresh();
        } catch (error) {
            console.error('Lỗi khi tạo phiếu nhập thuốc:', error.response?.data || error.message);
            toast.error('Lỗi khi tạo phiếu nhập thuốc!');
        }
    };

    const isSubmitDisabled = () => {
        return receiptMedicines.some(item => !item.medicineId || item.quantity <= 0 || item.price <= 0);
    };

    return (
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title><FontAwesomeIcon icon={faCapsules} /> Nhập Thuốc</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    {receiptMedicines.map((item, index) => (
                        <Row key={index} className="mb-3">
                            <Col className='col-4'>
                                <Form.Group>
                                    <Form.Label>Chọn Thuốc</Form.Label>
                                    <Select
                                        options={medicines}
                                        onChange={(selectedOption) => handleSelectChange(selectedOption, index)}
                                        placeholder="Tìm và chọn thuốc..."
                                        ref={el => inputRefs.current[index * 4] = el}
                                        autoFocus={index === 0}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='col-2'>
                                <Form.Group>
                                    <Form.Label>Số Lượng</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={item.quantity}
                                        onChange={(e) =>
                                            setReceiptMedicines(receiptMedicines.map((r, i) =>
                                                i === index ? { ...r, quantity: Number(e.target.value) } : r
                                            ))
                                        }
                                        ref={el => inputRefs.current[index * 4 + 1] = el}
                                        onKeyDown={(e) => handleKeyDown(e, index, 'quantity')}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='col-2'>
                                <Form.Group>
                                    <Form.Label>Giá Nhập</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={item.price}
                                        onChange={(e) =>
                                            setReceiptMedicines(receiptMedicines.map((r, i) =>
                                                i === index ? { ...r, price: Number(e.target.value) } : r
                                            ))
                                        }
                                        ref={el => inputRefs.current[index * 4 + 2] = el}
                                        onKeyDown={(e) => handleKeyDown(e, index, 'price')}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='col-2'>
                                <Form.Group>
                                    <Form.Label>Hạn Dùng</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={item.expirationDate}
                                        onChange={(e) =>
                                            setReceiptMedicines(receiptMedicines.map((r, i) =>
                                                i === index ? { ...r, expirationDate: e.target.value } : r
                                            ))
                                        }
                                        ref={el => inputRefs.current[index * 4 + 3] = el}
                                        onKeyDown={(e) => handleKeyDown(e, index, 'expirationDate')}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col className="d-flex align-items-end col-2">
                                <Button variant="danger" onClick={() => handleRemoveMedicine(index)}>
                                    X
                                </Button>
                            </Col>
                        </Row>
                    ))}

                    <Button variant="success" onClick={handleAddMedicine} className='m-1'>
                        Thêm +
                    </Button>

                    <Form.Group className="mt-3">
                        <Form.Label>Ghi Chú</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                        />
                    </Form.Group>
                    <Modal.Footer className='d-flex justify-content-center'>
                        <Button id="submitButton" variant="primary" type="submit" className="mt-3" disabled={isSubmitDisabled()}>
                            Tạo Phiếu Nhập
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateMedicineReceipt;
