import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileClipboard, faPrint, faTrash, faSave } from '@fortawesome/free-solid-svg-icons';
import ModalConfirm from './ModalConfirm';
import formatDate from './FormatDate';
import PrintPrescriptionModal from './PrintPrescriptionModal';

const PrescriptionList = ({ reload, refreshPrescriptionList, refreshTemplateList }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [prescriptions, setPrescriptions] = useState([]);
    const [customerMap, setCustomerMap] = useState({});
    const [showTemplateModal, setShowTemplateModal] = useState(false);
    const [selectedPrescription, setSelectedPrescription] = useState(null);
    const [templateName, setTemplateName] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedPrescriptionToDelete, setSelectedPrescriptionToDelete] = useState(null);
    const [showPrintModal, setShowPrintModal] = useState(false);
    const inputRef = useRef(null);

    const handlePrintModalOpen = (prescription) => {
        setSelectedPrescription(prescription);
        setShowPrintModal(true);
    };

    const handlePrintModalClose = () => {
        setShowPrintModal(false);
        setSelectedPrescription(null);
    };

    const token = localStorage.getItem('token');
    let userId;
    if (token && typeof token === 'string') {
        try {
            const decoded = jwtDecode(token);
            userId = decoded.userId;
        } catch (error) {
            console.error('Token không hợp lệ:', error);
        }
    }
    const fetchData = useCallback(async () => {
        try {
            const [prescriptionRes, customerRes] = await Promise.all([
                axios.get(`${apiUrl}/prescriptions?createdBy=${userId}`),
                axios.get(`${apiUrl}/customers?createdBy=${userId}`)
            ]);

            // Sắp xếp prescriptions từ mới đến cũ dựa vào createdAt
            const sortedPrescriptions = prescriptionRes.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

            setPrescriptions(sortedPrescriptions);

            const map = {};
            customerRes.data.forEach(customer => {
                map[customer._id] = customer.name;
            });
            setCustomerMap(map);
        } catch (error) {
            console.error('Lỗi khi lấy dữ liệu:', error);
        }
    }, [apiUrl, userId]);
    useEffect(() => {
        fetchData();
    }, [reload]);

    useEffect(() => {
        if (showTemplateModal) {
            inputRef.current?.focus();
        }
    }, [showTemplateModal]);

    // Hàm xử lý sự kiện khi nhấn Enter
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Ngăn chặn hành động mặc định của Enter
            handleSaveTemplate(); // Gọi hàm lưu toa mẫu
        }
    };

    const handleClose = () => {
        setShowConfirmModal(false);
        setShowTemplateModal(false);
    };

    const openTemplateModal = (prescription) => {
        setSelectedPrescription(prescription);
        setShowTemplateModal(true);
    };

    const handleSaveTemplate = async () => {
        if (!templateName) {
            toast.error('Vui lòng nhập tên cho toa mẫu.');
            return;
        }

        if (!selectedPrescription) {
            toast.error('Không có toa thuốc nào để lưu.');
            return;
        }

        try {
            const token = localStorage.getItem('token');
            const decoded = jwtDecode(token);
            const userId = decoded.userId;

            const formattedPrescriptions = selectedPrescription.prescriptions.map(p => ({
                medicineId: p.medicineId || '', // Đảm bảo không có trường null hoặc undefined
                days: p.days || 0,
                timesPerDay: p.timesPerDay || 0,
                dosePerTime: p.dosePerTime || 0,
                usageTime: p.usageTime || '',
                route: p.route || '',
                note: p.note || '',
            }));

            const response = await axios.post(`${apiUrl}/prescription-templates`, {
                name: templateName,
                createdBy: userId,
                diagnosis: selectedPrescription.diagnosis || '', // Bổ sung trường diagnosis
                prescriptions: formattedPrescriptions
            });

            if (response.status === 200) {
                toast.success('Lưu toa mẫu thành công!');
                if (refreshTemplateList) {
                    refreshTemplateList();
                }
                refreshPrescriptionList();
                setShowTemplateModal(false);
            }
        } catch (error) {
            console.error('Lỗi khi lưu toa mẫu:', error);
            toast.error('Lỗi khi lưu toa mẫu!');
        }
    };


    const handleDelete = async () => {
        if (!selectedPrescriptionToDelete) return;

        try {
            await axios.delete(`${apiUrl}/prescriptions/${selectedPrescriptionToDelete}`);
            setPrescriptions(prescriptions.filter(prescription => prescription._id !== selectedPrescriptionToDelete));
            toast.success('Xóa toa thuốc thành công!');
            setShowConfirmModal(false);
        } catch (error) {
            console.error('Lỗi khi xóa toa thuốc:', error);
            toast.error('Lỗi khi xóa toa thuốc!');
        }
    };

    return (
        <div className="mt-5">
            <h3><FontAwesomeIcon icon={faFileClipboard} /> Tất Cả Toa Thuốc</h3>
            <Table striped bordered hover>
                <thead className='text-center'>
                    <tr>
                        <th>STT</th>
                        <th>Tên Khách Hàng</th>
                        <th>Chẩn Đoán</th>
                        <th>Ngày Kê Toa</th>
                        <th>Lời dặn</th>
                        <th>Hành động</th>
                    </tr>
                </thead>
                <tbody>
                    {prescriptions.map((prescription, index) => (
                        <tr key={prescription._id}>
                            <td>{index + 1}</td>
                            <td><b>{customerMap[prescription.customerId._id] || 'Không xác định'}</b></td>
                            <td>{prescription.diagnosis}</td>
                            <td>{formatDate(prescription.createdAt)}</td>
                            <td>{prescription.doctorAdvice || 'Không có'}</td>
                            <td className='text-center'>
                                <Button variant="info" onClick={() => handlePrintModalOpen(prescription)}>
                                    <FontAwesomeIcon icon={faPrint} /> In toa
                                </Button>{' '}
                                <Button variant="danger" onClick={() => {
                                    setSelectedPrescriptionToDelete(prescription._id);
                                    setShowConfirmModal(true);
                                }}>
                                    <FontAwesomeIcon icon={faTrash} /> Xóa
                                </Button>{' '}
                                <Button variant="primary" onClick={() => openTemplateModal(prescription)}>
                                    <FontAwesomeIcon icon={faSave} /> Lưu mẫu
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Modal Lưu toa mẫu */}
            <Modal show={showTemplateModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> <FontAwesomeIcon icon={faSave} /> Lưu Toa Mẫu</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="templateName">
                        <Form.Label>Nhập tên toa mẫu</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Tên toa mẫu"
                            value={templateName}
                            onChange={(e) => setTemplateName(e.target.value)}
                            onKeyDown={handleKeyDown} // Gắn sự kiện onKeyDown cho ô nhập
                            ref={inputRef} // Gắn ref cho ô nhập để tự động focus
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Hủy</Button>
                    <Button variant="primary" onClick={handleSaveTemplate}>Lưu</Button>
                </Modal.Footer>
            </Modal>
            <ModalConfirm
                show={showConfirmModal}
                handleClose={() => setShowConfirmModal(false)}
                handleConfirm={handleDelete}
            />
            <PrintPrescriptionModal
                show={showPrintModal}
                handleClose={handlePrintModalClose}
                prescription={selectedPrescription}
            />
        </div>
    );
};

export default PrescriptionList;
