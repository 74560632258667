import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages, faLightbulb, faNotesMedical, faPlusCircle, faTrashAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';
import imageCompression from 'browser-image-compression';

const AddImageModal = ({ show, handleClose, onReFresh }) => {
    const [albumName, setAlbumName] = useState('');
    const [customerId, setCustomerId] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [imageData, setImageData] = useState([{ before: null, after: null, beforePreview: null, afterPreview: null, beforeName: 'Trước', afterName: 'Sau', beforeDescription: '', afterDescription: '' }]);
    const [notes, setNotes] = useState('');
    const [showAlert, setShow] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;

    const token = localStorage.getItem('token');
    let userId;
    if (token && typeof token === 'string') {
        try {
            const decoded = jwtDecode(token);
            userId = decoded.userId;
        } catch (error) {
            console.error('Token không hợp lệ:', error);
        }
    } else {
        console.warn('Token không hợp lệ hoặc không tồn tại.');
    }

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const response = await axios.get(`${apiUrl}/customers?createdBy=${userId}`);
                const customerOptions = response.data.map(customer => ({
                    value: customer._id,
                    label: `${customer.name} - ${customer.phone} - ${customer.birthday ? new Date(customer.birthday).getFullYear() : 'N/A'}`
                }));
                setCustomers(customerOptions);
            } catch (error) {
                console.error("Lỗi khi lấy danh sách khách hàng:", error);
            }
        };
        fetchCustomers();
    }, [apiUrl, userId]);

    const handleAddImageRow = () => {
        setImageData([...imageData, { before: null, after: null, beforePreview: null, afterPreview: null, beforeName: 'Trước', afterName: 'Sau', beforeDescription: '', afterDescription: '' }]);
    };

    const handleImageChange = async (index, position, file) => {
        try {
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 800,
                useWebWorker: true,
                fileType: 'image/webp',
            };

            const compressedFile = await imageCompression(file, options);

            const newImageData = [...imageData];
            newImageData[index][position] = {
                data: compressedFile,
                contentType: 'image/webp',
                name: position === "before" ? "Trước" : "Sau",
                uploadedAt: new Date(),
            };

            newImageData[index][`${position}Preview`] = URL.createObjectURL(compressedFile);
            setImageData(newImageData);
        } catch (error) {
            console.error('Lỗi khi xử lý hình ảnh:', error);
        }
    };

    const handleRemoveImage = (index, position) => {
        const newImageData = [...imageData];
        newImageData[index][position] = null;
        newImageData[index][`${position}Preview`] = null;
        newImageData[index][`${position}Name`] = '';
        setImageData(newImageData);
    };

    const handleAddImages = async () => {
        const formData = new FormData();
        formData.append('albumName', albumName);
        formData.append('customerId', customerId);
        formData.append('createdBy', userId);
        formData.append('notes', notes);

        imageData.forEach((data) => {
            if (data.before) {
                formData.append('images', data.before.data);
                formData.append('imageNames', data.beforeName);
                formData.append('imageDescriptions', data.beforeDescription);
            }
            if (data.after) {
                formData.append('images', data.after.data);
                formData.append('imageNames', data.afterName);
                formData.append('imageDescriptions', data.afterDescription);
            }
        });

        try {
            await axios.post(`${apiUrl}/albums`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            handleClose();
            toast.success('Tạo Album thành công!');
            onReFresh();
        } catch (error) {
            console.error("Lỗi khi thêm album:", error);
            toast.error("Không thể tạo album.");
        }
    };

    const handleCustomerChange = (option) => {
        setCustomerId(option ? option.value : null);
        setAlbumName(option ? `${option.label}` : '');
    };

    return (
        <Modal show={show} onHide={handleClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title> <FontAwesomeIcon icon={faImages} /> Thêm Album Mới {!showAlert && <FontAwesomeIcon className="text-warning text-right" style={{ cursor: 'pointer' }} icon={faLightbulb} onClick={() => setShow(true)} />}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    {showAlert && (
                        <Alert variant="warning" onClose={() => setShow(false)} dismissible>
                            <Alert.Heading>Lưu ý:</Alert.Heading>
                            <i>
                                Bạn nên cắt chỉnh hình ảnh trước khi tải lên. Hình ảnh cùng kích thước,
                                cùng tư thế trước-sau, sẽ thuận tiện cho bạn so sánh và theo dõi về sau.
                            </i>
                        </Alert>
                    )}
                </>
                <Form>
                    <Form.Group controlId="formSelectCustomer" className="mb-3">
                        <Form.Label> <FontAwesomeIcon icon={faUser} /> Chọn Khách Hàng</Form.Label>
                        <Select
                            options={customers}
                            value={customers.find(customer => customer.value === customerId)}
                            onChange={handleCustomerChange}
                            placeholder="Tìm kiếm khách hàng..."
                            isClearable
                        />
                    </Form.Group>

                    <Form.Group controlId="formNotes">
                        <Form.Label><FontAwesomeIcon icon={faNotesMedical} /> Mô tả</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Mô tả về album ảnh..."
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                            disabled={!customerId}
                        />
                    </Form.Group>

                    {imageData.map((data, index) => (
                        <div className="row mb-3" key={index}>
                            <div className="col-md-6 text-center">
                                <Form.Group>
                                    <Form.Label>Hình Ảnh Trước</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Tên hình ảnh"
                                        value={data.beforeName}
                                        onChange={(e) => {
                                            const newImageData = [...imageData];
                                            newImageData[index].beforeName = e.target.value;
                                            setImageData(newImageData);
                                        }}
                                        className="mb-2"
                                    />
                                    <Form.Control
                                        type="text"
                                        placeholder="Mô tả về hình ảnh"
                                        value={data.beforeDescription}
                                        onChange={(e) => {
                                            const newImageData = [...imageData];
                                            newImageData[index].beforeDescription = e.target.value;
                                            setImageData(newImageData);
                                        }}
                                        className="mb-2"
                                    />
                                    <input
                                        type="file"
                                        onChange={(e) => handleImageChange(index, 'before', e.target.files[0])}
                                        id={`before-${index}`}
                                        style={{ display: 'none' }}
                                    />
                                    <Button variant="outline-primary" onClick={() => document.getElementById(`before-${index}`).click()} disabled={data.beforePreview || !customerId}>
                                        <FontAwesomeIcon icon={faPlusCircle} /> Chọn Hình Ảnh
                                    </Button>
                                    {data.beforePreview && (
                                        <>
                                            <img src={data.beforePreview} alt="Hình ảnh trước" className="mt-2" style={{ maxWidth: '100%', maxHeight: '150px' }} />
                                            <Button variant="danger" onClick={() => handleRemoveImage(index, 'before')} className="mt-2">
                                                <FontAwesomeIcon icon={faTrashAlt} /> Xóa
                                            </Button>
                                        </>
                                    )}
                                </Form.Group>
                            </div>
                            <div className="col-md-6 text-center">
                                <Form.Group>
                                    <Form.Label>Hình Ảnh Sau</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Tên hình ảnh"
                                        value={data.afterName}
                                        onChange={(e) => {
                                            const newImageData = [...imageData];
                                            newImageData[index].afterName = e.target.value;
                                            setImageData(newImageData);
                                        }}
                                        className="mb-2"
                                    />
                                    <Form.Control
                                        type="text"
                                        placeholder="Mô tả về hình ảnh"
                                        value={data.afterDescription}
                                        onChange={(e) => {
                                            const newImageData = [...imageData];
                                            newImageData[index].afterDescription = e.target.value;
                                            setImageData(newImageData);
                                        }}
                                        className="mb-2"
                                    />
                                    <input
                                        type="file"
                                        onChange={(e) => handleImageChange(index, 'after', e.target.files[0])}
                                        id={`after-${index}`}
                                        style={{ display: 'none' }}
                                    />
                                    <Button variant="outline-primary" onClick={() => document.getElementById(`after-${index}`).click()} disabled={data.afterPreview || !customerId}>
                                        <FontAwesomeIcon icon={faPlusCircle} /> Chọn Hình Ảnh
                                    </Button>
                                    {data.afterPreview && (
                                        <>
                                            <img src={data.afterPreview} alt="Hình ảnh sau" className="mt-2" style={{ maxWidth: '100%', maxHeight: '150px' }} />
                                            <Button variant="danger" onClick={() => handleRemoveImage(index, 'after')} className="mt-2">
                                                <FontAwesomeIcon icon={faTrashAlt} /> Xóa
                                            </Button>
                                        </>
                                    )}
                                </Form.Group>
                            </div>
                        </div>
                    ))}
                    <Button variant="success" onClick={handleAddImageRow} disabled={!customerId}>
                        Thêm hình ảnh +
                    </Button>
                </Form>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <Button variant="secondary" onClick={handleClose}>
                    Đóng
                </Button>
                <Button variant="primary" onClick={handleAddImages} disabled={!customerId}>
                    Thêm Album
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddImageModal;
