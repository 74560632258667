import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBirthdayCake, faPhone, faMapMarkerAlt, faHeart, faVenusMars, faCamera, faSave, faLink, faLightbulb, faCopy } from '@fortawesome/free-solid-svg-icons';
import { Form, FormGroup, InputGroup, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import CopyText from '../CopyText';

const PersonalInfo = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [userData, setUserData] = useState({
        name: '',
        birthday: '',
        gender: '',
        phone: '',
        address: '',
        hobbies: '',
        profilePicture: '',
        slug: '', // Thêm trường slug
    });
    const [isSlugAvailable, setIsSlugAvailable] = useState(true);

    useEffect(() => {
        const fetchPersonalInfo = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const { userId } = jwtDecode(token);
                    const response = await axios.get(`${apiUrl}/user/${userId}`);
                    const data = response.data.user;
                    setUserData({
                        name: data.name || '',
                        birthday: data.birthday || '',
                        gender: data.gender || '',
                        phone: data.phone || '',
                        address: data.address || '',
                        hobbies: data.hobbies || '',
                        profilePicture: data.profilePicture || '',
                        slug: data.slug || '', // Lấy slug nếu có
                    });
                } catch (error) {
                    toast.error('Không thể tải thông tin cá nhân');
                }
            }
        };
        fetchPersonalInfo();
    }, [apiUrl]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({ ...prevData, [name]: value }));
        if (name === 'slug') {
            checkSlugExists(value); // Kiểm tra slug khi người dùng nhập
        }
    };

    // Hàm kiểm tra slug đã tồn tại
    const checkSlugExists = async (slug) => {
        try {
            const response = await axios.get(`${apiUrl}/user/check-slug/${slug}`);
            setIsSlugAvailable(!response.data.exists);
        } catch (error) {
            console.error('Lỗi khi kiểm tra slug:', error);
        }
    };

    const handleSave = async (e) => {
        e.preventDefault();
        if (!isSlugAvailable) {
            toast.error('Slug này đã tồn tại, vui lòng chọn slug khác.');
            return;
        }
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const { userId } = jwtDecode(token);
                await axios.put(`${apiUrl}/user/${userId}/personal-info`, userData, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                toast.success('Lưu thông tin thành công!');
            } catch (error) {
                toast.error('Lỗi khi lưu thông tin!');
            }
        }
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => setUserData((prevData) => ({ ...prevData, profilePicture: reader.result }));
            reader.readAsDataURL(file);
        }
    };

    return (
        <Form onSubmit={handleSave} className='m-1'>
            <div className="profile-picture-container">
                {userData.profilePicture ? (
                    <img src={userData.profilePicture} alt="Profile" className="profile-picture" />
                ) : (
                    <div className="placeholder-picture">Hình đại diện</div>
                )}
                <input
                    type="file"
                    id="file-upload"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleImageUpload}
                />
                <label htmlFor="file-upload" className="icon camera-icon">
                    <FontAwesomeIcon icon={faCamera} />
                </label>
            </div>
            <FormGroup className="mb-3">
                <InputGroup>
                    <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faUser} />Họ tên</InputGroup.Text>
                    <Form.Control
                        placeholder="Tên đầy đủ"
                        name="name"
                        value={userData.name}
                        onChange={handleInputChange}
                    />
                </InputGroup>
            </FormGroup>
            <Form.Group controlId="formGender" className="mb-3">
                <InputGroup>
                    <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faVenusMars} />Giới tính</InputGroup.Text>
                    <Form.Control
                        as="select"
                        name="gender"
                        value={userData.gender}
                        onChange={handleInputChange}
                    >
                        <option value="Nam">Nam</option>
                        <option value="Nữ">Nữ</option>
                    </Form.Control>
                </InputGroup>
            </Form.Group>
            <FormGroup className="mb-3">
                <InputGroup>
                    <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faBirthdayCake} />Sinh nhật</InputGroup.Text>
                    <Form.Control
                        type="date"
                        name="birthday"
                        value={userData.birthday ? userData.birthday.slice(0, 10) : ''}
                        onChange={handleInputChange}
                    />
                </InputGroup>
            </FormGroup>
            <FormGroup className="mb-3">
                <InputGroup>
                    <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faPhone} />Điện thoại</InputGroup.Text>
                    <Form.Control
                        placeholder="Số điện thoại"
                        name="phone"
                        value={userData.phone}
                        onChange={handleInputChange}
                    />
                </InputGroup>
            </FormGroup>
            <FormGroup className="mb-3">
                <InputGroup>
                    <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faMapMarkerAlt} />Địa chỉ</InputGroup.Text>
                    <Form.Control
                        placeholder="Địa chỉ"
                        name="address"
                        value={userData.address}
                        onChange={handleInputChange}
                    />
                </InputGroup>
            </FormGroup>
            <FormGroup className="mb-3">
                <InputGroup>
                    <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faHeart} />Sở thích</InputGroup.Text>
                    <Form.Control
                        placeholder="Sở thích"
                        name="hobbies"
                        value={userData.hobbies}
                        onChange={handleInputChange}
                    />
                </InputGroup>
            </FormGroup>
            <FormGroup className="mb-3">
                <InputGroup>
                    <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faLink} />https://ohs.plus/</InputGroup.Text>
                    <Form.Control
                        placeholder="tencuaban"
                        name="slug"
                        value={userData.slug}
                        onChange={handleInputChange}
                        isInvalid={!isSlugAvailable}
                    />
                    <Form.Control.Feedback type="invalid">
                        Tên này đã tồn tại. Vui lòng chọn tùy chọn khác.
                    </Form.Control.Feedback>
                </InputGroup>
            </FormGroup>
            <Button variant='secondary'>
                <CopyText
                    textToCopy={`https://ohs.plus/${userData.slug}`}
                    buttonLabel="Copy Url của bạn"
                />
            </Button><br />
            <FontAwesomeIcon icon={faLightbulb} className='m-1 text-warning' /> Url tùy chỉnh sẽ giúp khách hàng truy cập vào trang cá nhân cũng như đặt lịch hẹn... dễ dàng hơn. Không phân biệt CHỮ HOA/chữ thường, nhưng bạn có thể viết TenCuaBan để đọc dễ hơn. Không sử dụng khoảng trắng<hr />
            <Button type="submit" disabled={!isSlugAvailable}><FontAwesomeIcon className="m-1" icon={faSave} />Lưu Thay Đổi</Button>
        </Form>
    );
};

export default PersonalInfo;
