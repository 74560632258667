import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faPhone, faMapMarkerAlt, faCamera, faTrash, faSave } from '@fortawesome/free-solid-svg-icons';
import { Form, FormGroup, InputGroup, Button } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';

const CompanyInfo = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [companyData, setCompanyData] = useState({
        companyName: '',
        companyAddress: '',
        companyPhone: '',
        logo: ''
    });

    useEffect(() => {
        const fetchCompanyInfo = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const { userId } = jwtDecode(token);
                    const response = await axios.get(`${apiUrl}/user/${userId}`);
                    const data = response.data.user;
                    setCompanyData({
                        companyName: data.companyName || '',
                        companyAddress: data.companyAddress || '',
                        companyPhone: data.companyPhone || '',
                        logo: data.logo || ''
                    });
                } catch (error) {
                    toast.error('Không thể tải thông tin công ty');
                }
            }
        };
        fetchCompanyInfo();
    }, [apiUrl]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCompanyData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleLogoUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => setCompanyData((prevData) => ({ ...prevData, logo: reader.result }));
            reader.readAsDataURL(file);
        }
    };

    const handleSave = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const { userId } = jwtDecode(token);
                await axios.put(`${apiUrl}/user/${userId}/company-info`, companyData, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                toast.success('Lưu thông tin công ty thành công!');
            } catch (error) {
                toast.error('Lỗi khi lưu thông tin công ty!');
            }
        }
    };

    return (
        <Form onSubmit={handleSave} className='m-1'>
            <div className="profile-picture-container text-center mb-3">
                {companyData.logo ? (
                    <img src={companyData.logo} alt="Company Logo" className="profile-picture" />
                ) : (
                    <div className="placeholder-picture">Hình logo</div>
                )}
                <input
                    type="file"
                    id="logo-upload"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleLogoUpload}
                />
                <label htmlFor="logo-upload" className="icon camera-icon">
                    <FontAwesomeIcon icon={faCamera} />
                </label>
            </div>
            <FormGroup className="mb-3">
                <InputGroup>
                    <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faBriefcase} />Tên công ty</InputGroup.Text>
                    <Form.Control
                        placeholder="Tên công ty"
                        name="companyName"
                        value={companyData.companyName}
                        onChange={handleInputChange}
                    />
                </InputGroup>
            </FormGroup>
            <FormGroup className="mb-3">
                <InputGroup>
                    <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faMapMarkerAlt} />Địa chỉ</InputGroup.Text>
                    <Form.Control
                        placeholder="Địa chỉ công ty"
                        name="companyAddress"
                        value={companyData.companyAddress}
                        onChange={handleInputChange}
                    />
                </InputGroup>
            </FormGroup>
            <FormGroup className="mb-3">
                <InputGroup>
                    <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faPhone} />Điện thoại</InputGroup.Text>
                    <Form.Control
                        placeholder="Số điện thoại công ty"
                        name="companyPhone"
                        value={companyData.companyPhone}
                        onChange={handleInputChange}
                    />
                </InputGroup>
            </FormGroup>
            <Button type="submit"><FontAwesomeIcon className="m-1" icon={faSave} /> Lưu Thay Đổi</Button>
        </Form>
    );
};

export default CompanyInfo;
