import React from 'react';
import { components } from 'react-select';

// Tùy chỉnh thành phần Option để in đậm tên thuốc
const MedicineCustomOption = (props) => {
    return (
        <components.Option {...props}>
            <div>
                <strong>{props.data.name}</strong> | Còn: {props.data.quantity < 20 ? <span className='text-danger'><strong>{props.data.quantity}</strong></span> : props.data.quantity} {props.data.unit} | Giá (1 {props.data.unit}): {props.data.price.toLocaleString('vi-VN')}đ
            </div>
        </components.Option>
    );
};

export default MedicineCustomOption;
