import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faPrint, faTrash, faSave } from '@fortawesome/free-solid-svg-icons';
import ModalConfirm from './ModalConfirm';
import PrintSupplyUsageModal from './PrintSupplyUsageModal'; // Import PrintSupplyUsageModal
import formatDate from './FormatDate';

const SupplyUsageList = ({ reload, refreshSupplyUsageList, refreshTemplateList }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [supplyUsages, setSupplyUsages] = useState([]);
    const [customerMap, setCustomerMap] = useState({});
    const [showTemplateModal, setShowTemplateModal] = useState(false);
    const [selectedUsage, setSelectedUsage] = useState(null);
    const [showPrintModal, setShowPrintModal] = useState(false); // Trạng thái cho Print Modal
    const [templateName, setTemplateName] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedUsageToDelete, setSelectedUsageToDelete] = useState(null);
    const inputRef = useRef(null);

    const token = localStorage.getItem('token');
    let userId;
    if (token && typeof token === 'string') {
        try {
            const decoded = jwtDecode(token);
            userId = decoded.userId;
        } catch (error) {
            console.error('Token không hợp lệ:', error);
        }
    }

    const fetchData = useCallback(async () => {
        try {
            const [supplyUsageRes, customerRes] = await Promise.all([
                axios.get(`${apiUrl}/supplyUsage?createdBy=${userId}`),
                axios.get(`${apiUrl}/customers?createdBy=${userId}`)
            ]);

            const sortedSupplyUsages = supplyUsageRes.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setSupplyUsages(sortedSupplyUsages);

            const map = {};
            customerRes.data.forEach(customer => {
                map[customer._id] = `${customer.name} - ${customer.gender} - ${new Date(customer.birthday).getFullYear()}`;
            });
            setCustomerMap(map);
        } catch (error) {
            console.error('Lỗi khi lấy dữ liệu:', error);
        }
    }, [apiUrl, userId]);

    useEffect(() => {
        fetchData();
    }, [reload, fetchData]);

    useEffect(() => {
        if (showTemplateModal) {
            inputRef.current?.focus();
        }
    }, [showTemplateModal]);

    const handleClose = () => {
        setShowConfirmModal(false);
        setShowTemplateModal(false);
        setShowPrintModal(false); // Đóng Print Modal
    };

    const openTemplateModal = (usage) => {
        setSelectedUsage(usage);
        setShowTemplateModal(true);
    };

    const openPrintModal = (usage) => {
        setSelectedUsage(usage);
        setShowPrintModal(true);
    };

    const handleSaveTemplate = async () => {
        if (!templateName) {
            toast.error('Vui lòng nhập tên cho mẫu.');
            return;
        }

        if (!selectedUsage || !selectedUsage.usageDetails || selectedUsage.usageDetails.length === 0) {
            toast.error('Không có dữ liệu vật tư nào để lưu.');
            return;
        }

        try {
            await axios.post(`${apiUrl}/supply-templates`, {
                name: templateName,
                createdBy: userId,
                usageList: selectedUsage.usageDetails // Đảm bảo gửi `usageDetails`
            });

            toast.success('Lưu mẫu vật tư thành công!');

            if (refreshTemplateList) refreshTemplateList();
            if (typeof refreshSupplyUsageList === 'function') refreshSupplyUsageList();

            setShowTemplateModal(false);
        } catch (error) {
            console.error('Lỗi khi lưu mẫu:', error);
            toast.error('Lỗi khi lưu mẫu!');
        }
    };


    const handleDelete = async () => {
        if (!selectedUsageToDelete) return;

        try {
            await axios.delete(`${apiUrl}/supplyUsage/${selectedUsageToDelete}`);
            setSupplyUsages(supplyUsages.filter(usage => usage._id !== selectedUsageToDelete));
            toast.success('Xóa thành công!');
            setShowConfirmModal(false);
        } catch (error) {
            console.error('Lỗi khi xóa vật tư đã sử dụng:', error);
            toast.error('Lỗi khi xóa vật tư!');
        }
    };

    return (
        <div className="mt-5">
            <h3><FontAwesomeIcon icon={faClipboard} /> Danh Sách Phiếu Sử Dụng Vật Tư</h3>
            {supplyUsages.length === 0 ? (
                <p className="text-center">Chưa có phiếu sử dụng vật tư nào</p>
            ) : (
                <Table striped bordered hover>
                    <thead className="text-center">
                        <tr>
                            <th>STT</th>
                            <th>Khách Hàng</th>
                            <th>Dịch Vụ</th>
                            <th>Ngày Sử Dụng</th>
                            <th>Lời dặn</th>
                            <th>Hành động</th>
                        </tr>
                    </thead>
                    <tbody>
                        {supplyUsages.map((usage, index) => (
                            <tr key={usage._id}>
                                <td>{index + 1}</td>
                                <td><b>{customerMap[usage.customerId._id] || 'Không xác định'}</b></td>
                                <td>{usage.usageServices || 'Không xác định'}</td>
                                <td>{formatDate(new Date(usage.createdAt))}</td>
                                <td>{usage.instructions || 'Không có'}</td>
                                <td className="text-center">
                                    <Button variant="info" onClick={() => openPrintModal(usage)}>
                                        <FontAwesomeIcon icon={faPrint} /> In
                                    </Button>{' '}
                                    <Button variant="danger" onClick={() => {
                                        setSelectedUsageToDelete(usage._id);
                                        setShowConfirmModal(true);
                                    }}>
                                        <FontAwesomeIcon icon={faTrash} /> Xóa
                                    </Button>{' '}
                                    <Button variant="primary" onClick={() => openTemplateModal(usage)}>
                                        <FontAwesomeIcon icon={faSave} /> Lưu mẫu
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}

            <Modal show={showTemplateModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title><FontAwesomeIcon icon={faSave} /> Lưu Mẫu Vật Tư</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="templateName">
                        <Form.Label>Nhập tên mẫu vật tư</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Tên mẫu"
                            value={templateName}
                            onChange={(e) => setTemplateName(e.target.value)}
                            ref={inputRef}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault(); // Ngăn chặn hành vi mặc định của Enter
                                    handleSaveTemplate(); // Gọi hàm lưu
                                }
                            }}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Hủy</Button>
                    <Button variant="primary" onClick={handleSaveTemplate}>Lưu</Button>
                </Modal.Footer>
            </Modal>

            <PrintSupplyUsageModal
                show={showPrintModal}
                handleClose={handleClose}
                supplyUsage={selectedUsage}
            />

            <ModalConfirm
                show={showConfirmModal}
                handleClose={() => setShowConfirmModal(false)}
                handleConfirm={handleDelete}
            />
        </div>
    );
};

export default SupplyUsageList;
