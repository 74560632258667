import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks } from '@fortawesome/free-solid-svg-icons';
import { jwtDecode } from 'jwt-decode';

const CourseConfirmModal = ({ show, handleClose, consultation, onReFresh }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token'); // Lấy token từ localStorage
    const decoded = jwtDecode(token);
    const userId = decoded.userId;

    const handleConfirmCourse = async () => {
        if (!consultation) {
            toast.error('Không có thông tin liệu trình để xác nhận.');
            return;
        }

        try {
            // Cập nhật courseCount
            const updatedCourseCount = consultation.courseCount - 1;

            await axios.put(`${apiUrl}/consultations/${consultation._id}`, {
                courseCount: updatedCourseCount,
            });

            // Tạo bản ghi xác nhận liệu trình
            await axios.post(`${apiUrl}/courses-confirm`, {
                consultationId: consultation._id,
                customerId: consultation.customerId,
                updatedCourseCount,
                notes: 'Thực hiện liệu trình', // hoặc từ một input nếu cần
                createdBy: userId // Lấy ID người dùng từ localStorage
            });

            toast.success('Xác nhận thực hiện liệu trình thành công!');

            handleClose();
            onReFresh(); // Refresh danh sách để cập nhật
        } catch (error) {
            console.error('Lỗi khi xác nhận liệu trình:', error);
            toast.error('Không thể xác nhận liệu trình.');
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title><FontAwesomeIcon icon={faTasks} /> Xác Nhận Liệu Trình</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {consultation ? (
                    <div className='text-center'>
                        <p>Bạn có chắc chắn muốn xác nhận đã thực hiện liệu trình cho khách hàng <strong>{consultation.customerId.name}</strong> không?</p>
                        <p><strong>Số lần còn lại: {consultation.courseCount}</strong></p>
                    </div>
                ) : (
                    <p className='text-center'>Không có thông tin liệu trình để hiển thị.</p>
                )}
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <Button variant="secondary" onClick={handleClose}>
                    Đóng
                </Button>
                <Button variant="primary" onClick={handleConfirmCourse}>
                    Xác nhận
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CourseConfirmModal;
