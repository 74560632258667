import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import { Button, FormControl, Badge, Table } from 'react-bootstrap';
import AddConsultation from '../components/AddConsultation';
import EditConsultation from '../components/EditConsultation';
import ModalConfirm from '../components/ModalConfirm';
import Loading from '../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faCommentMedical, faTrash, faEdit, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import PageWrapper from '../components/PageWrapper';
import formatDate from '../components/FormatDate';

const Consultations = () => {
    const [consultations, setConsultations] = useState([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedConsultationId, setSelectedConsultationId] = useState(null);
    const [receipts, setReceipts] = useState([]); // Thêm khai báo state này
    const [visibleColumns, setVisibleColumns] = useState({
        index: true,
        customerName: true,
        services: true,
        totalPrice: true,
        staffName: true,       // Thêm Người Thực hiện
        executionDate: true,
        createdAt: true,
        note: true,
        actions: true,
    });

    const toggleColumnVisibility = (column) => {
        setVisibleColumns((prevState) => ({
            ...prevState,
            [column]: !prevState[column],
        }));
    };


    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');
    let userId;

    if (token) {
        try {
            const decoded = jwtDecode(token);
            userId = decoded.userId;
        } catch (error) {
            console.error('Token không hợp lệ:', error);
        }
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const updateIsMobile = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', updateIsMobile);
        return () => window.removeEventListener('resize', updateIsMobile);
    }, []);

    const fetchConsultations = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/consultations?createdBy=${userId}`);
            if (Array.isArray(response.data.consultations)) {
                const consultationsWithDetails = await Promise.all(
                    response.data.consultations.map(async (consultation) => {
                        const customerId = consultation.customerId?._id || consultation.customerId?.$oid || consultation.customerId;
                        const staffId = consultation.staffId?._id || consultation.staffId?.$oid || consultation.staffId;

                        // Lấy thông tin khách hàng nếu customerId tồn tại
                        let customerName = "Không có tên";
                        let customerBirthday = null;
                        let customerGender = "Không rõ"; // Thêm biến để lưu giới tính
                        if (customerId) {
                            try {
                                const customerResponse = await axios.get(`${apiUrl}/customers/${customerId}`);
                                customerName = customerResponse.data.name;
                                customerBirthday = customerResponse.data.birthday;
                                customerGender = customerResponse.data.gender; // Lấy giới tính
                            } catch (error) {
                                console.error('Lỗi khi lấy thông tin khách hàng:', error);
                            }
                        }

                        // Lấy tên nhân viên nếu staffId tồn tại
                        let staffName = "Không có tên";
                        if (staffId) {
                            try {
                                const staffResponse = await axios.get(
                                    `${apiUrl}/user/${userId}/staff/${staffId}`,
                                    {
                                        headers: {
                                            Authorization: `Bearer ${token}` // Đặt `Bearer ${token}` trong dấu backticks (``)
                                        }
                                    }
                                );
                                staffName = staffResponse.data.name;
                            } catch (error) {
                                console.error('Lỗi khi lấy thông tin nhân viên:', error);
                            }
                        }

                        return {
                            ...consultation,
                            customerName,
                            customerBirthday,
                            customerGender, // Thêm giới tính vào kết quả
                            staffName,
                        };
                    })
                );

                consultationsWithDetails.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setConsultations(consultationsWithDetails);
            } else {
                toast.error('Không có dữ liệu tư vấn!');
            }
        } catch (error) {
            console.error('Lỗi khi lấy danh sách tư vấn:', error);
        } finally {
            setLoading(false);
        }
    }, [apiUrl, userId, token]);


    const hasReceiptForConsultation = (consultationId) => {
        return receipts.some(receipt => receipt.consultationId === consultationId);
    };

    const fetchReceipts = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/receipts?createdBy=${userId}`);
            if (response.data.success) {
                setReceipts(response.data.receipts);
            } else {
                toast.error('Lỗi khi lấy danh sách phiếu thu!');
                setReceipts([]);
            }
        } catch (error) {
            toast.error('Lỗi khi lấy danh sách phiếu thu!');
            console.error('Lỗi khi lấy danh sách phiếu thu:', error);
            setReceipts([]);
        } finally {
            setLoading(false);
        }
    }, [apiUrl, userId]);

    useEffect(() => {
        fetchReceipts();
    }, [fetchReceipts]);

    useEffect(() => {
        fetchConsultations();
    }, [fetchConsultations]);

    const handleShowAdd = () => setShowAddModal(true);
    const handleCloseAdd = () => setShowAddModal(false);

    const handleEditConsultation = (consultationId) => {
        setSelectedConsultationId(consultationId);
        setShowEditModal(true);
    };
    const handleCloseEdit = () => setShowEditModal(false);

    const confirmDelete = (consultationId) => {
        setSelectedConsultationId(consultationId);
        setShowConfirmModal(true);
    };

    const handleDeleteConsultation = async () => {
        try {
            await axios.delete(`${apiUrl}/consultations/${selectedConsultationId}`);
            toast.success('Xóa tư vấn thành công!');
            setConsultations(consultations.filter(c => c._id !== selectedConsultationId));
        } catch (error) {
            toast.error('Lỗi khi xóa tư vấn!');
        } finally {
            setShowConfirmModal(false);
        }
    };

    const filteredConsultations = consultations.filter(consultation =>
        (consultation.customerName?.toLowerCase() || "").includes(search.toLowerCase())
    );
    const displayedConsultations = filteredConsultations
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, 10);
    return (
        <div className="m-2">
            <PageWrapper
                pageTitle="Tư Vấn/Khám"
                pageIcon={<FontAwesomeIcon icon={faComments} />}>
                <div className="d-flex mb-3">
                    <Button
                        variant="primary"
                        onClick={handleShowAdd}
                        className="add-customer-btn"
                        title="Tạo Tư Vấn Mới"
                    >
                        <FontAwesomeIcon icon={faCommentMedical} size='lg' />
                    </Button>
                    <FormControl
                        type="text"
                        placeholder="Tìm kiếm tư vấn..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className="m-1"
                    />
                </div>
                {loading ? (
                    <Loading />
                ) : isMobile ? (
                    <div className="row">
                        {filteredConsultations.map((consultation, index) => (
                            <div className="col-md-6 col-sm-12 mb-4" key={consultation._id}>
                                <div className="card h-100">
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            <Badge bg="secondary">{index + 1}</Badge> {consultation.customerName} - {(new Date(consultation.customerBirthday)).getFullYear()}
                                        </h5>
                                        <div className="d-flex">
                                            {/* Cột bên trái: Hình đại diện */}
                                            <div className="me-3 d-flex justify-content-center align-items-center">
                                                <img
                                                    className="customer-avatar"
                                                    src={
                                                        consultation.customerGender === "Nam"
                                                            ? 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEivYejoR2LHSmj1k4rPX5GAzkudAMk1bry1vGcUIMKT6KGqVdZHCXei00LlHmy9sVd-KgyBGoBnjUMEadamNiYPCw9ujoBgsZ7d42o2ICLNeiFb_-EZVJ4JJXj9VaIpNDew1kj8OTQzjp682jz71pNprN_YaK5nFQxZ5CKDVsdpkObFahGt-KLNsIIU0vP1/s16000/noProfile.png'
                                                            : 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEj4zZog9334BSk5TeAduz4vggNF4RH0O7Frj0Rz46ze56CcfU3GMVrAB1Ly5Dz79AN2tbHhlFnfZ8ZvWqWJhj7TAW4vmEySIb-BKG8_Ez0PiQe6taDRTP8P4wrVOQZmjLQix8t1DIec6vA0_11jTM-xB4DER2C2lzHFO54Zqt9cpUfDOMQkN1TquUTCNFN9/s200/default-avatar-female.jpg'
                                                    }
                                                    alt={consultation.customerName}
                                                    style={{
                                                        width: "100px",
                                                        height: "100px",
                                                        borderRadius: "50%",
                                                        objectFit: "cover",
                                                    }}
                                                />
                                            </div>
                                            {/* Cột bên phải: Thông tin */}
                                            <div>
                                                <p className="card-text mb-1" style={{ fontSize: '0.9em' }}>
                                                    <b>Dịch Vụ:</b> <span className='text-dark'><strong>{consultation.services.map(service => service.name).join(", ")}</strong></span><br />
                                                    <b>Tổng Tiền:</b> <span className='text-success'><strong>{consultation.totalPrice.toLocaleString('vi-VN')}</strong></span>đ<br />
                                                    <b>Nhân Viên:</b> {consultation.staffName || "Không có tên"} <br />
                                                    <b>Ngày Thực Hiện:</b> {formatDate(consultation.executionDate)} <br />
                                                    <b>Ghi Chú:</b> {consultation.note || "Không có ghi chú"}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer d-flex justify-content-between">
                                        <Button
                                            variant="warning"
                                            size="sm"
                                            onClick={() => handleEditConsultation(consultation._id)}
                                            disabled={hasReceiptForConsultation(consultation._id)}>
                                            <FontAwesomeIcon icon={faEdit} /> Sửa
                                        </Button>
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() => confirmDelete(consultation._id)}
                                            disabled={hasReceiptForConsultation(consultation._id)} // Kiểm tra nếu đã có phiếu thu
                                        >
                                            <FontAwesomeIcon icon={faTrash} /> Xóa
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <Table striped bordered hover>
                        <thead className="text-center">
                            <tr>
                                <th>STT
                                    <FontAwesomeIcon icon={visibleColumns.index ? faEye : faEyeSlash}
                                        onClick={() => toggleColumnVisibility('index')}
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </th>
                                <th>Tên Khách Hàng
                                    <FontAwesomeIcon icon={visibleColumns.customerName ? faEye : faEyeSlash}
                                        onClick={() => toggleColumnVisibility('customerName')}
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </th>
                                <th>Dịch Vụ
                                    <FontAwesomeIcon icon={visibleColumns.services ? faEye : faEyeSlash}
                                        onClick={() => toggleColumnVisibility('services')}
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </th>
                                <th>Tổng Tiền
                                    <FontAwesomeIcon icon={visibleColumns.totalPrice ? faEye : faEyeSlash}
                                        onClick={() => toggleColumnVisibility('totalPrice')}
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </th>
                                <th>Người Thực hiện
                                    <FontAwesomeIcon icon={visibleColumns.staffName ? faEye : faEyeSlash}
                                        onClick={() => toggleColumnVisibility('staffName')}
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </th>
                                <th>Ngày Thực hiện
                                    <FontAwesomeIcon icon={visibleColumns.executionDate ? faEye : faEyeSlash}
                                        onClick={() => toggleColumnVisibility('executionDate')}
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </th>
                                <th>Ngày Tư Vấn
                                    <FontAwesomeIcon icon={visibleColumns.createdAt ? faEye : faEyeSlash}
                                        onClick={() => toggleColumnVisibility('createdAt')}
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </th>
                                <th>Ghi Chú
                                    <FontAwesomeIcon icon={visibleColumns.note ? faEye : faEyeSlash}
                                        onClick={() => toggleColumnVisibility('note')}
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </th>
                                <th>Hành Động
                                    <FontAwesomeIcon icon={visibleColumns.actions ? faEye : faEyeSlash}
                                        onClick={() => toggleColumnVisibility('actions')}
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayedConsultations.map((consultation, index) => (
                                <tr key={consultation._id}>
                                    {visibleColumns.index ? <td>{index + 1}</td> : <td>&nbsp;</td>}
                                    {visibleColumns.customerName ? <td style={{ color: consultation.courseCount > 0 ? 'blue' : 'black' }}><b>{consultation.customerName || 'Không có tên'}</b></td> : <td>&nbsp;</td>}
                                    {visibleColumns.services ? (
                                        <td>
                                            {consultation.services && consultation.services.length > 0 ? (
                                                <ol>
                                                    {consultation.services.map((service, index) => (
                                                        <li key={index}> {service.name}</li>
                                                    ))}
                                                </ol>
                                            ) : 'Không có dịch vụ'}
                                        </td>
                                    ) : <td>&nbsp;</td>}
                                    {visibleColumns.totalPrice ? <td><b><span className='text-danger'>{consultation.totalPrice?.toLocaleString('vi-VN') || 'Không có tổng tiền'} VND</span></b></td> : <td>&nbsp;</td>}
                                    {visibleColumns.staffName ? <td>{consultation.staffName || 'Không có tên'}</td> : <td>&nbsp;</td>}
                                    {visibleColumns.executionDate ? <td>{formatDate(consultation.executionDate) || 'Không có ngày'}</td> : <td>&nbsp;</td>}
                                    {visibleColumns.createdAt ? <td>{formatDate(consultation.createdAt) || 'Không có ngày'}</td> : <td>&nbsp;</td>}
                                    {visibleColumns.note ? <td>{consultation.note || 'Không có ghi chú'}</td> : <td>&nbsp;</td>}
                                    {visibleColumns.actions ? (
                                        <td className="text-center">
                                            {hasReceiptForConsultation(consultation._id) ? (
                                                <>
                                                    <Button variant="warning" size="sm" className="m-1" title="Sửa" disabled>
                                                        <FontAwesomeIcon icon={faEdit} /> Sửa
                                                    </Button>
                                                    <Button variant="danger" size="sm" className="m-1" title="Xóa" disabled>
                                                        <FontAwesomeIcon icon={faTrash} /> Xóa
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    <Button variant="warning" size="sm" onClick={() => handleEditConsultation(consultation._id)} className="m-1" title="Sửa">
                                                        <FontAwesomeIcon icon={faEdit} /> Sửa
                                                    </Button>
                                                    <Button variant="danger" size="sm" onClick={() => confirmDelete(consultation._id)} className="m-1" title="Xóa">
                                                        <FontAwesomeIcon icon={faTrash} /> Xóa
                                                    </Button>
                                                </>
                                            )}
                                        </td>
                                    ) : <td>&nbsp;</td>}

                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
                <AddConsultation onReFresh={fetchConsultations} show={showAddModal} handleClose={handleCloseAdd} />
                <EditConsultation onReFresh={fetchConsultations} show={showEditModal} handleClose={handleCloseEdit} consultationId={selectedConsultationId} />
                <ModalConfirm show={showConfirmModal} handleClose={() => setShowConfirmModal(false)} handleConfirm={handleDeleteConsultation} />
            </PageWrapper>
        </div>
    );
};

export default Consultations;
